import React from "react";
import styled from "styled-components";
import { IoMdAdd, IoMdRefresh } from "react-icons/io";
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  BlueButton,
  WhiteButton,
} from "../../Components/StyledComponents/Buttons";
import { IoFilterOutline, IoReorderThreeSharp } from "react-icons/io5";
import { tryfetchSalesPartnersList } from "../../Slices/VendorSlice";
import { Menu, MenuItem } from "@mui/material";
import { useDispatch } from "react-redux";
const Input = styled.input.attrs((props) => ({
  type: "text",
  size: props.small ? 5 : undefined,
}))`
  height: 38px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #aeaeae;
  background: #ffffff;
  padding: 0 0.75em;
  &:focus {
    outline: none;
    //   border: 2px solid #a6a6a6;
    //   background: white;
  }
`;

const ClearButton = styled.button`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-color: #052f855c;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HeaderComponentSP = ({ filterText, onFilter, itemId, handleAdd }) => {
  const roleName = localStorage.getItem("roleName");
  const dispatch = useDispatch();
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleRefresh = () => {
    dispatch(tryfetchSalesPartnersList());
    onFilter("");
  };
  const HandleDropdownClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDropdownClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <div
        className="col-md-12 iitem p-4"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="" style={{ width: "12em" }}>
          <Input
            id="search"
            type="text"
            placeholder="Search..."
            className="table-search"
            value={filterText}
            onChange={(e) => onFilter(e.target.value)} // Pass the input value to onFilter
          />
          {/* <span
            className="bx bx-search-alt"
            style={{ position: "absolute", top: "33%", left: "8px" }}
          ></span> */}
        </div>
        <div className="d-flex">
          {!itemId ? (
            <>
              {/* {roleName === "admin" ? (
                <WhiteButton className="" onClick={handleFilterClick}>
                  <IoFilterOutline size={13} />
                  <i class="bx bx-plus"></i> Filter
                </WhiteButton>
              ) : (
                ""
              )} */}
              {/*      <WhiteButton className="" onClick={handleRefresh}>
                <IoMdRefresh size={13} />
                <i class="bx bx-plus"></i> Refresh
              </WhiteButton>*/}
              {roleName !== "admin" ? (
                <BlueButton className="" onClick={handleAdd}>
                  <IoMdAdd color="white" />
                  New
                </BlueButton>
              ) : (
                ""
              )}
            </>
          ) : (
            <WhiteButton
              className="mx-2"
              // style={{ overflow: "none" }}
              onClick={HandleDropdownClick}
            >
              <IoReorderThreeSharp size={22} />
              {/* <i class="bx bx-plus"></i> Filter */}
            </WhiteButton>
          )}
          {/* </Link> */}
          {/* <button>
            {" "}
            <i class="bx bxs-cog"></i>
          </button> */}
          {/* <Dropdown
            className="d-inline-block"
            isOpen={socialDrp}
            toggle={() => {
              setsocialDrp(!socialDrp);
            }}
          >
            <DropdownToggle
              className="btn header-item waves-effect ddtoggle"
              tag="button"
            >
              <button>
                {" "}
                <i class="bx bx-menu"></i>
              </button>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem tag="a" href="">
                {" "}
                <i className="bx bx-import font-size-16 align-middle mr-1"></i>
                Import Invoice
              </DropdownItem>
              <DropdownItem tag="a" onClick={() => setModalIsOpen(true)}>
                {" "}
                <i className="bx bx-export font-size-16 align-middle mr-1"></i>
                Export Invoice
              </DropdownItem>
              <hr />
              <DropdownItem
                tag="a"
                onClick={() => window.location.reload(false)}
              >
                {" "}
                <i className="bx bx-refresh font-size-16 align-middle mr-1"></i>
                Refresh List
              </DropdownItem>
            </DropdownMenu>
          </Dropdown> */}
        </div>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleDropdownClose}
      >
        {" "}
        <MenuItem onClick={handleAdd}>
          <span>Add</span>
        </MenuItem>
        {/* <MenuItem
          onClick={() => {
            handleFilterClick();
            handleDropdownClose();
          }}
        >
          <span>Filter</span>
        </MenuItem> */}
        <MenuItem
          onClick={() => {
            handleRefresh();
            handleDropdownClose();
          }}
        >
          <span>Refresh</span>
        </MenuItem>
      </Menu>
    </>
  );
};

export default HeaderComponentSP;
