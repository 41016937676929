import React, { useEffect } from "react";
import styled from "styled-components";
import { IoMdAdd, IoMdRefresh } from "react-icons/io";
import { useState } from "react";
import { useDispatch } from "react-redux";
import FilterDropdown from "./FilterBox";
import {
  BlueButton,
  WhiteButton,
} from "../../Components/StyledComponents/Buttons";
import { IoReorderThreeSharp } from "react-icons/io5";
import { SETTINGS_TYPE } from "../../config/BaseUrl";
import axios from "axios";
import { apiPost } from "../../config/apiConfig";
import { tryfetchOEMList } from "../../Slices/OEMSlice";
import { Menu, MenuItem } from "@mui/material";
const Input = styled.input.attrs((props) => ({
  type: "text",
  size: props.small ? 5 : undefined,
}))`
  height: 38px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #aeaeae;
  background: #ffffff;
  padding: 0 0.75em;
  &:focus {
    outline: none;
    //   border: 2px solid #a6a6a6;
    //   background: white;
  }
`;

const HeaderComponent = ({ filterText, onFilter, itemId, handleAdd }) => {
  const dispatch = useDispatch();
  const [warranty, setWarranty] = useState([]);
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleRefresh = () => {
    dispatch(tryfetchOEMList());
  };
  const HandleDropdownClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };
  async function getWarranty() {
    var token = localStorage.getItem("token");
    try {
      var bodyFormData = new FormData();
      bodyFormData.append("token", token);
      bodyFormData.append("type", "gst");
      let resp = await apiPost(SETTINGS_TYPE, bodyFormData);
      console.log("gst", resp);
      if (resp.response.status === 200) {
        setWarranty(resp.response.data.Details);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.message;
      } else {
        console.log("unexpected error: ", error);
        // return yield put(setUserListData());
      }
    }
  }
  useEffect(() => {
    getWarranty();
  }, []);

  const handleFilterClick = () => {
    setShowFilterDropdown(!showFilterDropdown);
  };
  return (
    <>
      <div
        className="col-md-12 iitem"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <>
          <div className="" style={{ width: "12em" }}>
            <Input
              id="search"
              type="text"
              placeholder="Search"
              className="table-search"
              value={filterText}
              onChange={(e) => onFilter(e.target.value)} // Updated here
            />
            {/* <span
            className="bx bx-search-alt"
            style={{ position: "absolute", top: "33%", left: "8px" }}
          ></span> */}
          </div>

          <div className="d-flex">
            {!itemId ? (
              <>
                {/* <WhiteButton className="" onClick={handleFilterClick}>
                  <IoFilterOutline size={13} />
                  <i class="bx bx-plus"></i> Filter
                </WhiteButton> */}
                <WhiteButton className="" onClick={handleRefresh}>
                  <IoMdRefresh size={13} />
                  <i class="bx bx-plus"></i> Refresh
                </WhiteButton>
                <BlueButton className="" onClick={handleAdd}>
                  <IoMdAdd color="white" />
                  New
                </BlueButton>
              </>
            ) : (
              <WhiteButton
                className="mx-2"
                // style={{ overflow: "none" }}
                onClick={HandleDropdownClick}
              >
                <IoReorderThreeSharp size={22} />
              </WhiteButton>
            )}
          </div>
          {showFilterDropdown && (
            <FilterDropdown
              warranty={warranty}
              onSave={() => {
                setShowFilterDropdown(false);
              }}
              onClose={() => setShowFilterDropdown(false)}
            />
          )}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleDropdownClose}
          >
            {" "}
            <MenuItem
              onClick={() => {
                handleDropdownClose();
                handleAdd();
              }}
            >
              <span>Add</span>
            </MenuItem>
            {/* <MenuItem
              onClick={() => {
                handleFilterClick();
                handleDropdownClose();
              }}
            >
              <span>Filter</span>
            </MenuItem> */}
            <MenuItem
              onClick={() => {
                handleRefresh();
                handleDropdownClose();
              }}
            >
              <span>Refresh</span>
            </MenuItem>
          </Menu>
        </>
      </div>
    </>
  );
};

export default HeaderComponent;
