import { put, takeEvery } from "redux-saga/effects";
import axios from "axios";

import {
  LOGIN,
  FORGOT_PASSWORD_MAIL_SENT,
  FORGOT_PASSWORD_OTP_SENT,
  FORGOT_PASSWORD_RESET,
  ACCOUNT_RECOVERY_RESET,
} from "../config/BaseUrl";
import {
  resetPasswordEmailSendFailed,
  resetPasswordEmailSendSuccess,
  setInvalidLogin,
  setPasswordChange,
  setUser,
  setUserOtpVerify,
  tryPasswordChange,
  tryUserLogOut,
  tryUserLogin,
  tryUserResetPasswordEmailSend,
  tyrFetchRecoverOtpVerify,
  tyrFetchUserOtpVerify,
} from "../Slices/AuthenticationSlice";
import { apiPost } from "../config/apiConfig";

function* userLogin(data) {
  console.log("userLogin", data);
  try {
    var bodyFormData = new FormData();
    bodyFormData.append("email", data.payload.email);
    bodyFormData.append("password", data.payload.password);
    let resp = yield apiPost(LOGIN, bodyFormData);
    if (resp.response.status === 200) {
      console.log("login", resp);
      localStorage.setItem("valid-Login", true);
      localStorage.setItem(
        "token",
        resp.response.data.token ? resp.response.data.token : "invalidToken"
      );
      localStorage.setItem("roleName", resp.response.data.details?.role);
      localStorage.setItem("name", resp.response.data.details?.name);
      localStorage.setItem("userId", resp.response.data.details?.reg_no);
      if (resp?.response?.data?.details?.profile) {
        localStorage.setItem(
          "userdetails",
          JSON.stringify(resp?.response?.data?.details?.profile)
        );
      }
      localStorage.setItem("expires_at", resp.response.data?.expires_at);
      localStorage.setItem("username", data.payload.email);
      localStorage.setItem("checkbox", data.payload.keepLoggedIn);
      data.payload.navigate("/");
      yield put(setUser(resp.response.data));
    } else if (resp.error.status === 400) {
      yield put(setInvalidLogin(resp.error));
      // yield put(setInvalidLogin("Incorrect username or password"));
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.message;
    } else {
      // put(setInvalidLogin("Incorrect username or password"));
      console.log("unexpected error: ", error);
      // return yield put(setUserListData());
    }
  }
}

// function* userResetEmailSend(data) {
//   // console.log("email data is", data);
//   try {
//     const requestOptions = {
//       method: "POST",
//       headers: { "Content-Type": "application/json" },
//       body: JSON.stringify({
//         email: data.payload,
//       }),
//     };

//     fetch(FORGOT_PASSWORD_MAIL_SENT, requestOptions)
//       .then((response) => response.json())
//       .then((resp) => {
//         console.log("resp", resp);
//         if (resp.status) {
//           // localStorage.setItem("token", resp.token);
//           // console.log("resp", resp);
//           put(resetPasswordEmailSendSuccess(resp));
//         } else {
//           localStorage.setItem("valid-Login", false);
//         }
//       })
//       .catch((error) => {
//         console.log("unexpected error: ", error);
//         put(resetPasswordEmailSendSuccess(error));
//       });

//     // var bodyFormData = new FormData();
//     // bodyFormData.append("email", data.payload);
//     // let resp = yield apiPost(FORGOT_PASSWORD_MAIL_SENT, bodyFormData);
//     // console.log("reset email", resp);
//     // if (resp.response?.data === "") {
//     //   yield put(
//     //     resetPasswordEmailSendFailed("Email send failed. Please try again")
//     //   );
//     // } else {
//     //   yield put(resetPasswordEmailSendSuccess("Email send successfully!!!"));
//     // }
//   } catch (error) {
//     if (axios.isAxiosError(error)) {
//       return error.message;
//     } else {
//       console.log("unexpected error: ", error);
//       return;
//     }
//   }
// }
function* userResetEmailSend(data) {
  try {
    // const requestOptions = {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify({
    //     email: data.payload,
    //   }),
    // };
    var bodyFormData = new FormData();
    bodyFormData.append("email", data.payload);
    const response = yield apiPost(FORGOT_PASSWORD_MAIL_SENT, bodyFormData);
    // const resp = yield response.json();

    console.log("email sent data", response);
    if (response?.response?.data.status) {
      yield put(resetPasswordEmailSendSuccess(response?.response?.data));
    } else {
      yield put(resetPasswordEmailSendFailed(response?.response?.data));
    }
  } catch (error) {
    console.log("unexpected error: ", error);
    yield put(resetPasswordEmailSendFailed(error.message));
  }
}
function* userOtpVerify(data) {
  // console.log("otp data", data);

  try {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: data.payload.email,
        otp: data.payload.otp,
      }),
    };
    const response = yield fetch(FORGOT_PASSWORD_OTP_SENT, requestOptions);
    const resp = yield response.json();
    if (resp.status) {
      // console.log("otp resp", resp);
      yield put(setUserOtpVerify(resp));
    } else {
      yield put(setUserOtpVerify(resp));
    }
  } catch (error) {
    console.log("unexpected error: ", error);
    yield put(setUserOtpVerify(error.message));
  }
}
function* recoverOtpVerify(data) {
  // console.log("otp data", data);

  try {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: data.payload.email,
        otp: data.payload.otp,
      }),
    };
    const response = yield fetch(ACCOUNT_RECOVERY_RESET, requestOptions);
    const resp = yield response.json();
    if (resp.status) {
      // console.log("otp resp", resp);
      yield put(setUserOtpVerify(resp));
      data.payload.navigate("/Login");
    } else {
      yield put(setUserOtpVerify(resp));
    }
  } catch (error) {
    console.log("unexpected error: ", error);
    yield put(setUserOtpVerify(error.message));
  }
}
function* passwordChange(data) {
  console.log("passwordChange data", data);
  try {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: data.payload.email,
        password: data.payload.password,
        password_confirmation: data.payload.password_confirmation,
        // token: data.payload.token,
      }),
    };

    const response = yield fetch(FORGOT_PASSWORD_RESET, requestOptions);
    const resp = yield response.json();

    if (resp.status) {
      console.log("passwordChange resp", resp);
      yield put(setPasswordChange(resp));
    } else {
      yield put(setPasswordChange(resp));
    }
  } catch (error) {
    console.log("unexpected error: ", error);
    yield put(setPasswordChange(error.message));
  }
}

function userLogOut(data) {
  localStorage.setItem("token", "");
  data.payload.navigate("/Login");
  localStorage.setItem("roleName", "");
  localStorage.setItem("name", "");
  localStorage.setItem("userId", "");
  localStorage.setItem("username", "");
  localStorage.setItem("checkbox", "");
  // const requestOptions = {
  //   method: "GET",
  // headers: { "Content-Type": "application/json" },
  // body: JSON.stringify({
  //   email: data.payload.loginData.email,
  //   password: data.payload.loginData.password,
  // }),
  // };
  // fetch(LOGOUT, requestOptions);
}
export default function* AuthenticationSaga() {
  yield takeEvery(tryUserLogin, userLogin);
  yield takeEvery(tryUserLogOut, userLogOut);
  yield takeEvery(tryUserResetPasswordEmailSend, userResetEmailSend);
  yield takeEvery(tyrFetchUserOtpVerify, userOtpVerify);
  yield takeEvery(tyrFetchRecoverOtpVerify, recoverOtpVerify);
  yield takeEvery(tryPasswordChange, passwordChange);
  // yield takeEvery(tryfetchCollegeList, fetchCollegeListData);
  // yield takeEvery(tryfetchCategoryList, fetchCategoryListData);
}
