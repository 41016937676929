import React, { useEffect, useMemo, useState } from "react";
import { MDBTable, MDBTableHead, MDBTableBody, MDBBtn } from "mdb-react-ui-kit";
import {
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
} from "mdb-react-ui-kit";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HeaderComponent from "./HeaderComponent";
import {
  ActionIcons,
  Actions,
  customHeaderStyleId,
} from "../../Components/StyledComponents/Common";
import { tryfetchOEMDetailByID, tryfetchOEMList } from "../../Slices/OEMSlice";
import SingleOEMView from "./SingleOEMView";
import { format } from "date-fns";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";
import { OEM_BLOCK } from "../../config/BaseUrl";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { apiPost } from "../../config/apiConfig";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

const OEMList = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const ListData = useSelector((state) => state?.OEM?.OEMTableData);
  const [filterText, setFilterText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [itemId, setItemId] = useState("");
  const [blockData, setBlockData] = useState(false);

  const itemsPerPage = 10; // Set the number of items per page
  const totalPages = Math.ceil(ListData?.total_pages);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // Get the current page data
  // const indexOfLastItem = currentPage * itemsPerPage;
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData;
  const columnDefs = useMemo(() => [
    {
      field: "oem_name",
      headerName: "Company Name",
      headerTooltip: "Company Name",
    },
    {
      field: "id",
      headerName: "OEM Id",
      headerTooltip: "OEM Id",
    },
    // {
    //   field: "supplier_partner_no",
    //   headerName: "Supplier Partner No",
    //   headerTooltip: "Supplier Partner No",
    // },
    {
      field: "email",
      headerName: "Email",
      headerTooltip: "Coverage details",
    },
    {
      field: "mobile",
      headerName: "Contact Number",
      headerTooltip: "Contact Number",
    },
    {
      field: "date",
      headerName: "Date",
      headerTooltip: "Date",
      // Update the cell renderer to format the date
      cellRenderer: (rowData) =>
        format(new Date(rowData.created_at), "dd-MM-yyyy"), // Format the date
    },
    // {
    //   field: "website",
    //   headerName: "Website",
    //   headerTooltip: " Website",
    // },
  ]);
  const columnDefs1 = useMemo(() => [
    {
      field: "oem_name",
      headerName: "Company Name",
      headerTooltip: "Company Name",
    },
  ]);

  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      filter: true,
      sortable: true,
      resizable: true,
    }),
    []
  );

  const handleClear = () => {
    if (filterText) {
      setFilterText("");
    }
  };

  const handleSearch = (searchValue) => {
    setFilterText(searchValue);
    const filteredList = ListData?.data.filter((row) => {
      const id = String(row.id).toLowerCase();
      const name = String(row.oem_name).toLowerCase();
      const search = searchValue.toLowerCase();
      return id.includes(search) || name.includes(search);
    });
    setFilteredData(filteredList);
  };

  const handleRowClick = (row) => {
    console.log("rowsadra", row);
    setRowData(row);
    setItemId(row?.id);
    dispatch(tryfetchOEMDetailByID(row?.id));
  };

  const resetItemId = () => {
    setItemId("");
  };

  const handleAdd = () => {
    history(`/OEM/Add`);
  };

  const gridStyle = useMemo(() => ({ height: "60vh", width: "100%" }), []);
  useEffect(() => {
    // console.log("asfdasfaefaWef");
    var data = {
      pageNo: currentPage,
      itemPerPage: itemsPerPage,
    };
    dispatch(tryfetchOEMList(data));
  }, []);

  useEffect(() => {
    setFilteredData(ListData?.data);
  }, [ListData]);
  async function handleblock(e) {
    console.log("e saled id", e);
    var token = localStorage.getItem("token");
    try {
      var bodyFormData = new FormData();
      bodyFormData.append("token", token);
      bodyFormData.append("oem_id", e.id);
      bodyFormData.append("status", e.is_active === 1 ? 0 : 1);
      let resp = await apiPost(OEM_BLOCK, bodyFormData);
      console.log("oem block", resp);
      if (resp?.response?.status === 200) {
        var data = {
          pageNo: currentPage,
          itemPerPage: itemsPerPage,
        };
        dispatch(tryfetchOEMList(data));
        toast.success(resp?.response?.data?.message);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.message;
      } else {
        console.log("unexpected error: ", error);
      }
    }
  }
  return (
    <div className="d-flex" style={{ overflowY: "auto" }}>
      <ToastContainer />
      <Page $view={itemId}>
        <PageContent $singleView={itemId}>
          <ListHead>
            <HeaderComponent
              onFilter={handleSearch}
              onClear={handleClear}
              handleAdd={handleAdd}
              itemId={itemId}
              filterText={filterText}
            />
          </ListHead>
          <>
            <div className="list-table" style={{ overflow: "auto" }}>
              <MDBTable>
                <MDBTableHead color="#4D5FFF" align="left">
                  <tr>
                    {!itemId ? (
                      <>
                        {columnDefs.map((colDef) => (
                          <th
                            style={customHeaderStyle}
                            key={colDef.field}
                            // scope="col"
                            // className="header-text"
                          >
                            {colDef.headerName}
                          </th>
                        ))}
                        <th style={customHeaderStyle}> Action</th>
                      </>
                    ) : (
                      columnDefs1.map((colDef) => (
                        <th
                          style={customHeaderStyleId}
                          key={colDef.field}
                          scope="col"
                          // className="header-text"
                          className={itemId ? "px-5" : ""}
                        >
                          {colDef.headerName}
                        </th>
                      ))
                    )}
                  </tr>
                </MDBTableHead>

                <MDBTableBody align="left">
                  {currentItems?.map((rowData) => (
                    <tr key={rowData.customerId}>
                      {!itemId ? (
                        <>
                          {columnDefs?.map((colDef) => (
                            <td
                              key={colDef.field}
                              onClick={() => handleRowClick(rowData)}
                              style={customCellStyle}
                            >
                              {rowData[colDef.field]}
                            </td>
                          ))}
                          <td
                            // className={itemId ? "px-5" : ""}
                            style={customCellStyle}
                          >
                            <Actions>
                              <ActionIcons onClick={() => handleblock(rowData)}>
                                {rowData.is_active === 0 ? (
                                  <BsToggleOff size={20} title="Inactive" />
                                ) : (
                                  <BsToggleOn
                                    size={20}
                                    color="green"
                                    title="Active"
                                  />
                                )}
                              </ActionIcons>
                            </Actions>
                          </td>
                        </>
                      ) : (
                        columnDefs1?.map((colDef) => (
                          <td
                            key={colDef.field}
                            className={itemId ? "px-5" : ""}
                            onClick={() => handleRowClick(rowData)}
                            style={customCellStyle}
                          >
                            {rowData[colDef.field]}
                          </td>
                        ))
                      )}
                    </tr>
                  ))}
                </MDBTableBody>
              </MDBTable>
            </div>

            {/* <MDBPagination className="mt-3" end>
              <MDBPaginationItem disabled={currentPage === 1}>
                <MDBPaginationLink
                  className="page-link"
                  aria-label="Previous"
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <span aria-hidden="true">&laquo;</span>
                </MDBPaginationLink>
              </MDBPaginationItem>
              {Array.from({ length: totalPages }, (_, i) => (
                <MDBPaginationItem key={i} active={i + 1 === currentPage}>
                  <MDBPaginationLink
                    className="page-link"
                    onClick={() => handlePageChange(i + 1)}
                  >
                    {i + 1}
                  </MDBPaginationLink>
                </MDBPaginationItem>
              ))}
              <MDBPaginationItem disabled={currentPage === totalPages}>
                <MDBPaginationLink
                  className="page-link"
                  aria-label="Next"
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <span aria-hidden="true">&raquo;</span>
                </MDBPaginationLink>
              </MDBPaginationItem>
            </MDBPagination> */}
            <MDBPagination className="py-3 gap-4 justify-content-end ">
              <MDBPaginationItem disabled={currentPage === 1}>
                <MDBPaginationLink
                  style={{ border: "none", background: "none" }}
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <MdKeyboardArrowLeft />
                </MDBPaginationLink>
              </MDBPaginationItem>
              {Array.from({ length: totalPages }, (_, i) => (
                <MDBPaginationItem key={i} active={i + 1 === currentPage}>
                  <MDBPaginationLink
                    className="page-link"
                    onClick={() => handlePageChange(i + 1)}
                    style={{
                      backgroundColor:
                        i + 1 === currentPage ? "black" : "white",
                      color: i + 1 === currentPage ? "white" : "black",
                      border: "1px solid #aeaeae",
                    }}
                  >
                    {i + 1}
                  </MDBPaginationLink>
                </MDBPaginationItem>
              ))}
              <MDBPaginationItem disabled={currentPage === totalPages}>
                <MDBPaginationLink
                  onClick={() => handlePageChange(currentPage + 1)}
                  style={{ border: "none", background: "none" }}
                >
                  <MdKeyboardArrowRight />
                </MDBPaginationLink>
              </MDBPaginationItem>
            </MDBPagination>
          </>
        </PageContent>
      </Page>
      {itemId ? (
        <PageSingle $singleView={itemId}>
          <PageContent>
            <SingleOEMView
              close={resetItemId}
              itemId={itemId}
              data={rowData}
              handleblock={handleblock}
            />
          </PageContent>
        </PageSingle>
      ) : (
        ""
      )}
    </div>
  );
};

const ListHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border-bottom: 0.5px solid #bfbfbf;
  padding: 0 11px 20px 15px;
  .header-text {
    color: "#4D5FFF"; // Default text color
    font-size: 25px; // Default font size
  }
`;
const customHeaderStyle = {
  // background: "#4D5FFF",
  color: "#4D5FFF",
  fontSize: "13px",
  fontWeight: "500",
  padding: "1.3rem 0.5rem",
};
const PageSingle = styled.div`
  width: ${(props) => (!props.$singleView ? "" : "100%")};
  margin-left: 10px;
  margin-top: 10px;
  border-radius: 22px;
  overflow-y: auto;
  background-color: #fff;
  transition: all 0.3s ease-out;
  padding: 1em;
  display: ${(props) => (!props.$singleView ? "flex" : "")};
`;
const Page = styled.div`
  // width: 100%;
  width: ${(props) => (props.$view ? "22em" : "100%")};
  margin-left: 10px;
  margin-top: 10px;
  border-radius: 22px;
  overflow-y: auto;
  background-color: ${(props) => (props.$view ? "#f3f3f3" : "#fff")};
  flex-shrink: 0;
  transition: all 0.3s ease-out;
  padding: 1em;
  @media (max-width: 1200px) {
    display: ${(props) => (props.$view ? "none" : "flex")};
  }
  // display: flex;
`;
const PageContent = styled.div`
  // padding: calc(60px + 24px) 0 60px 0;
  // width: ${(props) => (props.$singleView ? "300px" : "100%")};
  width: 100%;
  overflow: auto;
  transition: all 0.3s ease-out;
`;
const customCellStyle = {
  // border: "1px solid #CCCCCC",
  padding: "1.3rem 0.5rem",
};
export default OEMList;
