import { createSlice } from "@reduxjs/toolkit";

const InitState = {
  isLoading: false,
  supplierTableData: [],
  supplierDetailByID: [],
};

const SupplierSlice = createSlice({
  name: "supplier",
  initialState: InitState,
  reducers: {
    tryfetchSupplierList: (state) => {
      state.isLoading = true;
    },
    setSupplierListData: (state, action) => {
      state.isLoading = false;
      state.supplierTableData = action.payload;
    },
    tryfetchSupplierDetailByID: (state) => {
      state.isLoading = true;
    },
    setSupplierDetailByID: (state, action) => {
      state.isLoading = false;
      state.supplierDetailByID = action.payload;
    },
    clearSupplierDetailsByID: (state) => {
      state.isLoading = false;
      state.supplierDetailByID = [];
    },
  },
});

export const {
  tryfetchSupplierList,
  setSupplierListData,
  tryfetchSupplierDetailByID,
  setSupplierDetailByID,
  clearSupplierDetailsByID,
} = SupplierSlice.actions;

export default SupplierSlice.reducer;
