import { createSlice } from "@reduxjs/toolkit";

const InitState = {
  isLoading: false,
  subscriptionTableData: [],
  packageTableData: [],
  subscriptionDetailByID: [],
};

const SubscriptionSlice = createSlice({
  name: "subscription",
  initialState: InitState,
  reducers: {
    tryfetchSubscriptionList: (state) => {
      state.isLoading = true;
    },
    setSubscriptionListData: (state, action) => {
      state.isLoading = false;
      state.subscriptionTableData = action.payload;
    },
    tryfetchFilteredSubscriptionList: (state) => {
      state.isLoading = true;
    },
    setFilteredSubscriptionListData: (state, action) => {
      state.isLoading = false;
      state.subscriptionTableData = action.payload;
    },
    tryfetchPackageList: (state) => {
      state.isLoading = true;
    },
    setPackageListData: (state, action) => {
      state.isLoading = false;
      state.packageTableData = action.payload;
    },
    tryfetchSubscriptionDetailByID: (state) => {
      state.isLoading = true;
    },
    setSubscriptionDetailByID: (state, action) => {
      state.isLoading = false;
      state.subscriptionDetailByID = action.payload;
    },
    clearSubscriptionDetailsByID: (state) => {
      state.isLoading = false;
      state.subscriptionDetailByID = [];
    },
  },
});

export const {
  tryfetchSubscriptionList,
  setSubscriptionListData,
  tryfetchSubscriptionDetailByID,
  setSubscriptionDetailByID,
  clearSubscriptionDetailsByID,
  tryfetchFilteredSubscriptionList,
  setFilteredSubscriptionListData,
  tryfetchPackageList,
  setPackageListData,
} = SubscriptionSlice.actions;

export default SubscriptionSlice.reducer;
