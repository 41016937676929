import React, { useEffect, useMemo, useState } from "react";
import { MDBTable, MDBTableHead, MDBTableBody, MDBBtn } from "mdb-react-ui-kit";
import {
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
} from "mdb-react-ui-kit";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HeaderComponent from "./HeaderComponent";
import {
  clearBatchDetailsByID,
  tryfetchBatchDetailByID,
  tryfetchBatchList,
} from "../../Slices/BatchSlice";
import SingleBatchView from "./SingleBatchView";
import {
  Page,
  PageContent,
  PageSingle,
  customCellStyle,
  customHeaderStyle,
  customHeaderStyleId,
} from "../../Components/StyledComponents/Common";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

const BatchList = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const ListData = useSelector((state) => state?.batch?.batchTableData);
  const [filterText, setFilterText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [itemId, setItemId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 10;
  const totalPages = Math.ceil(ListData?.total_pages);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const getPageNumbers = () => {
    const maxPagesToShow = 5;
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
    let startPage = currentPage - halfMaxPagesToShow;
    let endPage = currentPage + halfMaxPagesToShow;

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(totalPages, maxPagesToShow);
    } else if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, totalPages - maxPagesToShow + 1);
    }

    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  };

  // const indexOfLastItem = currentPage * itemsPerPage;
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData;
  const columnDefs = useMemo(() => [
    {
      field: "batch_number",
      headerName: "Batch Number",
      headerTooltip: "Batch Number",
    },
    {
      field: "product_name",
      headerName: "Product Name",
      headerTooltip: "Product Name",
      width: "500px",
    },
    {
      field: "product_type_name",
      headerName: "Product Type",
      headerTooltip: "Product Type",
    },
    {
      field: "warranty_package_name",
      headerName: "Warranty Type",
      headerTooltip: "Warranty Type",
    },
    {
      field: "model_number",
      headerName: "Model No",
      headerTooltip: "Model No",
    },
    {
      field: "manufacturing_date",
      headerName: "Manufacturing Date",
      headerTooltip: "Manufacturing Date",
    },
  ]);
  const columnDefs1 = useMemo(() => [
    {
      field: "batch_number",
      headerName: "Batch Number",
      headerTooltip: "Product Name",
    },
  ]);

  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      filter: true,
      sortable: true,
      resizable: true,
    }),
    []
  );

  const handleClear = () => {
    if (filterText) {
      setFilterText("");
    }
  };

  const handleSearch = (searchValue) => {
    setFilterText(searchValue);
    const filteredList = ListData?.batch?.filter((row) => {
      const id = String(row.batch_number).toLowerCase();
      const search = searchValue.toLowerCase();
      return id.includes(search);
    });
    setFilteredData(filteredList);
  };

  const handleRowClick = (row) => {
    // console.log("id row data", row);
    setRowData(row);
    setItemId(row?.id);
    dispatch(tryfetchBatchDetailByID(row?.id));
  };

  const resetItemId = () => {
    setItemId("");
  };

  const handleAdd = () => {
    history(`/Batch/Add`);
  };

  const gridStyle = useMemo(() => ({ height: "60vh", width: "100%" }), []);
  useEffect(() => {
    dispatch(clearBatchDetailsByID());
    var data = {
      pageNo: currentPage,
      itemPerPage: itemsPerPage,
    };
    dispatch(tryfetchBatchList(data));
  }, [currentPage, itemsPerPage]);

  useEffect(() => {
    console.log("lsitehadata", ListData);
    setFilteredData(ListData?.batch);
  }, [ListData.batch]);

  return (
    <div className="d-flex" style={{ overflow: "auto" }}>
      <Page $view={itemId}>
        <PageContent $singleView={itemId}>
          <ListHead>
            <HeaderComponent
              onFilter={handleSearch}
              onClear={handleClear}
              handleAdd={handleAdd}
              itemId={itemId}
              filterText={filterText}
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
            />
          </ListHead>
          <>
            <div className="list-table" style={{ overflow: "auto" }}>
              <MDBTable>
                <MDBTableHead color="#4D5FFF" align="left">
                  <tr>
                    {!itemId
                      ? columnDefs?.map((colDef) => (
                          <th
                            style={customHeaderStyle}
                            key={colDef.field}
                            scope="col"
                            className="header-text"
                          >
                            {colDef.headerName}
                          </th>
                        ))
                      : columnDefs1?.map((colDef) => (
                          <th
                            style={customHeaderStyleId}
                            key={colDef.field}
                            scope="col"
                            className={itemId ? "px-5" : ""}
                          >
                            {colDef.headerName}
                          </th>
                        ))}
                  </tr>
                </MDBTableHead>

                <MDBTableBody align="left">
                  {currentItems?.map((rowData) => (
                    <tr key={rowData.id}>
                      {!itemId
                        ? columnDefs?.map((colDef) => (
                            <td
                              key={colDef.field}
                              onClick={() => handleRowClick(rowData)}
                              style={customCellStyle}
                            >
                              {rowData[colDef.field]}
                            </td>
                          ))
                        : columnDefs1?.map((colDef) => (
                            <td
                              className={itemId ? "px-5" : ""}
                              key={colDef.field}
                              onClick={() => handleRowClick(rowData)}
                              style={customCellStyle}
                            >
                              {rowData[colDef.field]}
                            </td>
                          ))}
                    </tr>
                  ))}
                </MDBTableBody>
              </MDBTable>
            </div>

            <MDBPagination className="py-3 gap-4 justify-content-end">
              <MDBPaginationItem disabled={currentPage === 1}>
                <MDBPaginationLink
                  style={{ border: "none", background: "none" }}
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <MdKeyboardArrowLeft />
                </MDBPaginationLink>
              </MDBPaginationItem>
              {getPageNumbers().map((page) => (
                <MDBPaginationItem key={page} active={page === currentPage}>
                  <MDBPaginationLink
                    className="page-link"
                    onClick={() => handlePageChange(page)}
                    style={{
                      backgroundColor: page === currentPage ? "black" : "white",
                      color: page === currentPage ? "white" : "black",
                      border: "1px solid #aeaeae",
                    }}
                  >
                    {page}
                  </MDBPaginationLink>
                </MDBPaginationItem>
              ))}
              <MDBPaginationItem disabled={currentPage === totalPages}>
                <MDBPaginationLink
                  onClick={() => handlePageChange(currentPage + 1)}
                  style={{ border: "none", background: "none" }}
                >
                  <MdKeyboardArrowRight />
                </MDBPaginationLink>
              </MDBPaginationItem>
            </MDBPagination>
          </>
        </PageContent>
      </Page>
      {itemId ? (
        <PageSingle $singleView={itemId}>
          <PageContent>
            <SingleBatchView
              close={resetItemId}
              itemId={itemId}
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              data={rowData}
            />
          </PageContent>
        </PageSingle>
      ) : (
        ""
      )}
    </div>
  );
};

const ListHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .header-text {
    color: "#4D5FFF";
    font-size: 25px;
  }
`;

export default BatchList;
