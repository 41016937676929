import { combineReducers } from "@reduxjs/toolkit";
import authenticationReducer from "./AuthenticationSlice";
import productReducer from "./ProductSlice";
import WarrantySlice from "./WarrantySlice";
import BatchSlice from "./BatchSlice";
import VendorSlice from "./VendorSlice";
import SettingsSlice from "./SettingsSlice";
import HeaderSlice from "./HeaderSlice";
import OEMSlice from "./OEMSlice";
import SupplierSlice from "./SupplierSlice";
import SubscriptionSlice from "./SubscriptionSlice";

const combinedReducer = combineReducers({
  authentication: authenticationReducer,
  product: productReducer,
  warranty: WarrantySlice,
  batch: BatchSlice,
  vendor: VendorSlice,
  settings: SettingsSlice,
  header: HeaderSlice,
  OEM: OEMSlice,
  supplier: SupplierSlice,
  subscription: SubscriptionSlice,
});

const rootReducer = (state, action) => {
  return combinedReducer(state, action);
};
export default rootReducer;
