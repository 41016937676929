import { createSlice } from "@reduxjs/toolkit";

const InitState = {
  isLoading: false,
  notificationsListData: [],
};

const HeaderSlice = createSlice({
  name: "Header",
  initialState: InitState,
  reducers: {
    tryfetchNotificationsList: (state) => {
      state.isLoading = true;
    },
    setNotificationsListData: (state, action) => {
      state.isLoading = false;
      state.notificationsListData = action.payload;
    },
    clearBatchDetailsByID: (state) => {
      state.isLoading = false;
      state.batchDetailByID = [];
    },
  },
});

export const {
  tryfetchNotificationsList,
  setNotificationsListData,
  clearBatchDetailsByID,
} = HeaderSlice.actions;

export default HeaderSlice.reducer;
