import React from "react";
import styled from "styled-components";
import { IoMdAdd } from "react-icons/io";
import { useState } from "react";
import { Link } from "react-router-dom";
const Input = styled.input.attrs((props) => ({
  type: "text",
  size: props.small ? 5 : undefined,
}))`
  height: 38px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #aeaeae;
  background: #f3f6f9;
  padding: 0 0.75em;
  &:focus {
    outline: none;
    //   border: 2px solid #a6a6a6;
    //   background: white;
  }
`;

const ClearButton = styled.button`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-color: #052f855c;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HeaderComponent = ({ filterText, onFilter, onClear, handleAdd }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [socialDrp, setsocialDrp] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  return (
    <>
      <div
        className="col-md-12 iitem"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="" style={{ width: "12em" }}>
          <Input
            id="search"
            type="text"
            placeholder="Search with Id"
            className="table-search"
            value={filterText}
            onChange={onFilter}
          />
          {/* <span
            className="bx bx-search-alt"
            style={{ position: "absolute", top: "33%", left: "8px" }}
          ></span> */}
        </div>
        <div className="itembtn">
          <button className="">
            {" "}
            <i class="bx bx-plus"></i> Refresh
          </button>
          {/* <Link to="/Product/Add"> */}
          <button className="newbtn" onClick={handleAdd}>
            <IoMdAdd color="white" />
            <span>New</span>
          </button>
          {/* </Link> */}
          {/* <button>
            {" "}
            <i class="bx bxs-cog"></i>
          </button> */}
          {/* <Dropdown
            className="d-inline-block"
            isOpen={socialDrp}
            toggle={() => {
              setsocialDrp(!socialDrp);
            }}
          >
            <DropdownToggle
              className="btn header-item waves-effect ddtoggle"
              tag="button"
            >
              <button>
                {" "}
                <i class="bx bx-menu"></i>
              </button>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem tag="a" href="">
                {" "}
                <i className="bx bx-import font-size-16 align-middle mr-1"></i>
                Import Invoice
              </DropdownItem>
              <DropdownItem tag="a" onClick={() => setModalIsOpen(true)}>
                {" "}
                <i className="bx bx-export font-size-16 align-middle mr-1"></i>
                Export Invoice
              </DropdownItem>
              <hr />
              <DropdownItem
                tag="a"
                onClick={() => window.location.reload(false)}
              >
                {" "}
                <i className="bx bx-refresh font-size-16 align-middle mr-1"></i>
                Refresh List
              </DropdownItem>
            </DropdownMenu>
          </Dropdown> */}
        </div>
      </div>
    </>
  );
};

export default HeaderComponent;
