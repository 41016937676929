import { createSlice } from "@reduxjs/toolkit";

const InitState = {
  isLoading: false,
  OEMTableData: [],
  OEMDetailByID: [],
  OEMProfileByID: [],
};

const OEMSlice = createSlice({
  name: "OEM",
  initialState: InitState,
  reducers: {
    tryfetchOEMList: (state) => {
      state.isLoading = true;
    },
    setOEMListData: (state, action) => {
      state.isLoading = false;
      state.OEMTableData = action.payload;
    },
    tryfetchOEMDetailByID: (state) => {
      state.isLoading = true;
    },
    setOEMDetailByID: (state, action) => {
      state.isLoading = false;
      state.OEMDetailByID = action.payload;
    },
    tryfetchOEMProfileByID: (state) => {
      state.isLoading = true;
    },
    setOEMProfileByID: (state, action) => {
      state.isLoading = false;
      state.OEMProfileByID = action.payload;
    },
    clearOEMProfileByID: (state) => {
      state.isLoading = false;
      state.OEMProfileByID = [];
    },
    clearOEMDetailsByID: (state) => {
      state.isLoading = false;
      state.OEMDetailByID = [];
    },
  },
});

export const {
  tryfetchOEMList,
  setOEMListData,
  tryfetchOEMDetailByID,
  setOEMDetailByID,
  tryfetchOEMProfileByID,
  setOEMProfileByID,
  clearOEMProfileByID,
  clearOEMDetailsByID,
} = OEMSlice.actions;

export default OEMSlice.reducer;
