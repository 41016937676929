import React, { useEffect, useState } from "react";
import styled from "styled-components";
import HeaderSearch from "../Search/HeaderSearch";
import settings from "../../assets/Images/setting-2.png";
import noti from "../../assets/Images/Huge-icon (1).png";
import profile from "../../assets/Images/profile.png";
import { IoIosArrowDown } from "react-icons/io";
import ProfileModal from "./Profile";
import NotificationDropdown from "./Notification";
import { FaRegCircleUser } from "react-icons/fa6";
import { tryfetchOEMProfileByID } from "../../Slices/OEMSlice";
import { useDispatch, useSelector } from "react-redux";
import { tryfetchNotificationsList } from "../../Slices/HeaderSlice";
import { NOTIFICATIONS_SEEN } from "../../config/BaseUrl";
import { apiPost } from "../../config/apiConfig";
import axios from "axios";

const Header = () => {
  const profileData = useSelector((state) => state.OEM?.OEMProfileByID);
  const dispatch = useDispatch();
  const notificationData = useSelector(
    (state) => state.header?.notificationsListData
  );
  const notifications = [
    { id: 1, heading: "Notification 1", time: "2 hours ago" },
    { id: 2, heading: "Notification 2", time: "1 day ago" },
    // Add more notifications as needed
  ];
  let roleName = localStorage.getItem("roleName");

  let data =
    roleName !== "admin"
      ? JSON?.parse(localStorage?.getItem("userdetails")) || null
      : null;

  const [isProfileModalOpen, setProfileModalOpen] = useState(false);
  const [isNotificationDropdownOpen, setNotificationDropdownOpen] =
    useState(false);

  const openProfileModal = () => {
    setProfileModalOpen(!isProfileModalOpen);
    // dispatchEvent(tryFetch);
  };

  const closeProfileModal = () => {
    setProfileModalOpen(false);
  };

  const openNotificationDropdown = () => {
    setNotificationDropdownOpen(!isNotificationDropdownOpen);
    setSeenNotification();
    dispatch(tryfetchNotificationsList());
  };

  const closeNotificationDropdown = () => {
    setNotificationDropdownOpen(false);
  };

  async function setSeenNotification() {
    var token = localStorage.getItem("token");
    var userId = localStorage.getItem("userId");
    try {
      var bodyFormData = new FormData();
      bodyFormData.append("token", token);
      bodyFormData.append("id", userId);
      // bodyFormData.append("type", "Warranty");
      let resp = await apiPost(NOTIFICATIONS_SEEN, bodyFormData);
      // console.log("Warranty", resp);
      if (resp.response.status === 200) {
        console.log("seen");
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.message;
      } else {
        console.log("unexpected error: ", error);
        // return yield put(setUserListData());
      }
    }
  }
  useEffect(() => {
    if (data) {
      dispatch(tryfetchOEMProfileByID(data?.id));
      // console.log("data user called");
    }
    // console.log("data user", data);
  }, []);

  useEffect(() => {
    // console.log("profileData", profileData);
    dispatch(tryfetchNotificationsList());
  }, [dispatch]);

  return (
    <HeaderContainer className="">
      <HeaderWrapper className="">
        <div className="">
          <HeaderSearch />
        </div>
        <div className="col-md-2  d-flex justify-content-end gap-5 align-items-center">
          <div className="img-wrap">
            {notificationData.no_notif ? (
              <NotificationCount>
                {" "}
                {/* Add NotificationCount component here */}
                {notificationData.no_notif}
              </NotificationCount>
            ) : (
              ""
            )}
            <img src={noti} alt="" onClick={openNotificationDropdown} />
          </div>

          {/* Notification Dropdown */}
          {isNotificationDropdownOpen && (
            <NotificationDropdown closeDropdown={closeNotificationDropdown} />
          )}
          <div className="profile" onClick={openProfileModal}>
            {profileData?.image ? (
              <div
                style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              >
                <img src={profileData?.image} alt="" />
              </div>
            ) : (
              <div
                style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              >
                <FaRegCircleUser size={40} color="4d5fff" />
              </div>
            )}
            <IoIosArrowDown color="#5a5a5a" />
          </div>
        </div>
      </HeaderWrapper>

      {/* Profile Modal */}
      {isProfileModalOpen && (
        <ProfileModal closeModal={closeProfileModal} profile={profile} />
      )}
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div`
  height: 85px;
  background-color: #ffffff;
  padding: 0 50px 0 0px;
  width: 100%;
  overflow: hidden;
  border-radius: 22px;
  margin-left: 10px;
  z-index: 9;
  top: 0;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  .img-wrap {
    position: relative; /* Make position relative for absolute positioning */
    width: 24px;
    height: 24px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .profile {
    width: 58px;
    height: 43px;
    display: flex;
    align-items: center;
    border-radius: 40%;
    box-shadow: 4px 0px 6px rgba(0, 0, 0, 0.1);
    img {
      width: 100%;
      border-radius: 50%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const NotificationCount = styled.div`
  position: absolute;
  top: -5px; /* Adjust this value as needed */
  right: -5px; /* Adjust this value as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background-color: red;
  border-radius: 50%;
  color: white;
  font-size: 12px;
`;

export default Header;
