import React, { useEffect, useState } from "react";
import {
  HeadTitle,
  InputStyle,
  Page,
  PageInnerContent,
  selectStyle,
} from "../../Components/StyledComponents/Common";
import styled from "styled-components";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { apiPost } from "../../config/apiConfig";
import { GET_PACKAGE, SUBSCRIBE, UPDATE_SERIAL_NO } from "../../config/BaseUrl";
import { useNavigate, useParams } from "react-router-dom";
import { SaveButton } from "../../Components/StyledComponents/Buttons";

const Subscription = () => {
  const navigate = useNavigate();
  const params = useParams();
  const role_name = localStorage.getItem("roleName");
  const [IsSaving, setIsSaving] = useState(false);
  const [packageData, setPackageData] = useState([]);
  const [totalCost, setTotalCost] = useState(0);
  const [serialNumbers, setSerialNumbers] = useState([]);
  const [values, setValues] = useState({
    productNo: 1,
    years: "",
    payment: "",
  });
  const [error, setError] = useState({
    years: false,
    products: false,
  });
  async function getPackage() {
    const token = localStorage.getItem("token");
    try {
      const bodyFormData = new FormData();
      bodyFormData.append("token", token);
      bodyFormData.append("id", "9");
      const resp = await apiPost(GET_PACKAGE, bodyFormData);
      console.log("package", resp);
      if (resp.response.status === 200) {
        setPackageData(resp.response.data.products);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.message;
      } else {
        console.log("unexpected error: ", error);
      }
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "years") {
      if (value > 12) {
        setError((prevError) => ({ ...prevError, years: true }));
      } else {
        setError((prevError) => ({ ...prevError, years: false }));
      }
    }
    // if (name === "productNo") {
    //   if (value > 1000) {
    //     setError((prevError) => ({ ...prevError, products: true }));
    //   } else {
    //     setError((prevError) => ({ ...prevError, products: false }));
    //   }
    // }
    setValues({ ...values, [name]: parseInt(value) || "" });
  };

  const handleAdd = async (e) => {
    e.preventDefault();

    // Check if there are errors
    if (error.years || error.products) {
      toast.error("Please correct the errors before submitting.");
    } else {
      setIsSaving(true);
      try {
        const token = localStorage.getItem("token");
        const bodyFormData = new FormData();
        bodyFormData.append("token", token);
        bodyFormData.append("package_id", packageData.id);
        bodyFormData.append("period_type", packageData.time_period);
        bodyFormData.append("no_of_periods", values.years);
        bodyFormData.append("no_of_products", values.productNo);
        bodyFormData.append("cost", totalCost);
        const resp = await apiPost(SUBSCRIBE, bodyFormData);
        if (resp.response.status === 200) {
          console.log(" file values is asd", resp);
          toast.success(resp.response.data.message);
          if (params.type === "Edit") {
            handleUpdate(resp.response.data.data);
          }
          setTimeout(() => {
            if (role_name === "oem" && !params.type) {
              navigate("/Subscription-List");
            }
            setIsSaving(false);
          }, 1000);
          setValues({
            productNo: "",
            years: "",
            payment: "",
          });
        } else {
          toast.error(resp.response.data.message);
        }
      } catch (error) {
        setIsSaving(false);
        toast.error(error.message);
        if (axios.isAxiosError(error)) {
          console.log("error message: ", error.message);
          return error.message;
        } else {
          console.log("unexpected error: ", error);
          return "An unexpected error occurred";
        }
      }
    }
  };

  const handleUpdate = async (e) => {
    setIsSaving(true);
    try {
      const token = localStorage.getItem("token");
      const bodyFormData = new FormData();
      bodyFormData.append("token", token);
      bodyFormData.append("package_id", packageData.id);
      bodyFormData.append("batch_id", serialNumbers.batch_id);
      bodyFormData.append("subscription_id", e);
      serialNumbers?.serial_no?.forEach((serial, index) => {
        bodyFormData.append(`serial_no[${index}]`, serial.toString());
      });
      const resp = await apiPost(UPDATE_SERIAL_NO, bodyFormData);
      if (resp.response.status === 200) {
        toast.success(resp.response.data.message);
        setTimeout(() => {
          if (role_name === "oem") {
            navigate("/Batch-List");
          }
          setIsSaving(false);
        }, 2000);
        setValues({
          productNo: "",
          years: "",
          payment: "",
        });
      } else {
        toast.error(resp.response.data.message);
      }
    } catch (error) {
      setIsSaving(false);
      toast.error(error.message);
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  };

  useEffect(() => {
    getPackage();
  }, []);

  useEffect(() => {
    const cost = parseInt(packageData.cost) || 0;
    const year = parseInt(values.years) || 0;
    const productNo = parseInt(values.productNo) || 0;
    setTotalCost(cost * year * productNo);
  }, [values, packageData]);

  useEffect(() => {
    if (params.type === "Edit") {
      const storedSerialNumbers = JSON.parse(
        localStorage.getItem("serialNumbers")
      );
      setSerialNumbers(storedSerialNumbers);
      setValues({
        ...values,
        productNo: parseInt(params.nop) || 1,
        years: parseInt(params.mth) || 1,
      });
    }
  }, [params]);

  return (
    <div className="page">
      <ToastContainer />
      <div className="page-inner-content">
        <div className="row">
          <HeadTitle className="col-md-12">
            <IconButton
              onClick={() => navigate("/Subscription-List")}
              style={{ marginRight: "10px" }}
            >
              <ArrowBackIcon />
            </IconButton>
            <span>{"Subscription"}</span>
          </HeadTitle>
        </div>
        <SubscriptioForm>
          <form onSubmit={handleAdd}>
            <div className="row">
              <div className="col-md-12">
                <div className="row mb-5">
                  <div className="col-md-12 px-0">
                    <TextField
                      type="text"
                      label="Package"
                      variant="outlined"
                      fullWidth
                      size="medium"
                      sx={InputStyle}
                      disabled
                      value={packageData.package_name || " "}
                    />
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-md-12 px-0">
                    <TextField
                      type="text"
                      label={`Cost per ${packageData.time_period}`}
                      variant="outlined"
                      fullWidth
                      sx={InputStyle}
                      disabled
                      value={packageData.cost || " "}
                    />
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-md-12 px-0">
                    <TextField
                      type="number"
                      label="No of products"
                      variant="outlined"
                      fullWidth
                      required
                      name="productNo"
                      sx={InputStyle}
                      value={values.productNo}
                      onChange={handleInputChange}
                      // inputProps={{ min: 1, maxLength: 3, step: 1 }}
                      // error={error.products}
                      // helperText={
                      //   error.products ? "Value must be between 1 and 1000" : ""
                      // }
                    />
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-md-12 px-0">
                    <TextField
                      label={`No of ${packageData.time_period}`}
                      sx={InputStyle}
                      type="number"
                      variant="outlined"
                      fullWidth
                      required
                      name="years"
                      value={values.years}
                      onChange={handleInputChange}
                      // inputProps={{ min: 1, max: 12, step: 1 }}
                      error={error.years}
                      helperText={
                        error.years ? "Value must be between 1 and 12" : ""
                      }
                    />
                  </div>
                </div>
                <Total className="row mb-5">
                  <div className="com-md-12">Total Cost: ₹ {totalCost}</div>
                </Total>
                <div className="row mb-5">
                  <div className="col-md-12 px-0">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Payment
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        style={selectStyle}
                        value={values.payment}
                        label="Payment"
                        name="payment"
                        onChange={handleInputChange}
                      >
                        <MenuItem value="cash">Cash</MenuItem>
                        <MenuItem value="Card">Card</MenuItem>
                        <MenuItem value="Cheque">Cheque</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 buttons d-flex justify-content-end px-0">
                <SubmitButton
                  type="submit"
                  className="submit-button"
                  disabled={IsSaving}
                >
                  Submit
                </SubmitButton>
              </div>
            </div>
          </form>
        </SubscriptioForm>
      </div>
    </div>
  );
};

const SubscriptioForm = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Total = styled.div`
  /* padding: 0 4em; */
  font-weight: 600;
`;

const SubmitButton = styled.button`
  width: 100%;
  background: #4d5fff;
  border: 2px solid #4d5fff;
  display: flex;
  padding: 0.8em 6em;
  border-radius: 4px;
  font-size: 11px;
  font-family: "Public Sans", sans-serif;
  font-weight: 400;
  color: #ffffff;
  transition: 0.2s ease;
  justify-content: center;
  &:hover {
    color: #4d5fff;
    background: #ffffff;
  }
`;

export default Subscription;
