import React, { useEffect } from "react";
import styled from "styled-components";
import { IoMdAdd, IoMdRefresh } from "react-icons/io";
import { useState } from "react";
import { Link } from "react-router-dom";
import { tryfetchWarrantysList } from "../../Slices/WarrantySlice";
import { useDispatch } from "react-redux";
import {
  BlueButton,
  WhiteButton,
} from "../../Components/StyledComponents/Buttons";
import { IoFilterOutline } from "react-icons/io5";
import FilterDropdown from "./FilterBox";
import axios from "axios";
import { apiPost } from "../../config/apiConfig";
import { SETTINGS_TYPE, WARRANTY_LIST } from "../../config/BaseUrl";
import { SecondarySearch } from "../../Components/StyledComponents/Common";
const Input = styled.input.attrs((props) => ({
  type: "text",
  size: props.small ? 5 : undefined,
}))`
  height: 38px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #aeaeae;
  background: #ffffff;
  padding: 0 0.75em;
  &:focus {
    outline: none;
    //   border: 2px solid #a6a6a6;
    //   background: white;
  }
`;

const HeaderComponent = ({ filterText, onFilter, onClear, handleAdd }) => {
  const dispatch = useDispatch();
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [warranty, setWarranty] = useState([]);
  const [warrantyPeriod, setWarrantyPeriod] = useState([]);

  const handleRefresh = () => {
    dispatch(tryfetchWarrantysList());
    onFilter("");
  };

  const handleFilterClick = () => {
    setShowFilterDropdown(!showFilterDropdown);
  };

  async function getWarranty() {
    var token = localStorage.getItem("token");
    try {
      var bodyFormData = new FormData();
      bodyFormData.append("token", token);
      // bodyFormData.append("type", "Warranty");
      let resp = await apiPost(WARRANTY_LIST, bodyFormData);
      console.log("Warranty", resp);
      if (resp.response.status === 200) {
        setWarranty(resp.response.data.warranty);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.message;
      } else {
        console.log("unexpected error: ", error);
        // return yield put(setUserListData());
      }
    }
  }
  async function getWarrantyPeriod() {
    var token = localStorage.getItem("token");
    try {
      var bodyFormData = new FormData();
      bodyFormData.append("token", token);
      bodyFormData.append("type", "warranty_period_type");
      let resp = await apiPost(SETTINGS_TYPE, bodyFormData);
      console.log("uniy", resp);
      if (resp.response.status === 200) {
        setWarrantyPeriod(resp.response.data.Details);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.message;
      } else {
        console.log("unexpected error: ", error);
        // return yield put(setUserListData());
      }
    }
  }
  useEffect(() => {
    getWarranty();
    getWarrantyPeriod();
  }, []);

  return (
    <>
      <div
        className="col-md-12 iitem p-4"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <SecondarySearch>
          <Input
            id="search"
            type="text"
            placeholder="Search"
            className="table-search"
            value={filterText}
            onChange={(e) => onFilter(e.target.value)} // Updated here
          />
          {/* <span
            className="bx bx-search-alt"
            style={{ position: "absolute", top: "33%", left: "8px" }}
          ></span> */}
        </SecondarySearch>
        <div className="d-flex">
          <WhiteButton className="" onClick={handleFilterClick}>
            <IoFilterOutline size={13} />
            <i class="bx bx-plus"></i> Filter
          </WhiteButton>
          {/*   <WhiteButton className="" onClick={handleRefresh}>
            <IoMdRefresh size={13} />
            <i class="bx bx-plus"></i> Refresh
          </WhiteButton>
          <Link to="/Product/Add"> */}
          <BlueButton className="" onClick={handleAdd}>
            <IoMdAdd color="white" />
            New
          </BlueButton>
          {/* </Link> */}
        </div>
        {showFilterDropdown && (
          <FilterDropdown
            onSave={() => {
              setShowFilterDropdown(false);
            }}
            // productType={productType}
            unit={warrantyPeriod}
            warranty={warranty}
            onClose={() => setShowFilterDropdown(false)}
          />
        )}
      </div>
    </>
  );
};

export default HeaderComponent;
