import { all, fork } from "redux-saga/effects";
import AuthenticationSaga from "./AuthenticationSaga";
import ProductsSaga from "./ProductSaga";
import WarrantySaga from "./WarrantySaga";
import BatchSaga from "./BatchSaga";
import VendorsSaga from "./VendorsSaga";
import SettingsSaga from "./SettingsSaga";
import HeaderSaga from "./HeaderSaga";
import OEMSaga from "./OEMSaga";
import SubscriptionSaga from "./SubscriptionSaga";

export default function* rootSaga() {
  yield all([fork(AuthenticationSaga)]);
  yield all([fork(ProductsSaga)]);
  yield all([fork(WarrantySaga)]);
  yield all([fork(BatchSaga)]);
  yield all([fork(VendorsSaga)]);
  yield all([fork(SettingsSaga)]);
  yield all([fork(HeaderSaga)]);
  yield all([fork(OEMSaga)]);
  yield all([fork(SubscriptionSaga)]);
}
