import React from "react";
import styled from "styled-components";
import { IoMdAdd, IoMdRefresh } from "react-icons/io";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { tryfetchOEMList } from "../../Slices/OEMSlice";
import {
  BlueButton,
  WhiteButton,
} from "../../Components/StyledComponents/Buttons";
import {
  tryfetchPackageList,
  tryfetchSubscriptionList,
} from "../../Slices/SubscriptionSlice";

const Input = styled.input.attrs((props) => ({
  type: "text",
  size: props.small ? 5 : undefined,
}))`
  height: 38px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #aeaeae;
  background: #ffffff;
  padding: 0 0.75em;
  &:focus {
    outline: none;
    //   border: 2px solid #a6a6a6;
    //   background: white;
  }
`;

const ClearButton = styled.button`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-color: #052f855c;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HeaderComponent = ({ filterText, onFilter, itemId, handleAdd }) => {
  const dispatch = useDispatch();
  const roleName = localStorage.getItem("roleName");
  const handleRefresh = () => {
    dispatch(tryfetchSubscriptionList());
    onFilter("");
  };

  return (
    <>
      <div
        className="col-md-12 iitem p-4"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <>
          <div className="" style={{ width: "12em" }}>
            {roleName === "admin" ? (
              <Input
                id="search"
                type="text"
                placeholder="Search..."
                className="table-search"
                value={filterText}
                onChange={(e) => onFilter(e.target.value)} // Updated here
              />
            ) : (
              ""
            )}
          </div>

          <div className="d-flex">
            {!itemId
              ? // <WhiteButton className="" onClick={handleRefresh}>
                //   <IoMdRefresh size={13} />
                //   <i class="bx bx-plus"></i> Refresh
                // </WhiteButton>
                ""
              : ""}
            {roleName !== "admin" ? (
              <BlueButton className="" onClick={handleAdd}>
                <IoMdAdd color="white" />
                New
              </BlueButton>
            ) : (
              ""
            )}
            {/* </Link> */}
          </div>
        </>
      </div>
    </>
  );
};

export default HeaderComponent;
