import React, { useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
// import "ag-grid-enterprise";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  tryfetchProductsDetailByID,
  tryfetchProductsList,
} from "../../Slices/ProductSlice";
import { sideBar } from "../../Components/AgGrid/AggridFunctions";
import SingleProductView from "./SingleWarrantyClaimView";
import HeaderComponentClaim from "./HeaderComponentClaim";
import { tryfetchWarrantyClaimsList } from "../../Slices/WarrantySlice";
import {
  Page,
  PageContent,
  customCellStyle,
  customHeaderStyle,
} from "../../Components/StyledComponents/Common";
import { MDBTable, MDBTableHead, MDBTableBody, MDBBtn } from "mdb-react-ui-kit";
import {
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
} from "mdb-react-ui-kit";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

const WarrantyClaimList = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const ListData = useSelector(
    (state) => state?.warranty?.warrantyClaimListData
  );
  // const [rowClick, setRowClick] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedOption, setSelectedOption] = useState("all");
  // const [loading, setLoading] = useState(false);
  // const [totalRows, setTotalRows] = useState(0);
  // const [perPage, setPerPage] = useState(100000000);
  const [rowData, setRowData] = useState([]);
  const [itemId, setItemId] = useState("");

  const itemsPerPage = 10; // Set the number of items per page
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const getPageNumbers = () => {
    const maxPagesToShow = 3;
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
    let startPage = currentPage - halfMaxPagesToShow;
    let endPage = currentPage + halfMaxPagesToShow;

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(totalPages, maxPagesToShow);
    } else if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, totalPages - maxPagesToShow + 1);
    }

    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  };
  // Get the current page data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const columnDefs = useMemo(() => [
    {
      field: "customer_name",
      headerName: "Customer Name",
      headerTooltip: "Customer Name",
    },
    {
      field: "issue",
      headerName: "Issue",
      headerTooltip: "Issue",
    },
    {
      field: "model_no",
      headerName: "Model No.",
      headerTooltip: "Model No.",
    },
    {
      field: "phone_no",
      headerName: "Phone",
      headerTooltip: "Phone",
    },
    {
      field: "serial_no",
      headerName: "Serial No.",
      headerTooltip: "Serial No.",
    },

    {
      field: "type",
      headerName: "Type",
      headerTooltip: " Type",
    },
    {
      field: "warrenty_status",
      headerName: "Status",
      headerTooltip: " Status",
    },
  ]);
  const columnDefs1 = useMemo(() => [
    {
      field: "customer_name",
      headerName: "Customer Name",
      headerTooltip: "Customer Name",
    },
  ]);

  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      filter: true,
      sortable: true,
      resizable: true,
    }),
    []
  );

  const handleClear = () => {
    if (filterText) {
      // setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };
  const handleSearch = (searchValue) => {
    const searchTerm =
      searchValue && typeof searchValue === "string"
        ? searchValue?.toLowerCase()
        : "";
    const filteredList = ListData?.filter((row) => {
      const name = row?.data?.product_name?.toLowerCase();
      return name && name?.includes(searchTerm);
    });
    setFilteredData(filteredList);
  };

  const handleRowClick = (row) => {
    console.log("rowdata", row);
    setRowData(row.data);
    setItemId(row.data.id);
    // dispatch(tryfetchProductsDetailByID(row.data.id));
  };
  const resetItemId = () => {
    setItemId("");
  };
  // useEffect(() => {
  //   getData(1);
  // }, [filterText]);
  const cellClicked = (params) => {
    params.node.setSelected(true);
  };
  const handleAdd = () => {
    history(`/Product/Add`);
  };

  const gridStyle = useMemo(() => ({ height: "60vh", width: "100%" }), []);
  useEffect(() => {
    dispatch(tryfetchWarrantyClaimsList(selectedOption));
  }, [selectedOption]);
  useEffect(() => {
    console.log("ListData", ListData);
    setFilteredData(ListData);
  }, [ListData]);
  return (
    <React.Fragment>
      <Page>
        <PageContent $singleView={itemId}>
          <ListHead>
            {/* <Title>Products</Title>
            <ListButtons>
              <Button onClick={handleAdd}>
                <i class="fa fa-plus" aria-hidden="true"></i> New
              </Button>
              <Button $primary>
                <i class="fa fa-cog" aria-hidden="true"></i>
              </Button>
            </ListButtons> */}
            <HeaderComponentClaim
              onFilter={handleSearch}
              onClear={handleClear}
              filterText={filterText}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
            />
          </ListHead>
          {/* <ListGridTable className="ag-theme-alpine">
            <AgGridReact
              rowData={ListData}
              columnDefs={itemId ? columnDefs1 : columnDefs}
              //   onRowClicked={handleRowClick}
              animateRows={true}
              gridStyle={gridStyle}
              pagination={true}
              paginationAutoPageSize={true}
              sideBar={itemId ? false : sideBar}
              defaultColDef={defaultColDef}
            ></AgGridReact>
          </ListGridTable> */}
          <>
            <div className="list-table">
              <MDBTable>
                <MDBTableHead color="#4D5FFF" align="left">
                  <tr>
                    {!itemId
                      ? columnDefs.map((colDef) => (
                          <th
                            style={customHeaderStyle}
                            key={colDef.field}
                            scope="col"
                            className="header-text"
                          >
                            {colDef.headerName}
                          </th>
                        ))
                      : columnDefs1.map((colDef) => (
                          <th
                            style={customHeaderStyle}
                            key={colDef.field}
                            scope="col"
                            className="header-text"
                          >
                            {colDef.headerName}
                          </th>
                        ))}
                  </tr>
                </MDBTableHead>

                <MDBTableBody align="left">
                  {currentItems.map((rowData) => (
                    <tr key={rowData.customerId}>
                      {!itemId
                        ? columnDefs.map((colDef) => (
                            <td
                              key={colDef.field}
                              // onClick={() => handleRowClick(rowData)}
                              style={customCellStyle}
                            >
                              {rowData[colDef.field]}
                            </td>
                          ))
                        : columnDefs1.map((colDef) => (
                            <td
                              key={colDef.field}
                              onClick={() => handleRowClick(rowData)}
                              style={customCellStyle}
                            >
                              {rowData[colDef.field]}
                            </td>
                          ))}
                    </tr>
                  ))}
                </MDBTableBody>
              </MDBTable>
            </div>

            {/* <MDBPagination className="mt-3" end>
              <MDBPaginationItem disabled={currentPage === 1}>
                <MDBPaginationLink
                  className="page-link"
                  aria-label="Previous"
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <span aria-hidden="true">&laquo;</span>
                </MDBPaginationLink>
              </MDBPaginationItem>
              {Array.from({ length: totalPages }, (_, i) => (
                <MDBPaginationItem key={i} active={i + 1 === currentPage}>
                  <MDBPaginationLink
                    className="page-link"
                    onClick={() => handlePageChange(i + 1)}
                  >
                    {i + 1}
                  </MDBPaginationLink>
                </MDBPaginationItem>
              ))}
              <MDBPaginationItem disabled={currentPage === totalPages}>
                <MDBPaginationLink
                  className="page-link"
                  aria-label="Next"
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <span aria-hidden="true">&raquo;</span>
                </MDBPaginationLink>
              </MDBPaginationItem>
            </MDBPagination> */}
            <MDBPagination className="py-3 gap-4 justify-content-end">
              <MDBPaginationItem disabled={currentPage === 1}>
                <MDBPaginationLink
                  style={{ border: "none", background: "none" }}
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <MdKeyboardArrowLeft />
                </MDBPaginationLink>
              </MDBPaginationItem>
              {getPageNumbers().map((page) => (
                <MDBPaginationItem key={page} active={page === currentPage}>
                  <MDBPaginationLink
                    className="page-link"
                    onClick={() => handlePageChange(page)}
                    style={{
                      backgroundColor: page === currentPage ? "black" : "white",
                      color: page === currentPage ? "white" : "black",
                      border: "1px solid #aeaeae",
                    }}
                  >
                    {page}
                  </MDBPaginationLink>
                </MDBPaginationItem>
              ))}
              <MDBPaginationItem disabled={currentPage === totalPages}>
                <MDBPaginationLink
                  onClick={() => handlePageChange(currentPage + 1)}
                  style={{ border: "none", background: "none" }}
                >
                  <MdKeyboardArrowRight />
                </MDBPaginationLink>
              </MDBPaginationItem>
            </MDBPagination>
          </>
        </PageContent>
        {itemId ? (
          <PageContent>
            <SingleProductView
              close={resetItemId}
              itemId={itemId}
              data={rowData}
            />
          </PageContent>
        ) : (
          ""
        )}
      </Page>
    </React.Fragment>
  );
};

// const Page = styled.div`
//   width: 100%;
//   margin-left: 10px;
//   margin-top: 10px;
//   border-radius: 22px;
//   background-color: #fff;
//   padding: 1em;
//   display: flex;
// `;
// const PageContent = styled.div`
//   // padding: calc(60px + 24px) 0 60px 0;
//   width: ${(props) => (props.$singleView ? "350px" : "100%")};
//   overflow: auto;
//   border-right: ${(props) =>
//     props.$singleView ? "0.5px solid #aeaeae;" : "none"};
//   transition: all 0.3s ease-out;
//   @media (max-width: 1200px) {
//     display: ${(props) => (props.$singleView ? "none" : "block")};
//   }
// `;
const PageContent1 = styled.div`
  display: flex;
`;
const PageView = styled.div`
  display: flex;
`;
const ListHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border-bottom: 0.5px solid #bfbfbf;
  padding: 0 11px 20px 15px;
`;
const Title = styled.h4`
  margin-bottom: 0;
`;
const ListButtons = styled.div`
  display: flex;
  gap: 1.5em;
  align-items: center;

  @media (max-width: 1366px) {
    gap: 1em;
  }
`;
const Button = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${(props) => (props.$primary ? "#ffffff" : "#3cba00")};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: ${(props) => (props.$primary ? "0.5px solid #aeaeae;" : "none")};
  padding: 0 1.5em;
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
  color: ${(props) => (props.$primary ? "black" : "white")};
  height: 40px;
  transition: all 0.3s ease-in-out;
  @media (max-width: 1366px) {
    padding: 0 1em;
  }
`;
const ListGridTable = styled.div`
  width: 100%;
  height: 70vh;
`;
export default WarrantyClaimList;
