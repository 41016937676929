import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import styled from "styled-components";
import { TextField, Checkbox } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { apiPost } from "../../config/apiConfig";
import { ADD_SETTINGS, UPDATE_SETTINGS } from "../../config/BaseUrl";
import {
  SaveButton,
  SubmitButton,
} from "../../Components/StyledComponents/Buttons";
import { useDispatch } from "react-redux";
import { tryfetchSettingsByID } from "../../Slices/SettingsSlice";
import { InputStyle } from "../../Components/StyledComponents/Common";

const AddSettingsModal = ({
  open,
  type,
  settingsType,
  itemId,
  onClose,
  unit,
  row,
}) => {
  // const WRData = useSelector((state) => state?.warranty?.warrantyDetailByID);

  const dispatch = useDispatch();
  const [IsSaving, setIsSaving] = useState(false);
  const [values, setValues] = useState({
    Name: row?.name,
    // name: "",
  });

  // const [checkedValues, setCheckedValues] = useState({
  //   extendedWarranty: false,
  //   workstation: false,
  //   onSiteSupport: false,
  //   required: false,
  //   disabled: false,
  // });
  const [error, setError] = useState({
    Name: false,
  });
  const handleChange = (event) => {
    const value = event.target.value;
    setValues((prevValues) => ({
      ...prevValues,
      [event.target.name]: value,
    }));
    if (event.target.name === "Name") {
      if (event.target.value.length > 20) {
        setError({ ...error, Name: true });
      } else {
        setError({ ...error, Name: false });
      }
    }
  };

  // const handleCheckboxChange = (name) => (event) => {
  //   setCheckedValues({
  //     ...checkedValues,
  //     [name]: event.target.checked,
  //   });
  // };

  const handleAdd = async (e) => {
    console.log("add");
    setIsSaving(true);
    try {
      var token = localStorage.getItem("token");
      var bodyFormData = new FormData();

      bodyFormData.append("token", token);
      bodyFormData.append("setting_type", settingsType);
      bodyFormData.append("name", values.Name);
      let resp = await apiPost(ADD_SETTINGS, bodyFormData);
      if (
        resp.response.status === 200 ||
        resp.response.data.message === "Settings created successfully"
      ) {
        console.log(" file values is asd", resp);
        toast.success(resp.response.data.message);
        setValues({
          Name: "",
          // name: "",
        });
        onClose();
        setTimeout(() => {
          //   navigate("/");
          setIsSaving(false);
        }, 1000);
        dispatch(tryfetchSettingsByID(settingsType));
      } else {
        setIsSaving(false);
        toast.error(resp.response.data.message);
      }
    } catch (error) {
      setIsSaving(false);
      toast.error(error.message);
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  };
  const handleUpdate = async (e) => {
    console.log("updated", itemId);
    setIsSaving(true);
    try {
      var token = localStorage.getItem("token");
      var bodyFormData = new FormData();

      bodyFormData.append("id", itemId);
      bodyFormData.append("token", token);
      bodyFormData.append("setting_type", settingsType);
      bodyFormData.append("name", values.Name);
      let resp = await apiPost(UPDATE_SETTINGS, bodyFormData);
      if (
        resp.response.status === 200 ||
        resp.response.data.message === "Settings updated successfully"
      ) {
        console.log(" file values is asd", resp);
        toast.success(resp.response.data.message);
        setTimeout(() => {
          //   navigate("/");
          setIsSaving(false);
          onClose();
        }, 1000);
        dispatch(tryfetchSettingsByID(settingsType));
      } else {
        toast.error(resp.response.data.message);
        setIsSaving(false);
      }
    } catch (error) {
      setIsSaving(false);
      toast.error(error.message);
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  };
  //   const style = {
  //     position: "absolute",
  //     top: "150px",
  //     right: "40px",
  //     bgcolor: "background.paper",
  //     borderRadius: "22px",
  //     width: 500,
  //     height: calculateSize("100%" - "150px"),
  //     boxShadow: 24,
  //     padding: "3em 2em",
  //     // border: "2px solid #000",
  //     // pt: 2,
  //     // px: 4,
  //     // pb: 3,
  //   };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (type == "add") {
      handleAdd();
    } else {
      handleUpdate();
    }
  };

  useEffect(() => {
    console.log("row edit", type);
    if (type === "edit") {
      setValues({ ...values, Name: row?.name });
    }
  }, [row]);

  useEffect(() => {
    return () => {
      setValues({});
    };
  }, []);

  return (
    <>
      {/* <Button onClick={onClose}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <ModalContainer
        // sx={{ ...style }}
        >
          <h2 id="parent-modal-title">
            {type === "add" ? `Add ${unit}` : `Edit ${unit} `}
          </h2>
          {/* <p id="parent-modal-description">
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </p> */}
          <form action="" onSubmit={handleSubmit}>
            <Row>
              <Col md={12} className=" px-5 ">
                <Row className=" pt-5">
                  <Col md={12}>
                    <TextField
                      label={unit}
                      variant="outlined"
                      fullWidth
                      name="Name"
                      value={values.Name}
                      onChange={handleChange}
                      sx={InputStyle}
                      error={error.Name}
                      required
                      helperText={error.Name ? "Maximum Length exceeded!" : ""}
                    />
                  </Col>
                </Row>

                {/* <Row className=" pt-5">
                  <Col md={12}>
                    <TextField
                      label="PIN"
                      variant="outlined"
                      fullWidth
                      name="pin"
                      value={values.pin}
                      onChange={handleChange}
                      sx={{ borderRadius: 11 }}
                    />
                  </Col>
                </Row> */}
              </Col>
            </Row>
            <Row className=" pt-5">
              <div className="col-md-12 buttons d-flex justify-content-between px-5">
                <SaveButton
                  type="button"
                  onClick={onClose}
                  className=" save-button"
                >
                  Cancel
                </SaveButton>
                <SubmitButton
                  type="submit"
                  disabled={IsSaving}
                  className="submit-button"
                >
                  Save
                </SubmitButton>
              </div>
            </Row>
          </form>
        </ModalContainer>
      </Modal>
    </>
  );
};
const ModalContainer = styled(Box)`
  position: absolute;
  top: 150px;
  right: 40px;
  background-color: #fff;
  border-radius: 22px;
  width: 500px;
  // height: 80vh;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 3em 2em;
  overflow-y: auto;
`;
// const Outer = styled.div`
//   border: 1px solid #c4c4c4;
//   width: 96%;
//   justify-self: center;
//   border-radius: 4px;
//   //   margin: 28px 0 0 0;
// `;
// const Checkboxs = styled(Checkbox)`
//   display: flex;
//   padding: 2em;
//   white-space: nowrap;
//   color: #c4c4c4;
//   font-size: 14px;
// `;

export default AddSettingsModal;
