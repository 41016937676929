import { put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { apiPost } from "../config/apiConfig.js";
import {
  setBatchDetailByID,
  setBatchListData,
  setFilteredBatchListData,
  tryfetchBatchDetailByID,
  tryfetchBatchList,
  tryfetchFilteredBatchList,
} from "../Slices/BatchSlice.jsx";
import {
  BATCH_LIST,
  BATCH_LIST_PAGINATION,
  FILTER_BATCH,
  SINGLE_BATCH_DETAILS,
} from "../config/BaseUrl.js";

function* fetchBatchListData(data) {
  // console.log("PRODUCT_LIST started");
  let token = localStorage.getItem("token");

  try {
    var bodyFormData = new FormData();
    bodyFormData.append("token", token);
    bodyFormData.append("per_page", data.payload.itemPerPage);
    bodyFormData.append("page", data.payload.pageNo);
    let resp = yield apiPost(BATCH_LIST_PAGINATION, bodyFormData);
    console.log("BATCH_LIST pagination", resp);
    if (resp.response.status === 200) {
      yield put(setBatchListData(resp.response.data));
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.message;
    } else {
      console.log("unexpected error: ", error);
      // return yield put(setUserListData());
    }
  }
}
function* fetchBatchDetailByID(data) {
  console.log("fetchBatchDetailByIDPRODUCT_LIST started");
  let token = localStorage.getItem("token");
  try {
    var bodyFormData = new FormData();
    bodyFormData.append("token", token);
    bodyFormData.append("id", data.payload);
    let resp = yield apiPost(SINGLE_BATCH_DETAILS, bodyFormData);
    console.log("SINGLE_WARRANTY_DETAILS", resp);
    if (resp.response.status === 200) {
      yield put(setBatchDetailByID(resp.response.data.batch));
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.message;
    } else {
      console.log("unexpected error: ", error);
      // return yield put(setUserListData());
    }
  }
}
function* fetchBatchFilteredData(data) {
  // console.log("PRODUCT_LIST started");
  let token = localStorage.getItem("token");

  try {
    var bodyFormData = new FormData();
    bodyFormData.append("token", token);
    bodyFormData.append("product_type", data?.payload?.selectedProductType);
    bodyFormData.append("warranty_type", data?.payload?.selectedWarrantyType);
    let resp = yield apiPost(FILTER_BATCH, bodyFormData);
    console.log("FILTER_BATCH list", resp);
    if (resp.response.status === 200) {
      yield put(setFilteredBatchListData(resp?.response?.data));
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.message;
    } else {
      console.log("unexpected error: ", error);
      // return yield put(setUserListData());
    }
  }
}
export default function* BatchSaga() {
  yield takeEvery(tryfetchFilteredBatchList, fetchBatchFilteredData);
  yield takeEvery(tryfetchBatchList, fetchBatchListData);
  yield takeEvery(tryfetchBatchDetailByID, fetchBatchDetailByID);
}
