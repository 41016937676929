import React, { useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import styled from "styled-components";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Container,
} from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { apiPost } from "../../config/apiConfig";
import {
  ADD_SALES_PARTNER,
  ADD_WARRANTY,
  EDIT_SALES_PARTNER,
  SEARCH_SALES_PARTNER_BY_ID,
  UPDATE_WARRANTY,
} from "../../config/BaseUrl";
import {
  SaveButton,
  SubmitButton,
} from "../../Components/StyledComponents/Buttons";
import {
  tryfetchWarrantysDetailByID,
  tryfetchWarrantysList,
} from "../../Slices/WarrantySlice";
import { useDispatch, useSelector } from "react-redux";
import {
  clearSalesPartnerDetailByID,
  tryfetchSalesPartnersList,
} from "../../Slices/VendorSlice";
import {
  InputPinStyle,
  InputStyle,
} from "../../Components/StyledComponents/Common";

const PinInput = ({ pin, setPin }) => {
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  const handleInputChange = (index) => (event) => {
    const value = event.target.value;

    // Check if the input is a number or an empty string
    if (/^\d*$/.test(value)) {
      const newPin = [...pin];
      newPin[index] = value;
      setPin(newPin);

      // Move focus to the next input field
      if (index < 3 && value !== "") {
        inputRefs[index + 1].current.focus();
      }
    } else if (index > 0) {
      // Move focus to the previous input field when backspacing
      inputRefs[index - 1].current.focus();
    }
  };

  const handleKeyDown = (index) => (event) => {
    // Handle the backspace key
    if (event.key === "Backspace" && index > 0 && pin[index] === "") {
      setPin((prevPin) => {
        const newPin = [...prevPin];
        newPin[index - 1] = "";
        return newPin;
      });

      // Move focus to the previous input field
      inputRefs[index - 1].current.focus();
    }
  };

  return (
    // <Container>
    <PinContainer>
      {[0, 1, 2, 3].map((index) => (
        <TextField
          key={index}
          variant="outlined"
          margin="normal"
          label={index === 0 ? "Pin" : ""}
          type="text"
          inputProps={{ maxLength: 1 }}
          sx={InputPinStyle}
          value={pin[index]}
          onChange={handleInputChange(index)}
          inputRef={inputRefs[index]}
          onKeyDown={handleKeyDown(index)}
        />
      ))}
    </PinContainer>
    // </Container>
  );
};

const AddSalesPartnerModal = ({ open, type, itemId, onClose }) => {
  const WRData = useSelector((state) => state?.warranty?.warrantyDetailByID);
  const Data = useSelector((state) => state.vendor.salesPartnerDetailByID);

  const oem_id = localStorage.getItem("userId");
  const dispatch = useDispatch();
  const [IsSaving, setIsSaving] = useState(false);
  const [pin, setPin] = useState("", "", "", "");
  const [values, setValues] = useState({
    partner_id: "",
    name: "",
    email: "",
    mobile: "",
    address: "",
    gst_no: "",
    pan_no: "",
    pin: "",
  });
  const [error, setError] = useState({
    mobile: false,
    email: false,
  });
  const [checkedValues, setCheckedValues] = useState({
    extendedWarranty: false,
    workstation: false,
    onSiteSupport: false,
    required: false,
    disabled: false,
  });
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "mobile") {
      if (value.length !== 10) {
        setError((prevError) => ({ ...prevError, mobile: true }));
      } else {
        setError((prevError) => ({ ...prevError, mobile: false }));
      }
    }

    if (name === "email") {
      if (!emailRegex.test(value)) {
        setError((prevError) => ({ ...prevError, email: true }));
      } else {
        setError((prevError) => ({ ...prevError, email: false }));
      }
    }

    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const handleGSTChange = (e) => {
    const uppercaseValue = e.target.value.toUpperCase();
    setValues({ ...values, gst_no: uppercaseValue });
  };

  const handleCancel = () => {
    setValues({
      partner_id: "",
      name: "",
      email: "",
      mobile: "",
      address: "",
      gst_no: "",
      pan_no: "",
      pin: "",
    });
    setCheckedValues({
      extendedWarranty: false,
      workstation: false,
      onSiteSupport: false,
      required: false,
      disabled: false,
    });
    setPin("", "", "", "");
    onClose();
  };
  const handleCheckboxChange = (name) => (event) => {
    setCheckedValues({
      ...checkedValues,
      [name]: event.target.checked,
    });
  };

  const handleAdd = async (e) => {
    console.log("add", ADD_SALES_PARTNER);
    if (!error.mobile && !error.email) {
      setIsSaving(true);
      try {
        var token = localStorage.getItem("token");
        var bodyFormData = new FormData();

        bodyFormData.append("token", token);
        bodyFormData.append("partner_id", values.partner_id);
        if (values.sales_id) {
          bodyFormData.append("sales_id", values?.sales_id);
        }
        bodyFormData.append("oem_id", oem_id);
        bodyFormData.append("name", values.name);
        bodyFormData.append("email", values.email);
        bodyFormData.append("mobile", values.mobile);
        bodyFormData.append("address", values.address);
        bodyFormData.append("gst_no", values.gst_no);
        bodyFormData.append("pan_no", values.pan_no);
        bodyFormData.append("pin", pin?.join(""));
        let resp = await apiPost(ADD_SALES_PARTNER, bodyFormData);
        if (
          resp.response.data.status === true
          // resp.response.data.message === "User Added Successfully"
        ) {
          // console.log(" file values is asd", resp);
          toast.success(resp.response.data.message);
          setTimeout(() => {
            //   navigate("/");
            setIsSaving(false);
          }, 2000);
          setValues({
            partner_id: "",
            name: "",
            email: "",
            mobile: "",
            address: "",
            gst_no: "",
            pan_no: "",
            pin: "",
          });
          setPin("", "", "", "");
          setCheckedValues({
            extendedWarranty: false,
            workstation: false,
            onSiteSupport: false,
            required: false,
            disabled: false,
          });
          dispatch(tryfetchSalesPartnersList());
        } else {
          toast.error(resp.response.data.message);
        }
      } catch (error) {
        setIsSaving(false);
        toast.error(error.message);
        if (axios.isAxiosError(error)) {
          console.log("error message: ", error.message);
          return error.message;
        } else {
          console.log("unexpected error: ", error);
          return "An unexpected error occurred";
        }
      }
      onClose();
    }
  };
  const handleUpdate = async (e) => {
    if (!error.mobile && !error.email) {
      // console.log("updated");
      setIsSaving(true);
      try {
        var token = localStorage.getItem("token");
        var bodyFormData = new FormData();

        bodyFormData.append("id", values.id);
        bodyFormData.append("token", token);
        bodyFormData.append("sales_id", values?.sales_id);
        bodyFormData.append("partner_id", values?.partner_id);
        bodyFormData.append("name", values?.name);
        bodyFormData.append("email", values?.email);
        bodyFormData.append("mobile", values?.mobile);
        bodyFormData.append("address", values?.address);
        bodyFormData.append("gst_no", values?.gst_no);
        bodyFormData.append("pan_no", values?.pan_no);
        bodyFormData.append("pin", pin.join(""));
        let resp = await apiPost(EDIT_SALES_PARTNER, bodyFormData);
        console.log(" formdata is ", resp);
        if (
          resp.response.status === 200
          // resp.response.data.message === "User Added Successfully"
        ) {
          // console.log(" file values? is asd", resp);
          toast.success(resp.response.data.message);
          setTimeout(() => {
            //   navigate("/");
            setIsSaving(false);
          }, 2000);
          dispatch(tryfetchSalesPartnersList());
          setPin("", "", "", "");
        } else {
          setIsSaving(false);
          toast.error(resp.response.data.message);
        }
      } catch (error) {
        setIsSaving(false);
        toast.error(error[0]);
        if (axios.isAxiosError(error)) {
          console.log("error message: ", error.message);
          return error.message;
        } else {
          console.log("unexpected error: ", error);
          return "An unexpected error occurred";
        }
      }
      onClose();
    }
  };
  //   const style = {
  //     position: "absolute",
  //     top: "150px",
  //     right: "40px",
  //     bgcolor: "background.paper",
  //     borderRadius: "22px",
  //     width: 500,
  //     height: calculateSize("100%" - "150px"),
  //     boxShadow: 24,
  //     padding: "3em 2em",
  //     // border: "2px solid #000",
  //     // pt: 2,
  //     // px: 4,
  //     // pb: 3,
  //   };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("type", type);
    if (type == "add") {
      handleAdd();
    } else {
      handleUpdate();
    }
  };
  async function handleChangePID(e) {
    var token = localStorage.getItem("token");
    try {
      var bodyFormData = new FormData();
      bodyFormData.append("token", token);
      bodyFormData.append("partner_id", e);
      let resp = await apiPost(SEARCH_SALES_PARTNER_BY_ID, bodyFormData);
      console.log("salesparnerbyid", resp);
      if (resp?.response?.status === 200) {
        if (resp?.response?.data?.data[0]) {
          setValues(resp?.response?.data?.data[0]);
        }
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.message;
      } else {
        console.log("unexpected error: ", error);
      }
    }
  }

  useEffect(() => {
    // console.log("type", type);
    if (type === "edit") {
      setPin("", "", "", "");
      setValues(Data);
      if (Data?.pin) {
        const value = Data?.pin;
        const digits = value?.replace(/\D/g, ""); // Remove non-digit characters
        const newPin = [...digits?.slice(0, 4)]; // Extract the first 4 digits and convert to array
        setPin(newPin);
      }
      // setPin(Data?.pin);
    } else {
      setPin("", "", "", "");
      setError({
        mobile: false,
        email: false,
      });
    }
    // console.log("edit values", Data);
  }, [type, Data]);

  // pin change
  // const handleInputChange = (currentPin, setNextPin) => (e) => {
  //   const value = e.target.value;

  //   if (/^\d+$/.test(value)) {
  //     setNextPin(value);

  //     // Move focus to the next input field
  //     const nextInput = e.target.nextElementSibling;
  //     if (nextInput) {
  //       nextInput.focus();
  //     }
  //   }
  // };
  useEffect(() => {
    setValues({
      partner_id: "",
      name: "",
      email: "",
      mobile: "",
      address: "",
      gst_no: "",
      pan_no: "",
      pin: "",
    });
    dispatch(clearSalesPartnerDetailByID());
    setPin("", "", "", "");
    return () => {
      setValues({
        partner_id: "",
        name: "",
        email: "",
        mobile: "",
        address: "",
        gst_no: "",
        pan_no: "",
        pin: "",
      });
      dispatch(clearSalesPartnerDetailByID());
      setPin("", "", "", "");
    };
  }, [type]);

  return (
    <>
      <ToastContainer />
      {/* <Button onClick={onClose}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleCancel}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <ModalContainer
        // sx={{ ...style }}
        >
          <h2 id="parent-modal-title">
            {type === "add" ? "Add New Sales Partner" : "Edit Sales Partner"}
          </h2>
          {/* <p id="parent-modal-description">
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </p> */}
          <form action="" onSubmit={handleSubmit}>
            <Row>
              <Col md={12} className=" px-5 pt-5">
                <Row className=" pt-5">
                  <Col md={12}>
                    <TextField
                      label="Partner ID"
                      variant="outlined"
                      fullWidth
                      name="partner_id"
                      value={values?.partner_id}
                      onChange={handleChange}
                      onBlur={(e) => handleChangePID(e.target.value)}
                      sx={InputStyle}
                      required
                    />
                  </Col>
                </Row>
                <Row className=" pt-5">
                  <Col md={12}>
                    <TextField
                      label="Name"
                      variant="outlined"
                      fullWidth
                      name="name"
                      value={values?.name}
                      onChange={handleChange}
                      sx={InputStyle}
                      required
                    />
                  </Col>
                </Row>
                <Row className=" pt-5">
                  <Col md={12}>
                    <TextField
                      label="Email"
                      variant="outlined"
                      fullWidth
                      name="email"
                      value={values?.email}
                      onChange={handleChange}
                      sx={InputStyle}
                      required
                      error={error.email}
                      helperText={error.email ? "Invalid email id" : ""}
                    />
                  </Col>
                </Row>
                <Row className=" pt-5">
                  <Col md={12}>
                    <TextField
                      label="Mobile"
                      variant="outlined"
                      fullWidth
                      name="mobile"
                      value={values?.mobile}
                      onChange={handleChange}
                      sx={InputStyle}
                      required
                      error={error.mobile}
                      helperText={error.mobile ? "10 digits required" : ""}
                    />
                  </Col>
                </Row>
                <Row className=" pt-5">
                  <Col md={12}>
                    <TextField
                      label="Address"
                      variant="outlined"
                      fullWidth
                      name="address"
                      value={values?.address}
                      onChange={handleChange}
                      sx={InputStyle}
                      required
                    />
                  </Col>
                </Row>
                <Row className=" pt-5">
                  <Col md={12}>
                    <TextField
                      label="GST No"
                      variant="outlined"
                      fullWidth
                      name="gst_no"
                      type="text"
                      value={values?.gst_no}
                      onChange={handleGSTChange}
                      sx={InputStyle}
                      required
                    />
                  </Col>
                </Row>
                <Row className=" pt-5">
                  <Col md={12}>
                    <TextField
                      label="PAN No"
                      variant="outlined"
                      fullWidth
                      name="pan_no"
                      value={values?.pan_no}
                      onChange={handleChange}
                      sx={InputStyle}
                      required
                    />
                  </Col>
                </Row>
                <Row className=" pt-5">
                  <Col md={12}>
                    {/* <TextField
                      label="PIN"
                      variant="outlined"
                      fullWidth
                      name="pin"
                      value={values?.pin||" }
                      onChange={handleChange}
                      sx={InputStyle}
                      required
                    /> */}
                    <PinInput pin={pin} setPin={setPin} />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className=" pt-5">
              <div className="col-md-12 buttons d-flex justify-content-between px-5">
                <SaveButton
                  type="button"
                  onClick={handleCancel}
                  className=" save-button"
                >
                  Cancel
                </SaveButton>
                <SubmitButton
                  type="submit"
                  disabled={IsSaving}
                  className="submit-button"
                >
                  Save
                </SubmitButton>
              </div>
            </Row>
          </form>
        </ModalContainer>
      </Modal>
    </>
  );
};
const ModalContainer = styled(Box)`
  position: absolute;
  top: 150px;
  right: 40px;
  background-color: #fff;
  border-radius: 22px;
  width: 460px;
  height: 80vh;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 3em 2em;
  overflow-y: auto;
`;
const Outer = styled.div`
  border: 1px solid #c4c4c4;
  width: 96%;
  justify-self: center;
  border-radius: 4px;
  //   margin: 28px 0 0 0;
`;
const Checkboxs = styled(Checkbox)`
  display: flex;
  padding: 2em;
  white-space: nowrap;
  color: #c4c4c4;
  font-size: 14px;
`;
const PinContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
`;

export default AddSalesPartnerModal;
