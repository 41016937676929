let baseUrl = "https://gateway.opine.co.in/api/";
// let baseUrl = "http://cinema.opine.co.in/admin/";

export const LOGIN = baseUrl + "login";
export const FORGOT_PASSWORD_MAIL_SENT = baseUrl + "sendForgetMail";
export const FORGOT_PASSWORD_OTP_SENT = baseUrl + "check-otp";
export const FORGOT_PASSWORD_RESET = baseUrl + "save-reset-pass";
export const ACCOUNT_RECOVERY_RESET = baseUrl + "check-block-otp";
// product
export const PRODUCT_LIST = baseUrl + "All-Product";
export const PRODUCT_LIST_PAGINATION = baseUrl + "All-Product-Pagination";
export const ADD_PRODUCT = baseUrl + "Add-Product";
export const UPDATE_PRODUCT = baseUrl + "Update-Product";
export const SINGLE_PRODUCT_DETAILS = baseUrl + "Get-Product-Details";
export const DELETE_PRODUCT = baseUrl + "Delete-Product";
export const PRODUC_TYPE = baseUrl + "All-Product-Type";
export const PRODUCT_FILTER = baseUrl + "Get-Product-Filter";
// settings
export const SETTINGS_TYPE = baseUrl + "Get-Settings-Bytype";
export const ADD_SETTINGS = baseUrl + "Add-Settings";
export const UPDATE_SETTINGS = baseUrl + "Update-Settings";
export const DELETE_SETTINGS = baseUrl + "Delete-Settings";
// warranty
export const WARRANTY_LIST = baseUrl + "All-Warranty-Packages";
export const ADD_WARRANTY = baseUrl + "Add-Warranty-Packages";
export const UPDATE_WARRANTY = baseUrl + "Update-Warranty-Packages";
export const DELETE_WARRANTY = baseUrl + "Delete-Warranty-Packages";
export const SINGLE_WARRANTY_DETAILS = baseUrl + "Get-Warranty-Packages";
export const ALL_CLAIM_REGISTRATION = baseUrl + "All-Claim-Registration";
export const FILTER_WARRANTY = baseUrl + "Get-Warranty-Filter";
// batch
export const BATCH_LIST = baseUrl + "All-Batch";
export const BATCH_LIST_PAGINATION = baseUrl + "All-Batch-Pagination";
export const ADD_BATCH = baseUrl + "Add-Batch";
export const UPDATE_BATCH = baseUrl + "Update-Batch";
export const SINGLE_BATCH_DETAILS = baseUrl + "Get-Batch";
export const DELETE_BATCH = baseUrl + "Delete-Batch";
export const FILTER_BATCH = baseUrl + "Get-Batch-Filter";
export const BATCH_RENEW = baseUrl + "Renew";
export const BATCH_BALANCE_CHECK = baseUrl + "Check-Balance";
export const UPDATE_SERIAL_NO = baseUrl + "Update-Serialno";
export const SERIAL_NO = baseUrl + "New-Batch";
//vendors service center
export const SERVICE_CENTER_LIST = baseUrl + "List-Oem-Service-Center";
//salespartner
export const SALES_PARTNERS_LIST = baseUrl + "List-Oem-Sales-Partner";
export const SALES_PARTNERS_BY_ID = baseUrl + "Sales-Partner-Profile";
export const ADD_SALES_PARTNER = baseUrl + "Add-Oem-Sales-Partner";
export const DELETE_SALES_PARTNER = baseUrl + "Delete-Sales-Partner";
export const BLOCK_SALES_PARTNER = baseUrl + "Block-Sales-Partner";
export const EDIT_SALES_PARTNER = baseUrl + "Edit-Sales-Partner";
export const SEARCH_SALES_PARTNER_BY_ID = baseUrl + "search-sales-partner-pid";
//supplier
export const SUPPLIER_LIST = baseUrl + "List-Oem-Supplier";
export const ADD_SUPPLIER = baseUrl + "Add-Oem-Supplier";
// OEM
export const OEM_LIST_PAGINATION = baseUrl + "list-oem-user-pagination";
export const OEM_LIST = baseUrl + "list-oem-user";
export const OEM_BY_ID = baseUrl + "oem-profile-details";
export const OEM_PROFILE_EDIT = baseUrl + "oem-profile-edit";
export const OEM_REGISTERATION = baseUrl + "oem-registration";
export const OEM_DELETE = baseUrl + "oem-profile-delete";
export const OEM_BLOCK = baseUrl + "Block-oem-user";
// subscription

export const SUBSCRIBE = baseUrl + "Add-oem-subscribe";
export const SUBSCRIPTION_LIST = baseUrl + "List-oem-subscribe";
export const PACKAGE_LIST = baseUrl + "All-Package";
export const ADD_PACKAGE = baseUrl + "Add-Package";
export const GET_PACKAGE = baseUrl + "Get-Package";

//search
export const SEARCH = baseUrl + "Search";
// notifications
export const NOTIFICATIONS = baseUrl + "Get-Notifications";
export const NOTIFICATIONS_SEEN = baseUrl + "Update-Seen";
