import { put, takeEvery } from "redux-saga/effects";
import {
  setProductType,
  setProductsDetailByID,
  setProductsFilteredListData,
  setProductsListData,
  tryfetchProductType,
  tryfetchProductsDetailByID,
  tryfetchProductsFilteredList,
  tryfetchProductsList,
} from "../Slices/ProductSlice.jsx";
import {
  PRODUCT_FILTER,
  PRODUCT_LIST_PAGINATION,
  PRODUC_TYPE,
  SINGLE_PRODUCT_DETAILS,
} from "../config/BaseUrl.js";
import axios from "axios";
import { apiPost } from "../config/apiConfig.js";

function* fetchProductsListData(data) {
  console.log("PRODUCT_LIST started", data);
  let token = localStorage.getItem("token");

  try {
    var bodyFormData = new FormData();
    bodyFormData.append("token", token);
    bodyFormData.append("per_page", data.payload.itemPerPage);
    bodyFormData.append("page", data.payload.pageNo);
    let resp = yield apiPost(PRODUCT_LIST_PAGINATION, bodyFormData);
    console.log("PRODUCT_LIST pagination", resp);
    if (resp.response.status === 200) {
      yield put(setProductsListData(resp.response.data));
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.message;
    } else {
      console.log("unexpected error: ", error);
      // return yield put(setUserListData());
    }
  }
}
function* fetchProductsDetailByID(data) {
  // console.log("PRODUCT_LIST started");
  let token = localStorage.getItem("token");
  try {
    var bodyFormData = new FormData();
    bodyFormData.append("token", token);
    bodyFormData.append("product_id", data.payload);
    let resp = yield apiPost(SINGLE_PRODUCT_DETAILS, bodyFormData);
    // console.log("PRODUCT_data", resp);
    if (resp.response.status === 200) {
      yield put(setProductsDetailByID(resp.response.data.products));
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.message;
    } else {
      console.log("unexpected error: ", error);
      // return yield put(setUserListData());
    }
  }
}
function* fetchProductType() {
  // console.log("PRODUCT_LIST started");
  let token = localStorage.getItem("token");
  try {
    var bodyFormData = new FormData();
    bodyFormData.append("token", token);
    let resp = yield apiPost(PRODUC_TYPE, bodyFormData);
    // console.log("PRODUC_TYPE", resp);
    if (resp.response.status === 200) {
      yield put(setProductType(resp.response.data.products_type));
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.message;
    } else {
      console.log("unexpected error: ", error);
      // return yield put(setUserListData());
    }
  }
}
function* fetchProductsFilteredData(data) {
  // console.log("PRODUCT_LIST filter started", data);
  let token = localStorage.getItem("token");
  try {
    var bodyFormData = new FormData();
    bodyFormData.append("token", token);
    bodyFormData.append("product_type", data?.payload?.selectedProductType);
    bodyFormData.append("warranty_type", data?.payload?.selectedWarrantyType);
    bodyFormData.append("unit", data?.payload?.selectedUnitType);
    let resp = yield apiPost(PRODUCT_FILTER, bodyFormData);
    console.log("PRODUCT_data filter", resp);
    if (resp.response.status === 200) {
      yield put(setProductsFilteredListData(resp.response.data));
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.message;
    } else {
      console.log("unexpected error: ", error);
      // return yield put(setUserListData());
    }
  }
}
export default function* ProductsSaga() {
  yield takeEvery(tryfetchProductsFilteredList, fetchProductsFilteredData);
  yield takeEvery(tryfetchProductsList, fetchProductsListData);
  yield takeEvery(tryfetchProductsDetailByID, fetchProductsDetailByID);
  yield takeEvery(tryfetchProductType, fetchProductType);
}
