import React, { useState, useEffect } from "react";
import { VscError } from "react-icons/vsc";
import "./login.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Group1 from "../../assets/Images/Frame.png";
import WIcon from "../../assets/Images/W.png";
import styled from "styled-components";
import { Icon } from "@iconify/react";
import { tryUserLogin } from "../../Slices/AuthenticationSlice";
import { FaToggleOff, FaToggleOn } from "react-icons/fa6";

const Login = () => {
  const loginData = useSelector((state) => state.authentication);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [keepLoggedIn, setKeepLoggedIn] = useState(false);

  const handleUserIdChange = (event) => {
    setUserId(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleKeepLoggedInChange = () => {
    setKeepLoggedIn(!keepLoggedIn);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const loginData = {
      email: userId,
      password: password,
      keepLoggedIn: keepLoggedIn,
      navigate: navigate,
    };
    console.log("submitting", loginData);

    if (keepLoggedIn) {
      localStorage.setItem("checkbox", true);
      localStorage.setItem("username", userId);
      localStorage.setItem("password", password);
    } else {
      localStorage.removeItem("checkbox");
      localStorage.removeItem("username");
      localStorage.removeItem("password");
    }

    dispatch(tryUserLogin(loginData));
  };

  useEffect(() => {
    const storedKeepLoggedIn = localStorage.getItem("checkbox") === "true";
    const storedUsername = localStorage.getItem("username");
    const storedPassword = localStorage.getItem("password");

    if (storedKeepLoggedIn) {
      setKeepLoggedIn(true);
      setUserId(storedUsername);
      setPassword(storedPassword);
    }
  }, []);

  return (
    <>
      <div className="login-container">
        <main className="signup-container">
          <div className="img-icon">
            <img src={WIcon} alt="" />
          </div>
          <StyledForm className="signup-form" onSubmit={handleSubmit}>
            <div className="lg-div">
              <h1 style={{ marginBottom: "16px" }}>Log in</h1>
              <h4 style={{ fontWeight: "600" }}>
                Welcome back ! Please enter your details.
              </h4>
            </div>
            {loginData?.invalidLogin?.status === false && (
              <div
                className="fp-div"
                style={{ color: "#ff0808", textAlign: "center" }}
              >
                <span className="span-error">
                  <VscError />
                  <span style={{ marginLeft: "5px" }}>
                    {loginData?.invalidLogin?.message}
                  </span>
                  {loginData?.invalidLogin?.message ===
                    "Account Blocked Temporarily" && (
                    <span style={{ marginLeft: "5px" }}>
                      <Link to="/Account-Recovery">Recover Account</Link>
                    </span>
                  )}
                </span>
              </div>
            )}
            <span className="label-text">Email</span>
            <label className="inp">
              <input
                type="text"
                className="input-text"
                placeholder="&nbsp;"
                name="username"
                onChange={handleUserIdChange}
                value={userId}
                disabled={false}
              />
              <span className="input-icon">
                <Icon icon="mdi:envelope" />
              </span>
            </label>
            <span className="label-text">Password</span>
            <label className="inp">
              <input
                type={showPassword ? "text" : "password"}
                className="input-text"
                placeholder="&nbsp;"
                id="password"
                value={password}
                onChange={handlePasswordChange}
                name="password"
                disabled={false}
              />
              <span
                className="input-icon input-icon-password"
                onClick={() => setShowPassword(!showPassword)}
              >
                <Icon icon="ic:baseline-remove-red-eye" />
              </span>
            </label>
            <div className="keep-logged-in" onClick={handleKeepLoggedInChange}>
              <div className="d-flex align-item-center">
                {keepLoggedIn ? (
                  <FaToggleOn size={22} color="cccccc" />
                ) : (
                  <FaToggleOff size={22} />
                )}
                <label htmlFor="keep-logged-in" className="px-2">
                  Keep me logged in
                </label>
              </div>
              <div className="fp-div">
                <Link className="span-text" to={`/Forgot-Password`}>
                  <span className="forgot_password">Forgot password?</span>
                </Link>
              </div>
            </div>
            <button className="btn btn-login" type="submit">
              Login
            </button>
          </StyledForm>
          <OpineDiv>opine2023</OpineDiv>
        </main>
        <main
          className="welcome-container"
          style={{ borderLeft: "0.5px solid #ebebeb" }}
        >
          <div className="image-container">
            <img src={Group1} alt="" className="welcome-image" />
          </div>
        </main>
      </div>
    </>
  );
};

export const GlobalStyle = styled.div`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  html {
    font-size: 62.5%;
  }
  body {
    line-height: 1.6;
    color: #1a1a1a;
    font-size: 1.6rem;
    overflow-x: hidden;
  }
  a {
    color: #2196f3;
    text-decoration: none;
  }
`;
export const OpineDiv = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
`;
export const StyledForm = styled.form`
  font-family: "Public Sans", sans-serif;
`;
export default Login;
