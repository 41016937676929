import React from "react";
import styled from "styled-components";
import { IoMdAdd, IoMdRefresh } from "react-icons/io";
import { FaRegCheckCircle } from "react-icons/fa";
import { FaRegCircleXmark } from "react-icons/fa6";
import { useState } from "react";
import { FiClock } from "react-icons/fi";
import { LuFiles } from "react-icons/lu";
import { MdOutlineSettings } from "react-icons/md";
import { WhiteButton } from "../../Components/StyledComponents/Buttons";
import { tryfetchWarrantyClaimsList } from "../../Slices/WarrantySlice";
import { useDispatch } from "react-redux";
const Input = styled.input.attrs((props) => ({
  type: "text",
  size: props.small ? 5 : undefined,
}))`
  height: 38px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #aeaeae;
  background: #ffffff;
  padding: 0 0.75em;
  &:focus {
    outline: none;
    //   border: 2px solid #a6a6a6;
    //   background: white;
  }
`;

const ClearButton = styled.button`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-color: #052f855c;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const FilterOptions = styled.div`
  display: flex;
  gap: 1em;
`;

const Options = styled.div`
  display: flex;
  text-align: center;
  border-radius: 8px;
  align-items: center;
  padding: 0 1em;
  cursor: pointer;
  gap: 5px;
  // margin-bottom: 1em;
  white-space: nowrap;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Add box shadow */

  &:hover {
    color: #000000;
    border-radius: 11px;
    background: ${({ isSelected }) => (isSelected ? "#4d5fff" : "#f4f4f4")};
  }

  ${({ isSelected }) =>
    isSelected &&
    `
    color: #ffffff;
    background: #4d5fff;
  `}

  @media (max-width: 768px) {
    margin-bottom: 0;
  }
`;
const HeaderComponentClaim = ({
  filterText,
  onFilter,
  onClear,
  handleAdd,
  selectedOption,
  setSelectedOption,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [socialDrp, setsocialDrp] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  // const [selectedOption, setSelectedOption] = useState(null);
  const dispatch = useDispatch();
  const handleOptionClick = (index) => {
    // console.log(index);
    setSelectedOption(index);
  };
  const handleRefresh = () => {
    handleOptionClick("all");
    dispatch(tryfetchWarrantyClaimsList("all"));
    onFilter("");
  };

  return (
    <>
      <div
        className="col-md-12 iitem p-4"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <FilterOptions>
          <Options
            isSelected={selectedOption === "all"}
            onClick={() => handleOptionClick("all")}
          >
            <LuFiles />
            <span>All</span>
          </Options>
          <Options
            isSelected={selectedOption === "pending"}
            onClick={() => handleOptionClick("pending")}
          >
            <FiClock />
            <span>Pending</span>
          </Options>
          <Options
            isSelected={selectedOption === "approved"}
            onClick={() => handleOptionClick("approved")}
          >
            <FaRegCheckCircle color="#51ae46" />
            <span>Approved</span>
          </Options>
          <Options
            isSelected={selectedOption === "rejected"}
            onClick={() => handleOptionClick("rejected")}
          >
            <FaRegCircleXmark color="#DE3A3B" />
            <span>Rejected</span>
          </Options>
          <Options
            isSelected={selectedOption === "onService"}
            onClick={() => handleOptionClick("onService")}
          >
            <MdOutlineSettings color="#FF9900" />
            <span>On Service</span>
          </Options>
        </FilterOptions>
        <div className="d-flex gap-3">
          <div className="" style={{ width: "12em" }}>
            <Input
              id="search"
              type="text"
              placeholder="Search with Id"
              className="table-search"
              value={filterText}
              onChange={onFilter}
            />
          </div>
          <WhiteButton className="" onClick={handleRefresh}>
            <IoMdRefresh size={13} />
            <i class="bx bx-plus"></i> Refresh
          </WhiteButton>
          {/* <Link to="/Product/Add"> */}
          {/* <button className="newbtn" onClick={handleAdd}>
            <IoMdAdd color="white" />
            <span>New</span>
          </button> */}
          {/* </Link> */}
          {/* <button>
            {" "}
            <i class="bx bxs-cog"></i>
          </button> */}
          {/* <Dropdown
            className="d-inline-block"
            isOpen={socialDrp}
            toggle={() => {
              setsocialDrp(!socialDrp);
            }}
          >
            <DropdownToggle
              className="btn header-item waves-effect ddtoggle"
              tag="button"
            >
              <button>
                {" "}
                <i class="bx bx-menu"></i>
              </button>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem tag="a" href="">
                {" "}
                <i className="bx bx-import font-size-16 align-middle mr-1"></i>
                Import Invoice
              </DropdownItem>
              <DropdownItem tag="a" onClick={() => setModalIsOpen(true)}>
                {" "}
                <i className="bx bx-export font-size-16 align-middle mr-1"></i>
                Export Invoice
              </DropdownItem>
              <hr />
              <DropdownItem
                tag="a"
                onClick={() => window.location.reload(false)}
              >
                {" "}
                <i className="bx bx-refresh font-size-16 align-middle mr-1"></i>
                Refresh List
              </DropdownItem>
            </DropdownMenu>
          </Dropdown> */}
        </div>
      </div>
    </>
  );
};

export default HeaderComponentClaim;
