import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { IoClose, IoPrintOutline } from "react-icons/io5";
import { PiPencilSimple } from "react-icons/pi";
import { TfiTrash } from "react-icons/tfi";
import { Link } from "react-router-dom";
import styled from "styled-components";
import enlargedImg from "../../assets/Product/Png final.png";
import Img1 from "../../assets/Product/Rectangle 1054.png";
import Img2 from "../../assets/Product/Rectangle 1055.png";
import Img3 from "../../assets/Product/Rectangle 1056.png";
import Img4 from "../../assets/Product/Rectangle 1057.png";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import DeleteAisle from "./DeleteModal";
import { ToastContainer, toast } from "react-toastify";
import { DELETE_BATCH, OEM_DELETE } from "../../config/BaseUrl";
import { tryfetchBatchList } from "../../Slices/BatchSlice";
import { apiPost } from "../../config/apiConfig";
import { IoIosArrowDown } from "react-icons/io";

const SingleOEMView = ({ close, data, itemId, handleblock }) => {
  const dispatch = useDispatch();
  const Data = useSelector((state) => state.OEM?.OEMDetailByID);
  const [selectedImage, setSelectedImage] = useState(enlargedImg);
  const [deleteModel, setdeleteModel] = useState(false);
  const [showData, setShowData] = useState(data.is_active);
  const [infoVisible, setInfoVisible] = useState(true);
  const [addressVisible, setAddressVisible] = useState(true);
  const [contactVisible, setContactVisible] = useState(true);

  const handleMiniImgClick = (imgSrc) => {
    setSelectedImage(imgSrc);
  };
  const handleOpenDelete = (id) => {
    // setId(id);
    setdeleteModel(true);
  };
  const handleDelete = (id) => {
    let token = localStorage.getItem("token");
    var bodyFormData = new FormData();
    bodyFormData.append("token", token);
    bodyFormData.append("id", itemId);
    let resp = apiPost(OEM_DELETE, bodyFormData);
    resp.then((resp) => {
      console.log("delete resp", resp);
      switch (resp.response.status) {
        case 200:
          dispatch(tryfetchBatchList());
          toast.success(resp.response.data.message);
          setTimeout(() => {
            setdeleteModel(false);
            close();
          }, 2000);
          break;

        default:
        // getData();
      }
    });
  };

  const toggleVisibility = (setVisible, visible) => {
    setVisible(!visible);
  };

  return (
    <>
      <ToastContainer />

      <RightBar>
        <Container>
          <Row>
            <Col md={8} className="d-flex gap-5">
              <div>
                <Heading style={{ fontWeight: "500" }} md={12}>
                  {Data?.oem_name}
                </Heading>
                <span>{Data?.id}</span>
              </div>
              <div>
                {showData === 0 ? (
                  <Heading
                    onClick={() => {
                      handleblock(data);
                      setShowData(1);
                    }}
                  >
                    <BsToggleOff
                      size={25}
                      color="#a8a2a2"
                      style={{ cursor: "pointer", transition: "0.5s" }}
                    />
                  </Heading>
                ) : (
                  <Heading
                    onClick={() => {
                      handleblock(data);
                      setShowData(0);
                    }}
                  >
                    <BsToggleOn
                      size={25}
                      color="#0cb513"
                      style={{ cursor: "pointer", transition: "0.5s" }}
                    />
                  </Heading>
                )}
              </div>
            </Col>
            <Col md={4}>
              <Row className=" py-4">
                <IconContainer>
                  <Link to={`/OEM/Edit/${itemId}`}>
                    <IconWrapper className="icons">
                      <PiPencilSimple />
                    </IconWrapper>
                  </Link>
                  <IconWrapper
                    className="icons"
                    onClick={close}
                    style={{ border: "0.5px solid #ED2D22" }}
                  >
                    <IoClose color="#ED2D22" />
                  </IconWrapper>
                </IconContainer>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Row className="py-4">
                <Info
                  md={12}
                  onClick={() => toggleVisibility(setInfoVisible, infoVisible)}
                >
                  <span>INFORMATION</span>
                  <span
                    style={{
                      transform: infoVisible
                        ? "rotate(0deg)"
                        : "rotate(180deg)",
                      transition: "transform 0.3s",
                    }}
                  >
                    <IoIosArrowDown />
                  </span>
                </Info>
                <Line />
              </Row>
              {infoVisible && (
                <>
                  <Row className="py-3">
                    <Col md={4}>
                      <Key>OEM Name</Key>
                      <Value>{Data?.oem_name}</Value>
                    </Col>
                    <Col md={4}>
                      <Key>Mobile Number</Key>
                      <Value>{Data?.mobile}</Value>
                    </Col>
                    <Col md={4}>
                      <Key>Email Id</Key>
                      <Value>{Data?.email}</Value>
                    </Col>
                  </Row>
                  <Row className="py-3">
                    <Col md={4}>
                      <Key>OEM Display Name</Key>
                      <Value>{Data?.display_name}</Value>
                    </Col>
                    <Col md={4}>
                      <Key>Landline Number</Key>
                      <Value>
                        {Data?.landline === "null" ? "-" : Data?.landline}
                      </Value>
                    </Col>
                    <Col md={4}>
                      <Key>Website</Key>
                      <Value>{Data?.website}</Value>
                    </Col>
                  </Row>
                  <Row className="py-3">
                    <Col md={4}>
                      <Key>Fax Number</Key>
                      <Value>{Data?.fax}</Value>
                    </Col>
                    <Col md={4}>
                      <Key>GST</Key>
                      <Value>{Data?.gst_number}</Value>
                    </Col>
                    <Col md={4}>
                      <Key>PAN</Key>
                      <Value>{Data?.pan}</Value>
                    </Col>
                  </Row>
                  <Row className="py-3">
                    <Col md={4}>
                      <Key>GST Treatment</Key>
                      <Value>{Data?.gst_tratment_name}</Value>
                    </Col>
                  </Row>
                </>
              )}

              <Row className="py-4">
                <Info
                  md={12}
                  onClick={() =>
                    toggleVisibility(setAddressVisible, addressVisible)
                  }
                >
                  <span>ADDRESS</span>
                  <span
                    style={{
                      transform: addressVisible
                        ? "rotate(0deg)"
                        : "rotate(180deg)",
                      transition: "transform 0.3s",
                    }}
                  >
                    <IoIosArrowDown />
                  </span>
                </Info>
                <Line />
              </Row>
              {addressVisible && (
                <Row className="py-3">
                  <Col md={4}>
                    <Key>Billing Address</Key>
                    <Value>{Data?.billing_address}</Value>
                  </Col>
                  <Col md={4}>
                    <Key>Shipping Address</Key>
                    <Value>{Data?.shipping_address}</Value>
                  </Col>
                </Row>
              )}

              <Row className="py-4">
                <Info
                  md={12}
                  onClick={() =>
                    toggleVisibility(setContactVisible, contactVisible)
                  }
                >
                  <span>CONTACT PERSON</span>
                  <span
                    style={{
                      transform: contactVisible
                        ? "rotate(0deg)"
                        : "rotate(180deg)",
                      transition: "transform 0.3s",
                    }}
                  >
                    <IoIosArrowDown />
                  </span>
                </Info>
                <Line />
              </Row>
              {contactVisible && (
                <Row className="py-3">
                  {Data?.contact_peron?.map((contact, index) => {
                    return (
                      <Col md={4} key={contact.id}>
                        <Key>{contact?.designation}</Key>
                        <Value>{contact?.name}</Value>
                        <Value>{contact?.email}</Value>
                        <Value>{contact?.contact_no}</Value>
                      </Col>
                    );
                  })}
                </Row>
              )}
            </Col>
          </Row>
        </Container>
        <DeleteAisle
          openModal={deleteModel}
          setOpenModel={setdeleteModel}
          handleDeleteUom={handleDelete}
          id={itemId}
        />
      </RightBar>
    </>
  );
};

const RightBar = styled.div`
  width: 100%;
  padding: 2rem 2.5rem;
  overflow: auto;
`;
const IconContainer = styled.div`
  display: flex;
  justify-content: end;
  padding-right: 2em;
  gap: 2rem;
`;
const EnlargedImg = styled.div`
  display: flex;
  width: 16rem;
  height: 16rem;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
const MiniImg = styled(Col)`
  display: flex;
  margin-top: 1em;
  width: 5rem;
  height: 5rem;
  justify-content: center;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
const IconWrapper = styled.div`
  border: 0.5px solid #aeaeae;
  border-radius: 5px;
  padding: 0.6em;
  display: flex;
  justify-content: space-around;
  cursor: pointer;
  height: min-content;
`;
const Heading = styled.div`
  margin-top: 1rem;
  font-size: 20px;
  font-weight: 400;
`;
const Info = styled(Col)`
  margin-top: 2rem;
  padding: 0;
  display: flex;
  font-weight: 400;
  justify-content: space-between;
  cursor: pointer;
`;
const Line = styled.hr`
  color: #bfbfbf;
`;
const Key = styled(Row)`
  font-weight: 300;
`;
const Value = styled(Row)`
  color: #bfbfbf;
`;

export default SingleOEMView;
