import { put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { apiPost } from "../config/apiConfig.js";
import {
  setOEMListData,
  tryfetchOEMList,
  setOEMDetailByID,
  tryfetchOEMDetailByID,
  setOEMProfileByID,
  tryfetchOEMProfileByID,
} from "../Slices/OEMSlice.jsx";
import { OEM_LIST, OEM_BY_ID, OEM_LIST_PAGINATION } from "../config/BaseUrl.js";

function* fetchOEMListData(data) {
  let token = localStorage.getItem("token");

  try {
    var bodyFormData = new FormData();
    bodyFormData.append("token", token);
    bodyFormData.append("per_page", data.payload.itemPerPage);
    bodyFormData.append("page", data.payload.pageNo);
    let resp = yield apiPost(OEM_LIST_PAGINATION, bodyFormData);
    if (resp.response.status === 200) {
      yield put(setOEMListData(resp.response.data));
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.message;
    } else {
      console.log("unexpected error: ", error);
      // return yield put(setUserListData());
    }
  }
}
function* fetchOEMDetailByID(data) {
  let token = localStorage.getItem("token");
  try {
    var bodyFormData = new FormData();
    bodyFormData.append("token", token);
    bodyFormData.append("id", data.payload);
    let resp = yield apiPost(OEM_BY_ID, bodyFormData);
    console.log("OEM_BY_ID", resp);
    if (resp.response.status === 200) {
      yield put(setOEMDetailByID(resp.response.data.data));
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.message;
    } else {
      console.log("unexpected error: ", error);
      // return yield put(setUserListData());
    }
  }
}
function* fetchOEMProfileByID(data) {
  let token = localStorage.getItem("token");
  try {
    var bodyFormData = new FormData();
    bodyFormData.append("token", token);
    bodyFormData.append("id", data.payload);
    let resp = yield apiPost(OEM_BY_ID, bodyFormData);
    // console.log("OEM_Profile_BY_ID", resp);
    if (resp.response.status === 200) {
      yield put(setOEMProfileByID(resp.response.data.data));
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.message;
    } else {
      console.log("unexpected error: ", error);
      // return yield put(setUserListData());
    }
  }
}

export default function* OEMSaga() {
  yield takeEvery(tryfetchOEMList, fetchOEMListData);
  yield takeEvery(tryfetchOEMDetailByID, fetchOEMDetailByID);
  yield takeEvery(tryfetchOEMProfileByID, fetchOEMProfileByID);
}
