import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { FormControl, InputLabel } from "@mui/material";
import Select from "react-select";
import {
  FilterButton,
  FilterButtonCancel,
  SaveButton,
  SubmitButton,
} from "../../Components/StyledComponents/Buttons";

import { apiPost } from "../../config/apiConfig";
import axios from "axios";
import {
  tryfetchProductsFilteredList,
  tryfetchProductsList,
} from "../../Slices/ProductSlice";
import { useDispatch } from "react-redux";
import {
  tryfetchFilteredWarrantyList,
  tryfetchWarrantysList,
} from "../../Slices/WarrantySlice";

const FilterBox = styled.div`
  right: 4rem;
  width: 50rem;
  top: 16rem;
  border: solid 1px #cbc2c2;
  background-color: #ffffff;
  padding: 1em 2em;
  box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.25);
  position: absolute;
  z-index: 9999;
  border-radius: 6px;
`;

const FilterDropdown = ({ onSave, onClose, productType, unit, warranty }) => {
  const dispatch = useDispatch();

  const [selectedProductType, setSelectedProductType] = useState("");
  const [selectedPeriodType, setSelectedPeriodType] = useState("");
  const [selectedWarrantyType, setSelectedWarrantyType] = useState("");
  const [selectedUnitType, setSelectedUnitType] = useState("");

  const productOptions = productType?.map((item) => ({
    value: item.id,
    label: item.name,
  }));
  const unitOptions = unit?.map((item) => ({
    value: item.id,
    label: item.name,
  }));
  const warrantyOptions = warranty?.map((item) => ({
    value: item.id,
    label: item.warranty_package_name,
  }));

  const warrantyPeriodOptions = [
    {
      value: "day",
      label: "Day",
    },
    {
      value: "month",
      label: "Month",
    },
    {
      value: "year",
      label: "Year",
    },
  ];
  const filterBoxRef = useRef(null);

  const handleClickOutside = (event) => {
    if (filterBoxRef.current && !filterBoxRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [filterBoxRef, onClose]);

  const filterData = () => {
    // console.log("filterData");
    var data = {
      warranty_package_name: selectedWarrantyType,
      warranty_period_type: selectedPeriodType,
      // selectedUnitType,
    };
    dispatch(tryfetchFilteredWarrantyList(data));
    onSave();
  };
  const cancelFilter = () => {
    // console.log("filterData cancel");
    dispatch(tryfetchWarrantysList());
    onClose();
  };

  return (
    <FilterBox className="filter-dropdown" ref={filterBoxRef}>
      <h2>Filter Options</h2>
      <div className="d-flex row py-3 ">
        <div className="col-md-6 px-3">
          <FormControl fullWidth>
            {/* <InputLabel id="">Product Type</InputLabel> */}
            <Select
              placeholder="Period Type"
              options={warrantyPeriodOptions}
              // isClearable
              value={warrantyPeriodOptions?.find(
                (option) => option.value === selectedProductType
              )}
              onChange={(selectedOption) =>
                setSelectedPeriodType(selectedOption.value)
              }
            />
          </FormControl>
        </div>
        <div className="col-md-6 px-3">
          <FormControl fullWidth>
            {/* <InputLabel id="">Warranty Type</InputLabel> */}
            <Select
              placeholder="Warranty Type"
              options={warrantyOptions}
              // isClearable
              value={warrantyOptions?.find(
                (option) => option.value === selectedWarrantyType
              )}
              onChange={(selectedOption) =>
                setSelectedWarrantyType(selectedOption.label)
              }
            />
          </FormControl>
        </div>
      </div>
      {/* <div className="d-flex py-3">
        <div className="col-md-6 px-3">
          <FormControl fullWidth>
          <InputLabel id="">Unit Type</InputLabel> 
            <Select
              placeholder="Unit Type"
              options={unitOptions}
              // isClearable
              value={unitOptions?.find(
                (option) => option.value === selectedUnitType
              )}
              onChange={(selectedOption) =>
                setSelectedUnitType(selectedOption.value)
              }
            />
          </FormControl>
        </div>
      </div> */}
      {/* <div className="row">
        <div className="col-md-12 buttons d-flex justify-content-end px-5 py-3 ">
          <SubmitButton
            type="button"
            onClick={cancelFilter}
            className="mx-5 save-button"
          >
            Cancel
          </SubmitButton>
          <SaveButton
            type="submit"
            style={{ whiteSpace: "nowrap" }}
            className=""
            onClick={filterData}
          >
            Filter Data
          </SaveButton>
        </div>
      </div> */}
      <div className="row px-2">
        <div className="col-md-6 buttons d-flex justify-content-end ">
          <FilterButtonCancel type="button" onClick={cancelFilter}>
            Cancel
          </FilterButtonCancel>
        </div>
        <div className="col-md-6 buttons d-flex justify-content-end">
          <FilterButton
            type="submit"
            style={{ whiteSpace: "nowrap" }}
            onClick={filterData}
          >
            Filter Data
          </FilterButton>
        </div>
      </div>
    </FilterBox>
  );
};

export default FilterDropdown;
