import React, { useEffect } from "react";
import styled from "styled-components";
import { useState } from "react";
import Logo from "../../assets/Images/W.png";
import { Link, useNavigate } from "react-router-dom";
// import { IoMdMenu } from "react-icons/io";
import { FiChevronDown } from "react-icons/fi";
import { IoAddCircleOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { tryUserLogOut } from "../../Slices/AuthenticationSlice";
import vendor from "../../assets/SidebarIcons/shop.png";
import vendorDark from "../../assets/SidebarIcons/shopD.png";
import dashboard from "../../assets/SidebarIcons/category-2.svg";
import dashboardD from "../../assets/SidebarIcons/category-2D.png";
import products from "../../assets/SidebarIcons/bag-2.png";
import productsD from "../../assets/SidebarIcons/bag-2D.png";
import batch from "../../assets/SidebarIcons/document-copy.png";
import batchD from "../../assets/SidebarIcons/document-copyD.png";
import report from "../../assets/SidebarIcons/home-trend-up.png";
import reportD from "../../assets/SidebarIcons/home-trend-upD.png";
import setting from "../../assets/SidebarIcons/setting.png";
import settingD from "../../assets/SidebarIcons/settingD.png";
import subscription from "../../assets/SidebarIcons/shield-tick.png";
import subscriptionD from "../../assets/SidebarIcons/shield-tickD.png";
import oem from "../../assets/SidebarIcons/oem.png";
import oemD from "../../assets/SidebarIcons/oemD.png";
import supplier from "../../assets/SidebarIcons/supplier.png";
import supplierD from "../../assets/SidebarIcons/supplierD.png";
import salesP from "../../assets/SidebarIcons/salesP.png";
import salesPD from "../../assets/SidebarIcons/salesPD.png";
import { FaDollarSign } from "react-icons/fa6";
import { IoIosArrowRoundForward } from "react-icons/io";

const Sidebar = () => {
  const [isOpen] = useState(true);
  //   const handleTrigger = () => setIsOpen(!isOpen);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //   const params = useParams();
  //   const location = useLocation();
  const role_name = localStorage.getItem("roleName");
  // const handleLogout = () => {
  //   dispatch(tryUserLogOut({ navigate }));
  // };
  const [hoveredItem, setHoveredItem] = useState(null);
  const [selectedDiv, setSelectedDiv] = useState(1);
  const [selectedDropdownItem, setSelectedDropdownItem] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const sidebarItems = [
    {
      isVisible: true,
      key: 1,
      index: 1,
      icon: <img src={dashboard} alt="" srcSet="" />,
      iconOnSelect: <img src={dashboardD} alt="" srcSet="" />,
      text: "Dashboard",
      link: "/",
      dropdown: false,
      addButton: false,
      addLink: "/",
    },
    {
      isVisible: role_name === "oem" ? true : false,
      key: 2,
      index: 2,
      icon: <img src={products} alt="" srcSet="" />,
      iconOnSelect: <img src={productsD} alt="" srcSet="" />,
      text: "Products",
      link: "/Product-List",
      dropdown: false,
      addButton: true,
      addLink: "/Product/Add",
    },
    {
      isVisible: role_name === "oem" ? true : false,
      key: 3,
      index: 3,
      icon: <img src={batch} alt="" srcSet="" />,
      iconOnSelect: <img src={batchD} alt="" srcSet="" />,
      text: "Batch",
      link: "/Batch-List",
      dropdown: false,
      addButton: true,
      addLink: "/Batch/Add",
    },
    {
      isVisible: role_name === "oem" ? true : false,
      key: 4,
      index: 4,
      icon: <img src={subscription} alt="" srcSet="" />,
      iconOnSelect: <img src={subscriptionD} alt="" srcSet="" />,
      text: "Warranty",
      // link: "/Warranty-List",
      dropdown: true,
      addButton: false,
      addLink: "/",
      dropdownList: [
        {
          dropdownKey: 1,
          dropdownText: "Warranty Settings",
          dropdownLink: "/Warranty-List",
          // dropdownIcon: (
          //   <MdOutlineNotificationsActive
          //     size={20}
          //     color={selecteddropdownDiv === 1 ? "#00aeef" : "#696869"}
          //   />
          // ),
        },
        {
          dropdownKey: 2,
          dropdownText: "Warranty Registration",
          dropdownLink: "/",
          // dropdownIcon: (
          //   <MdOutlineNotificationAdd
          //     size={20}
          //     color={selecteddropdownDiv === 2 ? "#00aeef" : "#696869"}
          //   />
          // ),
        },
        {
          dropdownKey: 3,
          dropdownText: "Warranty Claim",
          dropdownLink: "/Warranty-Claim-List",
          // dropdownIcon: (
          //   <MdOutlineNotificationAdd
          //     size={20}
          //     color={selecteddropdownDiv === 2 ? "#00aeef" : "#696869"}
          //   />
          // ),
        },
      ],
    },

    {
      isVisible: role_name === "oem" ? true : false,
      key: 5,
      index: 5,
      icon: <img src={report} alt="" srcSet="" />,
      iconOnSelect: <img src={reportD} alt="" srcSet="" />,
      text: "Reports",
      link: "/",
      dropdown: false,
      addButton: false,
      addLink: "/",
    },
    // {
    //   isVisible: role_name === "oem" ? true : false,
    //   key: 6,
    //   index: 6,
    //   icon: <img src={setting} alt="" srcSet="" />,
    //   iconOnSelect: <img src={settingD} alt="" srcSet="" />,
    //   text: "Settings",
    //   link: "/",
    //   dropdown: false,
    //   addButton: false,
    //   addLink: "/",
    // },
    {
      isVisible: true,
      key: 7,
      index: 7,
      icon: <FaDollarSign color="#5A5A5A" />,
      iconOnSelect: <FaDollarSign color="#000000" />,
      text: "Subscription",
      link: role_name === "oem" ? "/Subscription-List" : "",
      dropdown: role_name === "oem" ? false : true,
      addButton: false,
      addLink: "/Subscription/Add",
      dropdownList: [
        {
          dropdownKey: 1,
          dropdownText: "List",
          dropdownLink: "/Subscription-List",
          // dropdownIcon: (
          //   <MdOutlineNotificationsActive
          //     size={20}
          //     color={selecteddropdownDiv === 1 ? "#00aeef" : "#696869"}
          //   />
          // ),
        },
        {
          dropdownKey: 2,
          dropdownText: "Settings",
          dropdownLink: "/Subscription-Package-List",
          // dropdownIcon: (
          //   <MdOutlineNotificationAdd
          //     size={20}
          //     color={selecteddropdownDiv === 2 ? "#00aeef" : "#696869"}
          //   />
          // ),
        },
      ],
    },
    {
      isVisible: role_name === "oem" ? true : false,
      key: 8,
      index: 8,
      icon: <img src={vendor} alt="" srcSet="" />,
      iconOnSelect: <img src={vendorDark} alt="" srcSet="" />,
      text: "Vendors",
      // link: "/Service-Center-List",
      dropdown: true,
      addButton: false,
      addLink: "/Subscription/Add",
      dropdownList: [
        // {
        //   dropdownKey: 1,
        //   dropdownText: "Service Center",
        //   dropdownLink: "/Service-Center-List",
        //   // dropdownIcon: (
        //   //   <MdOutlineNotificationsActive
        //   //     size={20}
        //   //     color={selecteddropdownDiv === 1 ? "#00aeef" : "#696869"}
        //   //   />
        //   // ),
        // },
        {
          dropdownKey: 2,
          dropdownText: "Sales Partner",
          dropdownLink: "/Sales-Partners-List",
          // dropdownIcon: (
          //   <MdOutlineNotificationAdd
          //     size={20}
          //     color={selecteddropdownDiv === 2 ? "#00aeef" : "#696869"}
          //   />
          // ),
        },
        // {
        //   dropdownKey: 3,
        //   dropdownText: "Supplier",
        //   dropdownLink: "/Supplier-List",
        //   // dropdownIcon: (
        //   //   <MdOutlineNotificationAdd
        //   //     size={20}
        //   //     color={selecteddropdownDiv === 2 ? "#00aeef" : "#696869"}
        //   //   />
        //   // ),
        // },
      ],
    },
    {
      isVisible: role_name === "admin" ? true : false,
      key: 9,
      index: 9,
      icon: <img src={oem} alt="" srcSet="" />,
      iconOnSelect: <img src={oemD} alt="" srcSet="" />,
      text: "OEM",
      link: "/OEM-List",
      dropdown: false,
      addButton: false,
      addLink: "/OEM/Add",
    },
    // {
    //   isVisible: role_name === "admin" ? true : false,
    //   key: 10,
    //   index: 10,
    //   icon: <img src={supplier} alt="" srcSet="" />,
    //   iconOnSelect: <img src={supplierD} alt="" srcSet="" />,
    //   text: "Supplier",
    //   link: "/OEM-Supplier-List",
    //   dropdown: false,
    //   addButton: false,
    //   addLink: "/Supplier/Add",
    // },
    {
      isVisible: role_name === "admin" ? true : false,
      key: 11,
      index: 11,
      icon: <img src={salesP} alt="" srcSet="" />,
      iconOnSelect: <img src={salesPD} alt="" srcSet="" />,
      text: "Sales Partner",
      link: "/Sales-Partners-List",
      dropdown: false,
      addButton: false,
      addLink: "/Sales-Partner/Add",
    },
    {
      isVisible: true,
      key: 12,
      index: 12,
      icon: <img src={setting} alt="" srcSet="" />,
      iconOnSelect: <img src={settingD} alt="" srcSet="" />,
      text: "Settings",
      // link: "/Settings-List",
      dropdown: true,
      addButton: false,
      addLink: "/Subscription/Add",
      dropdownList:
        role_name == "oem"
          ? [
              {
                dropdownKey: 5,
                dropdownText: "Unit",
                dropdownLink: "/Settings-List/unit",
                // dropdownIcon: (
                //   <MdOutlineNotificationAdd
                //     size={20}
                //     color={selecteddropdownDiv === 2 ? "#00aeef" : "#696869"}
                //   />
                // ),
              },
              {
                dropdownKey: 4,
                dropdownText: "Product type",
                dropdownLink: "/Settings-List/product_type",
                // dropdownIcon: (
                //   <MdOutlineNotificationsActive
                //     size={20}
                //     color={selecteddropdownDiv === 1 ? "#00aeef" : "#696869"}
                //   />
                // ),
              },
            ]
          : [
              {
                dropdownKey: 6,
                dropdownText: "GST Type",
                dropdownLink: "/Settings-List/gst",
                // dropdownIcon: (
                //   <MdOutlineNotificationAdd
                //     size={20}
                //     color={selecteddropdownDiv === 2 ? "#00aeef" : "#696869"}
                //   />
                // ),
              },
            ],
    },
  ];

  const handleSelect = (items, index) => {
    if (items.dropdown) {
      if (selectedItem !== items.key) {
        setSelectedItem(items.key);
      } else {
        setSelectedItem(null);
      }
      setSelectedDiv(items.key);

      // Update the selected dropdown item
      setSelectedDropdownItem(null);
    } else {
      setSelectedItem(null);
      setSelectedDiv(items.key);
      setSelectedDropdownItem(null);
    }
    setHoveredItem(null);
  };
  const handleLogout = () => {
    dispatch(tryUserLogOut({ navigate }));
  };
  // useEffect(() => {
  // console.log("selectedItem", selectedItem);
  // }, [selectedItem]);

  return (
    <SidebarContainer isOpen={isOpen}>
      <TriggerButton
      //   onClick={handleTrigger}
      >
        <LogoContainer>
          <LogoImage src={Logo} alt="Logo" isOpen={isOpen} />
          <LogoText isOpen={isOpen}></LogoText>
        </LogoContainer>
        {/* {isOpen ? (
            <IoMdMenu size={30} color="#fff" />
            ) : (
            <IoMdMenu size={30} color="#fff" />
            )} */}
      </TriggerButton>
      <SidebarContent>
        {sidebarItems.map((items, index) => {
          if (items.isVisible) {
            // Rest of the code...
            return (
              <Link key={items.key} to={items.link}>
                <SidebarItem
                  className="row"
                  selected={selectedDiv === items.key}
                  hovered={hoveredItem === items.key}
                  onMouseEnter={() => setHoveredItem(items.key)}
                  onMouseLeave={() => setHoveredItem(null)}
                  onClick={() => handleSelect(items, index)}
                  style={{
                    marginBottom:
                      items.dropdown && selectedItem === items.key
                        ? "0"
                        : undefined,
                  }}
                >
                  {/* <img
                  src={
                    selectedDiv === items.key ? items.iconOnSelect : items.icon
                  }
                  alt=""
                /> */}
                  <div className="col-md-2 d-flex align-items-center">
                    {" "}
                    {selectedDiv === items.key
                      ? items.iconOnSelect
                      : items.icon}
                  </div>
                  <div className="col-md-6">
                    <ItemName className="item-name">
                      <span>{items.text}</span>
                    </ItemName>
                  </div>
                  <div className="col-md-2">
                    {items.dropdown && (
                      <DropdownIcon className="d-flex align-item-center">
                        <FiChevronDown
                          className={`icon ${
                            isOpen && selectedItem === items.key ? "up" : ""
                          }`}
                          size={18}
                          color="#818181"
                        />
                      </DropdownIcon>
                    )}
                  </div>
                  <div className="col-md-2">
                    {items.addButton && (
                      <Link to={items.addLink}>
                        {" "}
                        {/* Wrap IoAddCircleOutline with Link */}
                        <ItemAdd>
                          {isOpen && (
                            <IoAddCircleOutline
                              size={18}
                              color={
                                selectedDiv === items.key
                                  ? "#000000"
                                  : "#818181"
                              }
                            />
                          )}
                        </ItemAdd>
                      </Link>
                    )}
                  </div>
                </SidebarItem>
                <DropdownContainer>
                  {items.dropdown &&
                    selectedItem === items.key &&
                    items?.dropdownList?.map((dropdown, index) => (
                      <Link
                        key={index}
                        to={dropdown.dropdownLink}
                        style={{ position: "relative" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                            position: "absolute",
                            top: "50%",
                            left: "0",
                            transform: "translateY(-50%)",
                          }}
                        >
                          <DropdownLine />
                          <IoIosArrowRoundForward
                            color="#ccc"
                            style={{ marginLeft: "-8px" }}
                          />
                        </div>

                        {index === items.dropdownList.length - 1 ? (
                          <div
                            style={{
                              width: "4px",
                              height: "50.25%",
                              backgroundColor: "white",
                              position: "absolute",
                              bottom: "-0.8px",
                              left: "-2px",
                            }}
                          />
                        ) : null}

                        <DropdownContent
                          selected={
                            selectedDropdownItem === dropdown.dropdownKey
                          }
                          onMouseEnter={() => setHoveredItem(items.key)}
                          onMouseLeave={() => setHoveredItem(null)}
                          onClick={() =>
                            setSelectedDropdownItem(dropdown.dropdownKey)
                          }
                        >
                          {/* <div
                          style={{
                            paddingRight: "1em",
                            display: "flex",
                            alignContent: "center",
                          }}
                        >
                          {dropdown.dropdownIcon}
                        </div> */}
                          {dropdown.dropdownText}
                        </DropdownContent>
                      </Link>
                    ))}
                </DropdownContainer>
              </Link>
            );
          }
        })}
        {/* <SidebarItem
          className="row"
          style={{ bottom: "0", position: "absolute" }}
          onClick={() => handleLogout()}
        >
          <div className="col-md-2">
            <IoLogOutOutline />
          </div>
          <div className="col-md-10">
            <span>Logout</span>
          </div>
        
        </SidebarItem> */}
      </SidebarContent>
      {/* Rest of the code... */}
    </SidebarContainer>
  );
};
const SidebarContainer = styled.div`
  // width: ${(props) => (props.isOpen ? "14.5%" : "4%")};
  width: 260px; /* Adjust the width as needed */
  transition: all 0.3s ease-in-out;
  overflow: auto;
  position: fixed;
  font-family: "Public Sans", sans-serif;
  height: 100%;
  /* margin-right: 1%; */
  background-color: #ffffff;
  color: #fff;
  //   padding-top: 20px;
  border-radius: 22px;
  left: 0;
  top: 0;
`;
// box-shadow: ${(props) =>
//   props.isOpen ? "5px 0px 10px rgba(0, 0, 0, 0.2)" : "none"};

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: ${(props) => (props.isOpen ? "20px" : "10px")};
`;

const LogoImage = styled.img`
  width: 40px;
  height: 40px;
  margin-right: ${(props) => (props.isOpen ? "10px" : "0")};
`;

const LogoText = styled.span`
  display: ${(props) => (props.isOpen ? "inline-block" : "none")};
  font-size: 16px;
  font-weight: bold;
`;

const TriggerButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 15px;
  //   background-color: #343a40;
  color: #fff;
`;

const SidebarContent = styled.div`
  padding: 30px 15px 0px 15px;
`;

const SidebarItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 31px 10px 13px;
  // width: 100%;
  margin: 15px 0;
  //color: #5a5a5a;
  font-family: "Public Sans", sans-serif;
  cursor: pointer;
  font-weight: ${(props) => (props.selected ? `500` : "")};
  color: ${(props) => (props.selected ? `#000000` : "#5a5a5a")};
  border-radius: ${(props) => (props.selected ? `11px` : "")};
  background: ${(props) => (props.selected ? `#f4f4f4` : "")};
  &:hover {
    color: #000000;
    // border-radius: 22px;
    // background: #f4f4f4;
  }
`;

const ItemName = styled.div`
  width: 60%;
  display: flex;
  font-size: 14px;
  white-space: nowrap;
  font-family: "Public Sans", sans-serif;
`;
const ItemAdd = styled.div`
  display: flex;
  align-items: center;
  transition: color 0.3s ease-in-out;
  &:hover {
    color: #000000; // Replace with your desired hover color
  }
`;
const DropdownContent = styled.div`
  border-radius: 11px;
  width: 170px;
  height: 40px;
  font-family: "Public Sans", sans-serif;
  margin: 0.25em 0em 0.25em 2.1em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${(props) => (props.selected ? "#000000" : "#818181")};
  padding: 0.25em 0.8em;
  font-weight: 400;
  font-size: 14px;
  &:hover {
    color: #000000;
  }
  ${(props) =>
    props.selected &&
    `
    background: #f4f4f4;
    border-radius: 11px;
  `}
`;
const DropdownContainer = styled.div`
  width: 1px;
  // height: 100%;
  margin-left: 2em;
  background-color: #ccc; /* Line color */
`;
const DropdownLine = styled.div`
  width: 2rem;
  height: 0.5px;
  margin: 0;
  z-index: 99999;
  background-color: #ccc; /* Line color */
`;
const DropdownIcon = styled.div`
  .icon {
    transition: transform 0.2s ease-in-out;
  }
  .icon.up {
    transform: rotate(180deg);
  }
`;
export default Sidebar;
