import React, { useEffect, useMemo, useState } from "react";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import {
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
} from "mdb-react-ui-kit";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import SingleBatchView from "./SingleSPView";
import { tryfetchOEMDetailByID } from "../../Slices/OEMSlice";
import {
  tryfetchSalesPartnerDetailByID,
  tryfetchSalesPartnersList,
} from "../../Slices/VendorSlice";
import AddSalesPartnerModal from "./AddSalesPartnerModal";
import HeaderComponentSP from "./HeaderComponentSP";
import axios from "axios";
import { BLOCK_SALES_PARTNER } from "../../config/BaseUrl";
import { apiPost } from "../../config/apiConfig";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import {
  Page,
  PageContent,
  PageSingle,
  customHeaderStyleId,
} from "../../Components/StyledComponents/Common";
import { PiPencilSimple } from "react-icons/pi";

const OEMList = () => {
  const dispatch = useDispatch();
  const ListData = useSelector(
    (state) => state?.vendor?.salesPartnersTableData
  );
  const [filterText, setFilterText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [itemId, setItemId] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [type, setType] = useState(null);
  const itemsPerPage = 10; // Set the number of items per page
  const totalPages = Math.ceil(filteredData?.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const [blockData, setBlockData] = useState([]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  let roleName = localStorage.getItem("name");
  // Get the current page data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData?.slice(indexOfFirstItem, indexOfLastItem);
  const getPageNumbers = () => {
    const maxPagesToShow = 3;
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
    let startPage = currentPage - halfMaxPagesToShow;
    let endPage = currentPage + halfMaxPagesToShow;

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(totalPages, maxPagesToShow);
    } else if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, totalPages - maxPagesToShow + 1);
    }

    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  };

  const columnDefs = useMemo(() => [
    {
      field: "name",
      headerName: " Sales Partner Name",
      headerTooltip: "Sales Partner Name",
    },
    {
      field: "partner_id",
      headerName: "ID",
      headerTooltip: "ID",
    },
    // {
    //   field: "supplier_partner_no",
    //   headerName: "Supplier Partner No",
    //   headerTooltip: "Supplier Partner No",
    // },
    {
      field: "gst_no",
      headerName: "GST",
      headerTooltip: "GST",
    },

    {
      field: "mobile",
      headerName: "Phone",
      headerTooltip: "Phone",
    },
    {
      field: "email",
      headerName: "Email",
      headerTooltip: " Email",
    },
  ]);
  const columnDefs1 = useMemo(() => [
    {
      field: "name",
      headerName: "Sales Partner Name",
      headerTooltip: "Sales Partner Name",
    },
  ]);

  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      filter: true,
      sortable: true,
      resizable: true,
    }),
    []
  );

  const handleClear = () => {
    if (filterText) {
      setFilterText("");
    }
  };

  const handleSearch = (searchValue) => {
    setFilterText(searchValue);
    console.log("searchValue", searchValue);
    const filteredList = ListData?.filter((row) => {
      const id = String(row?.partner_id).toLowerCase();
      const name = String(row?.name).toLowerCase();
      const search = searchValue?.toLowerCase();
      return id.includes(search) || name.includes(search);
    });
    setFilteredData(filteredList);
  };
  useEffect(() => {
    console.log("rolename", roleName);
  }, [roleName]);

  // const handleSearch = (searchValue) => {
  //   const searchString = searchValue && typeof searchValue === 'string' ? searchValue : '';
  //   setFilterText(searchString);
  //   console.log("searchValue", searchString);
  //   const filteredList = ListData.filter((row) => {
  //     const id = String(row?.partner_id).toLowerCase();
  //     const search = searchString.toLowerCase();
  //     return id.includes(search);
  //   });
  //   setFilteredData(filteredList);
  // };

  const handleRowClick = (row) => {
    console.log("rowsadra", row);
    setRowData(row);
    setItemId(row?.id);
    dispatch(tryfetchOEMDetailByID(row?.id));
  };

  const resetItemId = () => {
    setItemId("");
  };

  const handleAdd = () => {
    setType("add");
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const gridStyle = useMemo(() => ({ height: "60vh", width: "100%" }), []);
  useEffect(() => {
    dispatch(tryfetchSalesPartnersList());
  }, []);

  useEffect(() => {
    setFilteredData(ListData);
  }, [ListData]);

  async function handleblock(e) {
    console.log("e saled id", e);
    var token = localStorage.getItem("token");
    try {
      var bodyFormData = new FormData();
      bodyFormData.append("token", token);
      bodyFormData.append("sales_id", e.sales_id);
      bodyFormData.append("status", e.is_active === 1 ? 0 : 1);
      let resp = await apiPost(BLOCK_SALES_PARTNER, bodyFormData);
      console.log("sales block", resp);
      if (resp?.response?.status === 200) {
        toast.success(resp?.response?.data?.message);
        setItemId("");
        dispatch(tryfetchSalesPartnersList());
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.message;
      } else {
        console.log("unexpected error: ", error);
      }
    }
  }

  const handleEdit = (row) => {
    // console.log("rowdata", row);
    setType("edit");
    setModalOpen(true);
    dispatch(tryfetchSalesPartnerDetailByID(row?.id));
  };
  return (
    <div className="d-flex" style={{ overflow: "auto" }}>
      <ToastContainer />
      <Page $view={itemId}>
        <PageContent $singleView={itemId}>
          <ListHead>
            <HeaderComponentSP
              onFilter={handleSearch}
              onClear={handleClear}
              handleAdd={handleAdd}
              itemId={itemId}
              filterText={filterText}
            />
          </ListHead>
          <>
            <div className="list-table">
              <MDBTable>
                <MDBTableHead color="#4D5FFF" align="left">
                  <tr>
                    {!itemId ? (
                      <>
                        {columnDefs.map((colDef, index) => (
                          <th
                            style={customHeaderStyle}
                            key={index}
                            scope="col"
                            className="header-text"
                          >
                            {colDef.headerName}
                          </th>
                        ))}
                        <th style={customHeaderStyle}>Action</th>
                      </>
                    ) : (
                      columnDefs1.map((colDef, index) => (
                        <th
                          style={customHeaderStyleId}
                          key={index}
                          scope="col"
                          className={itemId ? "px-5" : ""}
                        >
                          {colDef.headerName}
                        </th>
                      ))
                    )}
                  </tr>
                </MDBTableHead>

                <MDBTableBody align="left">
                  {currentItems?.map((rowData) => (
                    <tr key={rowData.customerId}>
                      {!itemId ? (
                        <>
                          {columnDefs?.map((colDef, index) => (
                            <td
                              key={index}
                              onClick={() => handleRowClick(rowData)}
                              style={customCellStyle}
                            >
                              {rowData[colDef.field]}
                            </td>
                          ))}
                          <td className="">
                            <Actions>
                              {roleName !== "Admin" ? (
                                <ActionIcons
                                  onClick={() => handleEdit(rowData)}
                                >
                                  <PiPencilSimple size={24} color="838383" />
                                </ActionIcons>
                              ) : (
                                ""
                              )}
                              <ActionIcons onClick={() => handleblock(rowData)}>
                                {rowData.is_active === 0 ? (
                                  <BsToggleOff size={20} title="Inactive" />
                                ) : (
                                  <BsToggleOn
                                    size={20}
                                    color="green"
                                    title="Active"
                                  />
                                )}
                              </ActionIcons>
                            </Actions>
                          </td>
                        </>
                      ) : (
                        columnDefs1?.map((colDef, index) => (
                          <td
                            key={index}
                            onClick={() => handleRowClick(rowData)}
                            style={customCellStyle}
                            className={itemId ? "px-5" : ""}
                          >
                            {rowData[colDef.field]}
                          </td>
                        ))
                      )}
                    </tr>
                  ))}
                </MDBTableBody>
              </MDBTable>
            </div>

            {/* <MDBPagination className="mt-3" end>
              <MDBPaginationItem disabled={currentPage === 1}>
                <MDBPaginationLink
                  className="page-link"
                  aria-label="Previous"
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <span aria-hidden="true">&laquo;</span>
                </MDBPaginationLink>
              </MDBPaginationItem>
              {Array.from({ length: totalPages }, (_, i) => (
                <MDBPaginationItem key={i} active={i + 1 === currentPage}>
                  <MDBPaginationLink
                    className="page-link"
                    onClick={() => handlePageChange(i + 1)}
                  >
                    {i + 1}
                  </MDBPaginationLink>
                </MDBPaginationItem>
              ))}
              <MDBPaginationItem disabled={currentPage === totalPages}>
                <MDBPaginationLink
                  className="page-link"
                  aria-label="Next"
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <span aria-hidden="true">&raquo;</span>
                </MDBPaginationLink>
              </MDBPaginationItem>
            </MDBPagination> */}
            <MDBPagination className="px-5 gap-4 justify-content-end">
              <MDBPaginationItem disabled={currentPage === 1}>
                <MDBPaginationLink
                  style={{ border: "none", background: "none" }}
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <MdKeyboardArrowLeft />
                </MDBPaginationLink>
              </MDBPaginationItem>
              {getPageNumbers().map((page) => (
                <MDBPaginationItem key={page} active={page === currentPage}>
                  <MDBPaginationLink
                    className="page-link"
                    onClick={() => handlePageChange(page)}
                    style={{
                      backgroundColor: page === currentPage ? "black" : "white",
                      color: page === currentPage ? "white" : "black",
                      border: "1px solid #aeaeae",
                    }}
                  >
                    {page}
                  </MDBPaginationLink>
                </MDBPaginationItem>
              ))}
              <MDBPaginationItem disabled={currentPage === totalPages}>
                <MDBPaginationLink
                  onClick={() => handlePageChange(currentPage + 1)}
                  style={{ border: "none", background: "none" }}
                >
                  <MdKeyboardArrowRight />
                </MDBPaginationLink>
              </MDBPaginationItem>
            </MDBPagination>
          </>
        </PageContent>
      </Page>
      {itemId ? (
        <PageSingle $singleView={itemId}>
          <PageContent>
            <SingleBatchView
              close={resetItemId}
              itemId={itemId}
              handleblock={handleblock}
              data={rowData}
            />
          </PageContent>
        </PageSingle>
      ) : (
        ""
      )}
      {/* <AddSalesPartnerModal /> */}
      <AddSalesPartnerModal
        itemId={itemId}
        type={type}
        open={isModalOpen}
        onClose={handleCloseModal}
      />
    </div>
  );
};

const ListHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border-bottom: 0.5px solid #bfbfbf;
  // padding: 0 11px 20px 15px;
  .header-text {
    color: "#4D5FFF"; // Default text color
    font-size: 25px; // Default font size
  }
`;
const customHeaderStyle = {
  // background: "#4D5FFF",
  color: "#4D5FFF",
  fontSize: "13px",
  fontWeight: "500",
  padding: "1.3rem 0.5rem",
};

const customCellStyle = {
  // border: "1px solid #CCCCCC",
  padding: "1.3rem 0.5rem",
};
// const Page = styled.div`
//   // width: 100%;
//   width: ${(props) => (props.$view ? "22em" : "100%")};
//   margin-left: 10px;
//   margin-top: 10px;
//   border-radius: 22px;
//   overflow-y: auto;
//   background-color: ${(props) => (props.$view ? "#f3f3f3" : "#fff")};
//   flex-shrink: 0;
//   transition: all 0.3s ease-out;
//   padding: 1em;
//   @media (max-width: 1200px) {
//     display: ${(props) => (props.$view ? "none" : "flex")};
//   }
//   // display: flex;
// `;
// const PageSingle = styled.div`
//   width: ${(props) => (!props.$singleView ? "" : "100%")};
//   margin-left: 10px;
//   margin-top: 10px;
//   border-radius: 22px;
//   overflow-y: auto;
//   background-color: #fff;
//   transition: all 0.3s ease-out;
//   padding: 1em;
//   display: ${(props) => (!props.$singleView ? "flex" : "")};
// `;
// const PageContent = styled.div`
//   // padding: calc(60px + 24px) 0 60px 0;
//   // width: ${(props) => (props.$singleView ? "300px" : "100%")};
//   width: 100%;
//   overflow: auto;
//   transition: all 0.3s ease-out;
// `;
export const Actions = styled.div`
  display: flex;
  gap: 10px;
`;
export const ActionIcons = styled.div`
  // display: flex;
  padding-right: 0.8rem;
  cursor: pointer;
`;
export default OEMList;
