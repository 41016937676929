import "./App.css";
import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Routes } from "react-router-dom";
import PublicRoute from "./Components/Layout/PublicRoute";
import Login from "./Pages/Login/Login";
import ForgotPassword from "./Pages/Login/ForgotPassword";
import PrivateRoute from "./Components/Layout/PrivateRoute";
import Dashboard from "./Pages/Dashboard/Dashboard";
import AddProduct from "./Pages/Products/AddProduct";
import ProductView from "./Pages/Products/ProductListView";
import WarrantyList from "./Pages/Warranty/WarrantyList";
import BatchList from "./Pages/Batch/BatchList";
import AddBatch from "./Pages/Batch/AddBatch";
import RecoverAccount from "./Pages/Login/RecoverAccount";
import WarrantyClaimList from "./Pages/Warranty/WarrantyClaimList";
import SalesPartnersList from "./Pages/Vendors/SalesPartnersList";
import ServiceCenterList from "./Pages/Vendors/ServiceCenterList";
import SupplierList from "./Pages/Vendors/SupplierList";
import Subscription from "./Pages/Subscription/Subscription";
import OEMList from "./Pages/OEM/OEMListing";
import AddOEM from "./Pages/OEM/AddOEM";
import SubscriptionList from "./Pages/Subscription/SubscriptionListing";
import OEMSupplierList from "./Pages/Supplier/SupplierListing";
import SettingsList from "./Pages/Settings/SettingsList";
import PackageList from "./Pages/Subscription/PackageList";
// import EditProfile from "./Components/Header/Profile/EditProfile";

function App() {
  // const [count, setCount] = useState(0);

  return (
    <>
      <Routes>
        <Route exact element={<PublicRoute />}>
          <Route path="/Login" element={<Login />} />
          <Route path="/Forgot-Password" element={<ForgotPassword />} />
          <Route path="/Account-Recovery" element={<RecoverAccount />} />
        </Route>
        <Route exact element={<PrivateRoute />}>
          <Route path="/" element={<Dashboard />} />
          {/* <Route path="/Product-List" element={<ProductList />} /> */}
          {/* <Route path="/Profile-Edit" element={<EditProfile />} /> */}
          {/*  Products  */}
          <Route path="/Product-List" element={<ProductView />} />
          <Route path="/Product/:type" element={<AddProduct />} />
          <Route path="/Product/:type/:id" element={<AddProduct />} />
          {/* warranty */}
          <Route path="/Warranty-List" element={<WarrantyList />} />
          <Route path="/Warranty-Claim-List" element={<WarrantyClaimList />} />
          {/* Batch */}
          <Route path="/Batch-List" element={<BatchList />} />
          <Route path="/Batch/:type" element={<AddBatch />} />
          <Route path="/Batch/:type/:id" element={<AddBatch />} />
          {/* Vendors */}
          <Route path="/Service-Center-List" element={<ServiceCenterList />} />
          <Route path="/Sales-Partners-List" element={<SalesPartnersList />} />
          <Route path="/Supplier-List" element={<SupplierList />} />
          {/* subsriptions */}
          <Route path="/Subscription" element={<Subscription />} />
          <Route
            path="/Subscription/:type/:mth/:nop"
            element={<Subscription />}
          />
          <Route path="/Subscription-List" element={<SubscriptionList />} />
          <Route path="/Subscription-Package-List" element={<PackageList />} />
          {/* OEM*/}
          <Route path="/OEM-List" element={<OEMList />} />
          <Route path="/OEM/:type" element={<AddOEM />} />
          <Route path="/OEM/:type/:id" element={<AddOEM />} />
          {/* SUPPLIER */}
          <Route path="/OEM-Supplier-List" element={<OEMSupplierList />} />
          {/* settings */}
          <Route path="/Settings-List" element={<SettingsList />} />
          <Route path="/Settings-List/:type" element={<SettingsList />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
