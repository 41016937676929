import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import styled from "styled-components";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from "@mui/material";

import { Col, Row } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { apiPost } from "../../config/apiConfig";
import { ADD_WARRANTY, UPDATE_WARRANTY } from "../../config/BaseUrl";
import {
  SaveButton,
  SubmitButton,
} from "../../Components/StyledComponents/Buttons";
import {
  clearWarrantysDetailsByID,
  tryfetchWarrantysDetailByID,
  tryfetchWarrantysList,
} from "../../Slices/WarrantySlice";
import { useDispatch, useSelector } from "react-redux";
import {
  InputStyle,
  selectStyle,
} from "../../Components/StyledComponents/Common";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const AddWarrantyModal = ({ open, type, itemId, onClose }) => {
  const WRData = useSelector((state) => state?.warranty?.warrantyDetailByID);

  const dispatch = useDispatch();
  const [IsSaving, setIsSaving] = useState(false);
  const [values, setValues] = useState({
    warranty_package_name: "",
    warranty_period: "",
    warranty_period_type: "",
    coverage_details: "",
    features: "",
    start_date: new Date(),
    end_date: "",
  });

  const [error, setError] = useState({
    warranty_package_name: false,
  });
  const [checkedValues, setCheckedValues] = useState({
    extendedWarranty: false,
    workstation: false,
    onSiteSupport: false,
    required: false,
    disabled: false,
  });
  const handleChange = (event) => {
    const value = event.target.value;

    setValues((prevValues) => ({
      ...prevValues,
      [event.target.name]: value,
    }));
    if (event.target.name === "warranty_package_name") {
      if (event.target.value.length > 20) {
        setError({ ...error, warranty_package_name: true });
      } else {
        setError({ ...error, warranty_package_name: false });
      }
    }
  };

  const handleCheckboxChange = (name) => (event) => {
    setCheckedValues({
      ...checkedValues,
      [name]: event.target.checked,
    });
  };

  const handleAdd = async (e) => {
    console.log("add");
    const checkedCheckboxes = Object.keys(checkedValues).filter(
      (key) => checkedValues[key]
    );
    setIsSaving(true);
    try {
      var token = localStorage.getItem("token");
      var bodyFormData = new FormData();

      bodyFormData.append("token", token);
      bodyFormData.append(
        "warranty_package_name",
        values.warranty_package_name
      );
      bodyFormData.append("warranty_period", values.warranty_period);
      bodyFormData.append("warranty_period_type", values.warranty_period_type);
      bodyFormData.append("coverage_details", values.coverage_details);
      bodyFormData.append("features", checkedCheckboxes);
      console.log(" formdata is ", bodyFormData);
      let resp = await apiPost(ADD_WARRANTY, bodyFormData);
      if (
        resp.response.status === 200
        // resp.response.data.message === "User Added Successfully"
      ) {
        console.log(" file values is asd", resp);
        toast.success(resp.response.data.message);
        setTimeout(() => {
          //   navigate("/");
          setIsSaving(false);
        }, 2000);
        dispatch(tryfetchWarrantysList());
        setValues({
          warranty_package_name: "",
          warranty_period: "",
          warranty_period_type: "",
          coverage_details: "",
          features: "",
          start_date: "",
          end_date: "",
        });
        setCheckedValues({
          extendedWarranty: false,
          workstation: false,
          onSiteSupport: false,
          required: false,
          disabled: false,
        });
      } else {
        toast.error(resp.response.data.message);
      }
    } catch (error) {
      setIsSaving(false);
      toast.error(error.message);
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
    onClose();
  };
  const handleUpdate = async (e) => {
    console.log("updated");
    const checkedCheckboxes = Object.keys(checkedValues).filter(
      (key) => checkedValues[key]
    );
    setIsSaving(true);
    try {
      var token = localStorage.getItem("token");
      var bodyFormData = new FormData();

      bodyFormData.append("id", itemId);
      bodyFormData.append("token", token);
      bodyFormData.append(
        "warranty_package_name",
        values.warranty_package_name
      );
      bodyFormData.append("warranty_period", values.warranty_period);
      bodyFormData.append("warranty_period_type", values.warranty_period_type);
      bodyFormData.append("coverage_details", values.coverage_details);
      bodyFormData.append("features", checkedCheckboxes);
      console.log(" formdata is ", bodyFormData);
      let resp = await apiPost(UPDATE_WARRANTY, bodyFormData);
      if (
        resp.response.status === 200
        // resp.response.data.message === "User Added Successfully"
      ) {
        console.log(" file values is asd", resp);
        toast.success(resp.response.data.message);
        setTimeout(() => {
          //   navigate("/");
          setIsSaving(false);
        }, 2000);
        dispatch(tryfetchWarrantysList());
      } else {
        toast.error(resp.response.data.message);
      }
    } catch (error) {
      setIsSaving(false);
      toast.error(error.message);
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
    onClose();
  };
  //   const style = {
  //     position: "absolute",
  //     top: "150px",
  //     right: "40px",
  //     bgcolor: "background.paper",
  //     borderRadius: "22px",
  //     width: 500,
  //     height: calculateSize("100%" - "150px"),
  //     boxShadow: 24,
  //     padding: "3em 2em",
  //     // border: "2px solid #000",
  //     // pt: 2,
  //     // px: 4,
  //     // pb: 3,
  //   };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (type === "edit") {
      handleUpdate();
    } else {
      handleAdd();
    }
  };
  useEffect(() => {
    console.log("type", type);
    if (type === "edit") {
      setValues(WRData);

      // Parse the features string into an array
      const featuresArray = WRData?.features?.split(",");

      // Create an object with checkboxes set based on the features
      const featuresObject = {};
      featuresArray?.forEach((feature) => {
        featuresObject[feature] = true;
      });

      setCheckedValues(featuresObject);
    } else {
      setValues({
        warranty_package_name: "",
        warranty_period: "",
        warranty_period_type: "",
        coverage_details: "",
        features: "",
        start_date: "",
        end_date: "",
      });
      setCheckedValues({
        extendedWarranty: false,
        workstation: false,
        onSiteSupport: false,
        required: false,
        disabled: false,
      });
    }
  }, [type, WRData]);
  useEffect(() => {
    return () => {
      dispatch(clearWarrantysDetailsByID());
      setValues({
        warranty_package_name: "",
        warranty_period: "",
        warranty_period_type: "",
        coverage_details: "",
        features: "",
        start_date: new Date(),
        end_date: "",
      });
    };
  }, []);

  useEffect(() => {
    if (type === "add") {
      setValues({
        warranty_package_name: "",
        warranty_period: "",
        warranty_period_type: "",
        coverage_details: "",
        features: "",
        start_date: new Date(),
        end_date: "",
      });
      setCheckedValues({
        extendedWarranty: false,
        workstation: false,
        onSiteSupport: false,
        required: false,
        disabled: false,
      });
    }
  }, [type]);
  return (
    <>
      <ToastContainer />
      {/* <Button onClick={onClose}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <ModalContainer
        // sx={{ ...style }}
        >
          <Heading id="parent-modal-title">
            {type === "add"
              ? "Add New Warranty Package"
              : "Edit Warranty Package"}
          </Heading>
          {/* <p id="parent-modal-description">
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </p> */}
          <form action="" onSubmit={handleSubmit}>
            <div style={{ msOverflowY: "scroll" }}>
              <Row>
                <Col md={12} className=" px-5 pt-5">
                  <Row>
                    <Col md={12}>
                      <TextField
                        label="Warranty Package Name"
                        variant="outlined"
                        fullWidth
                        required
                        name="warranty_package_name"
                        value={values.warranty_package_name}
                        onChange={handleChange}
                        sx={InputStyle}
                        error={error.warranty_package_name}
                        helperText={
                          error.warranty_package_name
                            ? "Maximum Length exceeded!"
                            : ""
                        }
                      />
                    </Col>
                  </Row>
                  <Row className="py-5">
                    <Col md={5}>
                      <TextField
                        label="Warranty Period"
                        variant="outlined"
                        type="number"
                        required
                        sx={InputStyle}
                        fullWidth
                        name="warranty_period"
                        value={values.warranty_period}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col md={7}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Period Type *
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={values.warranty_period_type}
                          label=" Product Type"
                          name="warranty_period_type"
                          IconComponent={(props) => (
                            <ExpandMoreIcon size={25} {...props} />
                          )}
                          sx={selectStyle}
                          required
                          onChange={handleChange}
                        >
                          <MenuItem value="day">Day</MenuItem>
                          <MenuItem value="month">Month</MenuItem>
                          <MenuItem value="year">Year</MenuItem>
                        </Select>
                      </FormControl>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <TextField
                        label="Coverage Details"
                        variant="outlined"
                        fullWidth
                        multiline
                        required
                        sx={InputStyle}
                        minRows="2"
                        name="coverage_details"
                        value={values.coverage_details}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="d-flex justify-content-center py-5">
                    <h2 className="py-2" style={{ color: "#515151" }}>
                      Features (Optional)
                    </h2>
                    <Outer className="col-md-12">
                      <div
                        className="row "
                        style={{ whiteSpace: "nowrap", color: "#AAAAAA" }}
                      >
                        <Col md={6}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkboxs
                                  checked={checkedValues.extendedWarranty}
                                  onChange={handleCheckboxChange(
                                    "extendedWarranty"
                                  )}
                                />
                              }
                              label={
                                <span style={{ fontSize: "15px" }}>
                                  Extended warranty
                                </span>
                              }
                            />
                            <FormControlLabel
                              control={
                                <Checkboxs
                                  checked={checkedValues.workstation}
                                  onChange={handleCheckboxChange("workstation")}
                                />
                              }
                              label={
                                <span style={{ fontSize: "15px" }}>
                                  Workstation
                                </span>
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkboxs
                                  checked={checkedValues.disabled}
                                  onChange={handleCheckboxChange("disabled")}
                                />
                              }
                              label={
                                <span style={{ fontSize: "15px" }}>
                                  Disabled
                                </span>
                              }
                            />
                            <FormControlLabel
                              control={
                                <Checkboxs
                                  checked={checkedValues.onSiteSupport}
                                  onChange={handleCheckboxChange(
                                    "onSiteSupport"
                                  )}
                                />
                              }
                              label={
                                <span style={{ fontSize: "15px" }}>
                                  On-site support
                                </span>
                              }
                            />
                            {/* <FormControlLabel
                            control={
                              <Checkboxs
                                checked={checkedValues.required}
                                onChange={handleCheckboxChange("required")}
                              />
                            }
                            label="Required"
                          />
                          <FormControlLabel
                            control={
                              <Checkboxs
                                checked={checkedValues.disabled}
                                onChange={handleCheckboxChange("disabled")}
                              />
                            }
                            label="Disabled"
                          /> */}
                          </FormGroup>
                        </Col>
                      </div>
                    </Outer>
                  </Row>
                  {/* <Row className="">
                  <h2 className="py-2" style={{ color: "#515151" }}>
                    Offers (Optional)
                  </h2>

                  <Col md={6}>
                    <TextField
                      label="Start Date"
                      variant="outlined"
                      type="date"
                      sx={InputStyle}
                      fullWidth
                      name="start_date"
                      value={values.start_date || " "}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col md={6}>
                    <TextField
                      label="End Date"
                      type="date"
                      variant="outlined"
                      sx={InputStyle}
                      fullWidth
                      name="end_date"
                      value={values.end_date || " "}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row className="py-5">
                  <Col md={12}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Region
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.product_type}
                        label="Region"
                        style={selectStyle}
                        name="Region"
                        onChange={handleChange}
                      >
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                      </Select>
                    </FormControl>
                  </Col>
                </Row> */}
                </Col>
              </Row>
              <Row>
                <div className="col-md-12 buttons d-flex justify-content-between px-5">
                  <SaveButton
                    type="button"
                    onClick={onClose}
                    className=" save-button"
                  >
                    Cancel
                  </SaveButton>
                  <SubmitButton
                    type="submit"
                    disabled={IsSaving}
                    className="submit-button"
                  >
                    Save
                  </SubmitButton>
                </div>
              </Row>
            </div>
          </form>
        </ModalContainer>
      </Modal>
    </>
  );
};
const ModalContainer = styled(Box)`
  position: absolute;
  top: 150px;
  right: 40px;
  background-color: #fff;
  border-radius: 22px;
  width: 500px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 3em 2em;
  overflow-y: auto; /* Enable vertical scrolling */
  max-height: 80vh; /* Set a maximum height for the modal */
`;

const Outer = styled.div`
  border: 1px solid #c4c4c4;
  width: 96%;
  justify-self: center;
  border-radius: 4px;
  //   margin: 28px 0 0 0;
`;
const Checkboxs = styled(Checkbox)`
  display: flex;
  padding: 2em;
  white-space: nowrap;
  color: #c4c4c4;
  font-size: 14px;
`;
const Heading = styled.div`
  font-weight: 600;
  font-size: 21px;
  margin-left: 2.1rem;
`;

export default AddWarrantyModal;
