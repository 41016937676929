import React, { useRef, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import styled from "styled-components";

const PrivateRoute = () => {
  const navigate = useNavigate();
  const logoutTimerRef = useRef(null);

  const isAuthenticate = localStorage.getItem("token");
  const isCheckboxChecked = localStorage.getItem("checkbox") === "true";

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("expires_at");
    localStorage.removeItem("checkbox");
    navigate("/Login");
  };

  useEffect(() => {
    if (!isAuthenticate || isAuthenticate === "invalidToken") {
      handleLogout();
      return;
    }

    if (!isCheckboxChecked) {
      const handleUserActivity = () => {
        resetLogoutTimer();
      };

      const resetLogoutTimer = () => {
        clearTimeout(logoutTimerRef.current);
        logoutTimerRef.current = setTimeout(handleLogout, 1800000); // 30 mins session timeout
      };

      resetLogoutTimer();

      window.addEventListener("mousemove", handleUserActivity);
      window.addEventListener("click", handleUserActivity);

      return () => {
        clearTimeout(logoutTimerRef.current);
        window.removeEventListener("mousemove", handleUserActivity);
        window.removeEventListener("click", handleUserActivity);
      };
    }
  }, [navigate, isAuthenticate, isCheckboxChecked]);

  return (
    <MainContainer>
      <SidebarContainer>
        <Sidebar />
      </SidebarContainer>
      <ContentContainer>
        <Header />
        <Outlet />
      </ContentContainer>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  display: flex;
  height: 100vh;
  overflow: hidden;
  padding-left: 260px;
  padding-bottom: 5px;
  padding-right: 5px;
`;

const SidebarContainer = styled.div`
  background-color: #ffffff; /* Sidebar background color */
  box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 22px;
`;

const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export default PrivateRoute;
