import React, { useState, useEffect, useRef } from "react";
import { SEARCH } from "../../config/BaseUrl";
import { apiPost } from "../../config/apiConfig";
import axios from "axios";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { FiSearch } from "react-icons/fi";

const HeaderSearch = () => {
  const [search, setSearch] = useState("");
  const [dropdownItems, setDropdownItems] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const roleName = localStorage.getItem("roleName");
  const handleSearch = async (e) => {
    setDropdownItems([]);
    setSearch(e.target.value);
    if (e.target.value.length >= 3) {
      await searchItems(e.target.value);
    }
    setIsDropdownOpen(true);
    if (e.target.value == "") {
      setIsDropdownOpen(false);
    }
  };

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  async function searchItems(query) {
    var token = localStorage.getItem("token");
    try {
      var bodyFormData = new FormData();
      bodyFormData.append("token", token);
      bodyFormData.append("name", query);
      let resp = await apiPost(SEARCH, bodyFormData);
      // console.log("Warranty", resp);
      if (resp.response.status === 200) {
        setDropdownItems(resp?.response?.data.data);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Axios error: ", error.message);
      } else {
        console.error("Unexpected error: ", error);
      }
    }
  }
  const handleReroute = (e) => {
    setIsDropdownOpen(false);
    // console.log("seatch type", e);
    setSearch(e.name);
    if (e.type == "product") {
      navigate("/Product-List");
    } else if (e.type == "batch") {
      navigate("/Batch-List");
    } else if (e.type == "warranty") {
      navigate("/Warranty-List");
    } else if (e.type == "sales_partner") {
      navigate("/Sales-Partners-List");
    } else if (e.type == "supplier") {
      navigate("/Supplier-List");
    } else if (e.type == "oem") {
      navigate("/OEM-List");
    } else {
      navigate("/");
    }
  };

  return (
    <div>
      <SearchInputDiv ref={dropdownRef}>
        <input
          type="text"
          aria-label="Search"
          className="search-input"
          onChange={(e) => handleSearch(e)}
          value={search}
        />
        <SearchIcon>
          {search ? (
            ""
          ) : (
            <>
              <FiSearch color="#1E1E1E" size={18} />
              Search {roleName !== "admin" ? `for Products,Batch and more` : ""}
              ...
            </>
          )}
          {/* <div >X</div> */}
        </SearchIcon>
        {isDropdownOpen ? (
          dropdownItems.length > 0 ? (
            <SearchUl className="dropdown-list">
              {dropdownItems.map((item, index) => (
                <SearchLi key={index} onClick={() => handleReroute(item)}>
                  {item.name}
                </SearchLi>
              ))}
            </SearchUl>
          ) : (
            search.length > 2 && (
              <SearchUl className="dropdown-list">
                <SearchLi>No results found</SearchLi>
              </SearchUl>
            )
          )
        ) : (
          ""
        )}
      </SearchInputDiv>
    </div>
  );
};

const SearchIcon = styled.div`
  display: flex;
  position: absolute;
  left: 15px;
  top: 50%;
  font-family: "Public Sans", sans-serif;
  transform: translateY(-50%);
  cursor: pointer;
  align-items: center;
  gap: 1rem;
`;

const SearchUl = styled.div`
  position: fixed;
  z-index: 99999;
  top: 50px;
  width: 22em;
  background: #f4f4f4;
  border-radius: 0 0 10px 10px;
`;

const SearchInputDiv = styled.label`
  display: flex;
  margin-left: 4rem;
  align-items: center;
  width: 22em;
  position: relative;
  background: #efefef;
  border-radius: 11px;
  input {
    height: 38px;
    width: 100%;
    border-radius: 6px;
    border: 1px solid #f3f6f9;
    background: #f4f4f4;
    padding: 0 0.75em;
    &:focus {
      outline: none;
    }
  }
`;

const SearchLi = styled.div`
  padding: 0.6rem 1rem;
  list-style: none;
  &:hover {
    background: #fafafa;
  }
`;

export default HeaderSearch;
