import { createSlice } from "@reduxjs/toolkit";

const InitState = {
  isLoading: false,
  settingsTableData: [],
  settingsDetailByID: [],
};

const SettingsSlice = createSlice({
  name: "settings",
  initialState: InitState,
  reducers: {
    tryfetchSettingsByID: (state) => {
      state.isLoading = true;
    },
    setSettingsByID: (state, action) => {
      state.isLoading = false;
      state.settingsTableData = action.payload;
    },
    clearBatchDetailsByID: (state) => {
      state.isLoading = false;
      state.batchDetailByID = [];
    },
  },
});

export const { tryfetchSettingsByID, setSettingsByID, clearBatchDetailsByID } =
  SettingsSlice.actions;

export default SettingsSlice.reducer;
