import React, { useEffect } from "react";
import "./login.css";
// import NehruLogo from "../../Assets/NehruLogo.png";
import Group1 from "../../assets/Images/Frame.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdGppGood } from "react-icons/md";
import { VscError } from "react-icons/vsc";
import { FaArrowLeftLong } from "react-icons/fa6";
import WIcon from "../../assets/Images/W.png";

import { Icon } from "@iconify/react";
import {
  ClearPasswordEmailSend,
  clearPasswordChange,
  clearUserOtpVerify,
  tryPasswordChange,
  tryUserResetPasswordEmailSend,
  tyrFetchUserOtpVerify,
} from "../../Slices/AuthenticationSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ForgotPassword = () => {
  const authData = useSelector((state) => state.authentication);

  const [page, setPage] = useState("sentMail");
  const params = useParams();
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [userId, setuserId] = useState("");
  const [invalidMailId, setInvalidMailId] = useState(false);
  const [loading, setloading] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordReEnter, setPasswordReEnter] = useState("");
  const [otp, setOtp] = useState("");
  const [otpInvalid, setOtpInvalid] = useState(false);
  //mail senting form

  const handleMailSent = (e) => {
    e.preventDefault();
    setloading(true);
    dispatch(tryUserResetPasswordEmailSend(userId));
    setloading(false);
    setPage("otp");
  };

  useEffect(() => {
    if (authData?.isEmailSendSuccess.status === true) {
      console.log("OTP", authData?.isEmailSendSuccess.otp);
      toast.success(authData?.isEmailSendSuccess.message);
      setPage("otp");
      setloading(false);
      // alert(`OTP : ${authData?.isEmailSendSuccess.otp}`);
      setuserId(authData?.isEmailSendSuccess.email);
    } else if (authData?.isEmailSendSuccess.status === false) {
      setloading(false);
      setInvalidMailId(true);
      toast.error(authData?.isEmailSendSuccess.message);
    }
  }, [authData?.isEmailSendSuccess.message]);

  // otp form

  const inputRefs = [useRef(1), useRef(2), useRef(3), useRef(4)];

  const handleChange = (e, index) => {
    setOtpInvalid(false);
    const value = e.target.value[0];
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp.join("")); // Join the digits into a single string

    if (index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    } else if (value === "") {
      // If the backspace key was pressed and the input is empty, go back to the previous input
      if (index > 0) {
        inputRefs[index - 1].current.focus();
      }
    }
  };
  const handleBackspace = (e, index) => {
    if (e.key === "Backspace" && index > 0 && otp[index] === "") {
      e.preventDefault();
      inputRefs[index + 1].current.focus();
      const newOtp = [...otp];
      newOtp[index + 1] = "";
      setOtp(newOtp.join(""));
    }
  };
  const handleOtpVerify = (e) => {
    setloading(true);
    e.preventDefault();
    var oneTimePassword = authData?.isEmailSendSuccess.otp.toString();
    var data = {
      email: userId,
      otp: otp,
    };
    if (oneTimePassword === otp) {
      dispatch(tyrFetchUserOtpVerify(data));
    } else if (oneTimePassword !== otp) {
      setOtpInvalid(true);
      toast.error("Otp is incorrect");
      setloading(false);
    }
  };

  useEffect(() => {
    if (authData?.otpVerification.status === true) {
      toast.success("OTP verified successfully");
      setPage("newPassword");
      setloading(false);
    } else if (authData?.otpVerification.status === false) {
      toast.error("OTP verification failed");
      setloading(false);
    }
  }, [authData?.otpVerification.message]);

  useEffect(() => {
    if (page === "sentMail") {
      dispatch(ClearPasswordEmailSend());
    }
  }, []);

  const handlePasswordChange = (e) => {
    setloading(true);
    e.preventDefault();
    var data = {
      email: userId,
      token: otp,
      password: password,
      password_confirmation: passwordReEnter,
    };
    dispatch(tryPasswordChange(data));
    setPage("done");
  };

  const handleReenterpassword = (e) => {
    setPasswordReEnter(e.target.value);
    if (password !== e.target.value) {
      setInvalidPassword(true);
    } else if (password === e.target.value) {
      setInvalidPassword(false);
    }
  };

  useEffect(() => {
    if (authData?.otpVerification.status === true) {
      setloading(false);
      toast.success(authData?.otpVerification.message);
      // navigate("/Login");
    } else if (authData?.otpVerification.status === false) {
      toast.error(authData?.otpVerification.message);
      setloading(false);
    }
  }, [authData?.otpVerification.status]);

  useEffect(() => {
    setuserId(params.id);
    setPage("sentMail");
    return () => {
      dispatch(ClearPasswordEmailSend());
      dispatch(clearPasswordChange());
      dispatch(clearUserOtpVerify());
    };
  }, [params]);

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <div>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* <ToastContainer /> */}
        <div className="login-container">
          <div className="img-icon">
            <img src={WIcon} alt="" srcset="" />
          </div>
          <main className="signup-container">
            {page === "sentMail" ? (
              <form action="" className="signup-form" onSubmit={handleMailSent}>
                <h1 className="heading-primary">Forgot password ?</h1>
                <p className="text-mute">
                  No worries we'll send you reset instructions.
                </p>
                {invalidMailId ? (
                  <div className="fp-div" style={{ color: "#ff0808" }}>
                    <span className="span-error">
                      <VscError />
                      <span style={{ marginLeft: "5px" }}>
                        Entered Username is incorrect
                      </span>
                    </span>
                  </div>
                ) : (
                  ""
                )}
                <span className="label-text">
                  Enter email to reset password
                </span>
                <label className="inp reset-email-input">
                  <input
                    type="email"
                    className="input-text"
                    placeholder="&nbsp;"
                    value={userId}
                    onChange={(e) => {
                      setInvalidMailId(false);
                      setuserId(e.target.value);
                    }}
                    // onBlur={() => dispatch(tryUserResetPasswordEmailSend(userId))}
                    name="resetemail"
                    required
                  />

                  <span className="input-icon">
                    <Icon icon="mdi:envelope" />
                  </span>
                </label>
                <button
                  className="btn btn-login"
                  type="submit"
                  // onClick={handleMailSent}
                >
                  {!loading ? "Sent mail to reset password" : "Sending..."}
                </button>
                <Link
                  className="span-text"
                  to="/Login"
                  onClick={() => {
                    setPage("sentMail");
                  }}
                >
                  <div className="bg-div">
                    <FaArrowLeftLong /> Back to Login
                  </div>
                </Link>
                {/* <div className="line-container">
                  <div className="line"></div>
                  <div className="or">or</div>
                  <div className="line"></div>
                </div> */}
                {/* <div className="lg-div">
                  Don't have an account?
                  <Link rel="stylesheet" href="">
                    Sign up
                  </Link>
                </div> */}{" "}
                <div className="page-no-wrapper">
                  <div className="page-nos">1</div>
                  <div className="page-nos-white">2</div>
                  <div className="page-nos-white">3</div>
                  <div className="page-nos-white">4</div>
                  <div className="line-background"></div>
                </div>
              </form>
            ) : page === "otp" ? (
              <form
                action=""
                className="signup-form"
                onSubmit={handleOtpVerify}
              >
                <h1 className="heading-primary">Password Reset</h1>
                <p className="text-mute">
                  {" "}
                  We sent a code to <span>{userId}</span>
                </p>
                {/* <span className="label-text">New password</span> */}
                <label className="inp reset-otp-input">
                  <div className="otp-div">
                    <input
                      type="number"
                      className="otp-inputs"
                      min="0"
                      maxLength={1}
                      max="9"
                      step="any"
                      value={otp[0]}
                      onChange={(e) => handleChange(e, 0)}
                      onKeyDown={(e) => handleBackspace(e, 3)}
                      ref={inputRefs[0]}
                    />
                  </div>
                  <div className="otp-div">
                    <input
                      type="number"
                      className="otp-inputs"
                      min="0"
                      max="9"
                      step="any"
                      maxLength={1}
                      value={otp[1]}
                      onChange={(e) => handleChange(e, 1)}
                      onKeyDown={(e) => handleBackspace(e, 2)}
                      ref={inputRefs[1]}
                    />{" "}
                  </div>
                  <div className="otp-div">
                    <input
                      type="number"
                      className="otp-inputs"
                      min="0"
                      max="9"
                      maxLength={1}
                      step="any"
                      value={otp[2]}
                      onChange={(e) => handleChange(e, 2)}
                      onKeyDown={(e) => handleBackspace(e, 1)}
                      ref={inputRefs[2]}
                    />{" "}
                  </div>
                  <div className="otp-div">
                    <input
                      type="number"
                      className="otp-inputs"
                      min="0"
                      max="9"
                      maxLength={1}
                      step="any"
                      value={otp[3]}
                      onChange={(e) => handleChange(e, 3)}
                      onKeyDown={(e) => handleBackspace(e, 0)}
                      ref={inputRefs[3]}
                    />
                  </div>
                </label>
                {otpInvalid ? (
                  <div className="fp-div" style={{ color: "#ff0808" }}>
                    <span className="span-error">
                      <VscError />
                      <span style={{ marginLeft: "5px" }}>
                        Otp is incorrect
                      </span>
                    </span>
                  </div>
                ) : (
                  ""
                )}
                <button
                  className="btn btn-login"
                  type="submit"
                  // onClick={() => handleOtpVerify()}
                >
                  {!loading ? "Verify" : "Verifying..."}
                </button>
                <div className="lg-div" style={{ fontWeight: "500" }}>
                  Didn't receive the email ?{" "}
                  <Link rel="stylesheet" href="">
                    Click to resend
                  </Link>
                </div>
                <Link
                  className="span-text"
                  onClick={() => setPage("sentMail")}
                  to="/Login"
                >
                  <div className="bg-div">
                    {" "}
                    <FaArrowLeftLong /> Back to Login
                  </div>
                </Link>
                {/* <div className="line-container">
                  <div className="line"></div>
                  <div className="or">or</div>
                  <div className="line"></div>
                </div> */}
                {/* <div className="lg-div">
                  Don't have an account?
                  <Link rel="stylesheet" href="">
                    Sign up
                  </Link>
                </div> */}{" "}
                <div className="page-no-wrapper">
                  <div className="page-nos-white">1</div>
                  <div className="page-nos">2</div>
                  <div className="page-nos-white">3</div>
                  <div className="page-nos-white">4</div>
                  <div className="line-background"></div>
                </div>
              </form>
            ) : page === "newPassword" ? (
              <form
                action=""
                className="signup-form"
                onSubmit={handlePasswordChange}
              >
                <h1 className="heading-primary">Set New Password</h1>
                <p className="text-mute">Must be at least 8 characters</p>
                <span className="label-text">New password</span>
                <label className="inp reset-email-input">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="input-text"
                    name="password"
                    onChange={(e) => setPassword(e.target.value)}
                    minLength={8}
                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
                    title="Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one digit, and one special character."
                    required
                  />
                  <span
                    className="input-icon input-icon-password"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <Icon icon="ic:baseline-remove-red-eye" />
                  </span>
                </label>
                <span className="label-text">Confirm Password</span>
                <label className="inp reset-email-input">
                  <input
                    type={showPassword1 ? "text" : "password"}
                    className="input-text"
                    name="passwordReEnter"
                    minLength={8}
                    required
                    onChange={(e) => {
                      handleReenterpassword(e);
                    }}
                  />
                  <span
                    className="input-icon input-icon-password"
                    onClick={() => setShowPassword1(!showPassword1)}
                  >
                    <Icon icon="ic:baseline-remove-red-eye" />
                  </span>
                </label>
                {invalidPassword ? (
                  <div className="fp-div" style={{ color: "#ff0808" }}>
                    <span className="span-error">
                      <VscError />
                      <span style={{ marginLeft: "5px" }}>
                        Re-entered password is different
                      </span>
                    </span>
                  </div>
                ) : (
                  ""
                )}
                <button
                  className="btn btn-login"
                  type="submit"
                  // onClick={() => handlePasswordChange()}
                >
                  {!loading ? "Reset Password" : "Resetting Password..."}
                </button>
                <Link className="span-text" to="/Login">
                  <div className="bg-div">
                    <FaArrowLeftLong /> Back to Login
                  </div>
                </Link>
                {/* <div className="line-container">
                  <div className="line"></div>
                  <div className="or">or</div>
                  <div className="line"></div>
                </div> */}
                {/* <div className="lg-div">
                  Don't have an account?
                  <Link rel="stylesheet" href="">
                    Sign up
                  </Link>
                </div> */}
                <div className="page-no-wrapper">
                  <div className="page-nos-white">1</div>
                  <div className="page-nos-white">2</div>
                  <div className="page-nos">3</div>
                  <div className="page-nos-white">4</div>
                  <div className="line-background"></div>
                </div>
              </form>
            ) : page === "done" ? (
              <form action="" className="signup-form">
                <div className="done-div">
                  <MdGppGood size={150} />
                  <h1 className="heading-primary">All Done!</h1>
                  <p className="text-mute">Your password has been reset.</p>
                </div>
                <Link className="span-text" to="/Login">
                  <button
                    className="btn btn-login"
                    type="button"
                    // onClick={() => setShowForgotPasswordInput(0)}
                  >
                    Login Now
                  </button>
                </Link>
                <div className="page-no-wrapper">
                  <div className="page-nos-white">1</div>
                  <div className="page-nos-white">2</div>
                  <div className="page-nos-white">3</div>
                  <div className="page-nos">4</div>
                  <div className="line-background"></div>
                </div>
              </form>
            ) : (
              ""
            )}
          </main>
          <div
            className="welcome-container"
            style={{ borderLeft: "0.5px solid #ebebeb" }}
          >
            <div className="image-container">
              {/* <img src={NehruLogo} alt="" /> */}
              {/* <h1 className="heading-secondary">
            <span className="lg">Welcome to Nehru Group of Institutions</span>
          </h1> */}
              <img src={Group1} alt="" className="welcome-image" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
