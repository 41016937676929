import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import HeaderComponent from "./HeaderComponent";
import {
  tryfetchWarrantysDetailByID,
  tryfetchWarrantysList,
} from "../../Slices/WarrantySlice";
import AddWarrantyModal from "./AddWarrantyPackage";
import {
  ActionIcons,
  Actions,
  Page,
  PageContent,
  customCellStyle,
  customHeaderStyle,
} from "../../Components/StyledComponents/Common";
import { PiPencilSimple } from "react-icons/pi";
import { TfiTrash } from "react-icons/tfi";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import {
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
} from "mdb-react-ui-kit";
import DeleteAisle from "./DeleteModal";
import { DELETE_WARRANTY } from "../../config/BaseUrl";
import deleteIcon from "../../assets/helperIcons/delete.svg";
import pencil from "../../assets/helperIcons/pencil.svg";
import { ToastContainer, toast } from "react-toastify";
import { apiPost } from "../../config/apiConfig";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

const WarrantyList = () => {
  const dispatch = useDispatch();
  const ListData = useSelector((state) => state?.warranty?.warrantyTableData);
  const [filterText, setFilterText] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [type, setType] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [itemId, setItemId] = useState("");
  const [deleteModel, setdeleteModel] = useState(false);

  const itemsPerPage = 10; // Set the number of items per page
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // Get the current page data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const getPageNumbers = () => {
    const maxPagesToShow = 3;
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
    let startPage = currentPage - halfMaxPagesToShow;
    let endPage = currentPage + halfMaxPagesToShow;

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(totalPages, maxPagesToShow);
    } else if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, totalPages - maxPagesToShow + 1);
    }

    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  };

  const columnDefs = useMemo(
    () => [
      {
        field: "warranty_package_name",
        headerName: "Warranty package name",
        headerTooltip: "Warranty package name",
      },
      {
        field: "warranty_period",
        headerName: "Warranty Period",
        headerTooltip: "Warranty Period",
      },
      {
        field: "warranty_period_type",
        headerName: "Warranty period type",
        headerTooltip: "Warranty period type",
      },
      {
        field: "features",
        headerName: "Features",
        headerTooltip: " Features",
      },
    ],
    []
  );

  const handleClear = () => {
    if (filterText) {
      setFilterText("");
    }
  };

  const handleSearch = (searchValue) => {
    setFilterText(searchValue);
    const filteredList = ListData.filter((row) => {
      const id = String(row.id)?.toLowerCase(); // Convert to string
      const name = String(row.warranty_package_name)?.toLowerCase(); // Convert to string
      const search = searchValue.toLowerCase();
      return id.includes(search) || name.includes(search);
    });
    setFilteredData(filteredList);
  };

  const handleRowClick = (row) => {
    console.log("rowdata", row);
    setModalOpen(true);
    setType("edit");
    dispatch(tryfetchWarrantysDetailByID(row.id));
    setItemId(row.id);
  };

  const resetItemId = () => {
    setItemId("");
  };

  const handleOpenDelete = (id) => {
    setItemId(id);
    setdeleteModel(true);
  };

  const handleDelete = (id) => {
    let token = localStorage.getItem("token");
    var bodyFormData = new FormData();
    bodyFormData.append("token", token);
    bodyFormData.append("id", itemId);
    let resp = apiPost(DELETE_WARRANTY, bodyFormData);
    resp.then((resp) => {
      console.log("delete resp", resp);
      switch (resp.response.status) {
        case 200:
          dispatch(tryfetchWarrantysList());
          toast.success(resp.response.data.message);
          setTimeout(() => {
            setdeleteModel(false);
          }, 2000);
          break;

        default:
      }
    });
  };

  const handleAdd = () => {
    setType("add");
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    dispatch(tryfetchWarrantysList());
  }, []);

  useEffect(() => {
    setFilteredData(ListData);
  }, [ListData]);

  return (
    <div className="d-flex" style={{ overflow: "auto" }}>
      <ToastContainer />
      <Page style={{ overflow: "auto" }}>
        <PageContent>
          <ListHead>
            <HeaderComponent
              onFilter={handleSearch}
              onClear={handleClear}
              filterText={filterText}
              handleAdd={handleAdd}
            />
          </ListHead>
          <>
            <div className="list-table" style={{ overflow: "auto" }}>
              {currentItems.length === 0 ? (
                <div className="d-flex justify-content-center">
                  No data found
                </div>
              ) : (
                <MDBTable>
                  <MDBTableHead color="#4D5FFF" align="left">
                    <tr>
                      <>
                        {columnDefs.map((colDef) => (
                          <th
                            style={customHeaderStyle}
                            key={colDef.field}
                            scope="col"
                            className="header-text"
                          >
                            {colDef.headerName}
                          </th>
                        ))}
                        <th style={customHeaderStyle}>Actions</th>
                      </>
                    </tr>
                  </MDBTableHead>

                  <MDBTableBody align="left">
                    {currentItems.map((rowData) => (
                      <tr key={rowData.customerId}>
                        <>
                          {columnDefs?.map((colDef) => (
                            <td key={colDef.field} style={customCellStyle}>
                              {rowData[colDef.field]}
                            </td>
                          ))}
                          <td className="">
                            <Actions>
                              <ActionIcons
                                onClick={() => handleRowClick(rowData)}
                              >
                                <img src={pencil} alt="" srcset="" />
                              </ActionIcons>
                              <ActionIcons
                                onClick={() => handleOpenDelete(rowData.id)}
                              >
                                <img src={deleteIcon} alt="" srcset="" />
                              </ActionIcons>
                            </Actions>
                          </td>
                        </>
                      </tr>
                    ))}
                  </MDBTableBody>
                </MDBTable>
              )}
            </div>

            <MDBPagination className="py-3 gap-4 justify-content-end">
              <MDBPaginationItem disabled={currentPage === 1}>
                <MDBPaginationLink
                  style={{ border: "none", background: "none" }}
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <MdKeyboardArrowLeft />
                </MDBPaginationLink>
              </MDBPaginationItem>
              {getPageNumbers().map((page) => (
                <MDBPaginationItem key={page} active={page === currentPage}>
                  <MDBPaginationLink
                    className="page-link"
                    onClick={() => handlePageChange(page)}
                    style={{
                      backgroundColor: page === currentPage ? "black" : "white",
                      color: page === currentPage ? "white" : "black",
                      border: "1px solid #aeaeae",
                    }}
                  >
                    {page}
                  </MDBPaginationLink>
                </MDBPaginationItem>
              ))}
              <MDBPaginationItem disabled={currentPage === totalPages}>
                <MDBPaginationLink
                  onClick={() => handlePageChange(currentPage + 1)}
                  style={{ border: "none", background: "none" }}
                >
                  <MdKeyboardArrowRight />
                </MDBPaginationLink>
              </MDBPaginationItem>
            </MDBPagination>
          </>
        </PageContent>
      </Page>
      <DeleteAisle
        openModal={deleteModel}
        setOpenModel={setdeleteModel}
        handleDeleteUom={handleDelete}
        id={itemId}
      />
      <AddWarrantyModal
        itemId={itemId}
        type={type}
        open={isModalOpen}
        onClose={handleCloseModal}
      />
    </div>
  );
};

const ListHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .header-text {
    color: "#4D5FFF";
    font-size: 25px;
  }
`;

export default WarrantyList;
