import { createSlice } from "@reduxjs/toolkit";

const InitState = {
  isLoading: false,
  warrantyTableData: [],
  warrantyDetailByID: [],
  warrantyClaimListData: [],
};

const WarrantySlice = createSlice({
  name: "Warranty",
  initialState: InitState,
  reducers: {
    tryfetchWarrantysList: (state) => {
      state.isLoading = true;
    },
    setWarrantysListData: (state, action) => {
      state.isLoading = false;
      state.warrantyTableData = action.payload;
    },
    tryfetchFilteredWarrantyList: (state) => {
      state.isLoading = true;
    },
    setFilteredWarrantyListData: (state, action) => {
      state.isLoading = false;
      state.warrantyTableData = action.payload;
    },
    tryfetchWarrantysDetailByID: (state) => {
      state.isLoading = true;
    },
    setWarrantysDetailByID: (state, action) => {
      state.isLoading = false;
      state.warrantyDetailByID = action.payload;
    },
    tryfetchWarrantyClaimsList: (state) => {
      state.isLoading = true;
    },
    setWarrantyClaimsList: (state, action) => {
      state.isLoading = false;
      state.warrantyClaimListData = action.payload;
    },
    clearWarrantysDetailsByID: (state) => {
      state.isLoading = false;
      state.warrantyDetailByID = [];
    },
  },
});

export const {
  tryfetchWarrantysList,
  setWarrantysListData,
  tryfetchWarrantysDetailByID,
  setWarrantysDetailByID,
  clearWarrantysDetailsByID,
  tryfetchWarrantyClaimsList,
  setWarrantyClaimsList,
  tryfetchFilteredWarrantyList,
  setFilteredWarrantyListData,
} = WarrantySlice.actions;

export default WarrantySlice.reducer;
