import React, { useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { sideBar } from "../../Components/AgGrid/AggridFunctions";
import HeaderComponent from "./HeaderComponent";
import {
  tryfetchWarrantysDetailByID,
  tryfetchWarrantysList,
} from "../../Slices/WarrantySlice";
import AddWarrantyModal from "./AddSalesPartnerModal";
import {
  tryfetchSalesPartnersList,
  tryfetchServiceCentersList,
} from "../../Slices/VendorSlice";
const ServiceCenterList = () => {
  const dispatch = useDispatch();
  const ListData = useSelector((state) => state?.Vendors?.serviceCenter);
  const [filterText, setFilterText] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [type, setType] = useState(null);
  const [itemId, setItemId] = useState("");
  const [columnDefs] = useState([
    {
      field: "warranty_package_name",
      headerName: "Warranty package name",
      headerTooltip: "Warranty package name",
    },
    {
      field: "warranty_package_name",
      headerName: "Package name",
      headerTooltip: "Package name",
    },
    {
      field: "coverage_details",
      headerName: "Coverage details",
      headerTooltip: "Coverage details",
    },
    {
      field: "warranty_period_type",
      headerName: "Warranty period type",
      headerTooltip: "Warranty period type",
    },
    {
      field: "warranty_period",
      headerName: "Warranty period",
      headerTooltip: "Warranty period",
    },

    {
      field: "features",
      headerName: "Features",
      headerTooltip: " Features",
    },
    // {
    //   field: "unit",
    //   headerName: "Unit",
    //   headerTooltip: " Unit",
    // },
  ]);

  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      filter: true,
      sortable: true,
      resizable: true,
    }),
    []
  );

  const handleClear = () => {
    if (filterText) {
      // setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };
  const handleSearch = (searchValue) => {
    const searchTerm =
      searchValue && typeof searchValue === "string"
        ? searchValue.toLowerCase()
        : "";

    const filteredList = ListData?.filter((row) => {
      const name = row?.data?.product_name?.toLowerCase();
      return name.includes(searchTerm);
    });

    // setFilteredData(filteredList);
  };
  const handleRowClick = (row) => {
    console.log("rowdata", row);
    setModalOpen(true);
    setType("edit");
    dispatch(tryfetchWarrantysDetailByID(row.data.id));
    setItemId(row.data.id);
  };
  const resetItemId = () => {
    setItemId("");
  };
  // useEffect(() => {
  //   getData(1);
  // }, [filterText]);
  const cellClicked = (params) => {
    params.node.setSelected(true);
  };
  const handleAdd = () => {
    setType("add");
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const gridStyle = useMemo(() => ({ height: "60vh", width: "100%" }), []);
  useEffect(() => {
    dispatch(tryfetchServiceCentersList());
  }, []);
  useEffect(() => {
    console.log("ListData", ListData);
  }, [ListData]);
  return (
    <React.Fragment>
      <Page>
        <PageContent>
          <ListHead>
            {/* <Title>Products</Title>
            <ListButtons>
              <Button onClick={handleAdd}>
                <i class="fa fa-plus" aria-hidden="true"></i> New
              </Button>
              <Button $primary>
                <i class="fa fa-cog" aria-hidden="true"></i>
              </Button>
            </ListButtons> */}
            <HeaderComponent
              onFilter={handleSearch}
              onClear={handleClear}
              filterText={filterText}
              handleAdd={handleAdd}
            />
          </ListHead>
          <ListGridTable className="ag-theme-alpine">
            <AgGridReact
              rowData={ListData}
              columnDefs={columnDefs}
              onRowClicked={handleRowClick}
              animateRows={true}
              gridStyle={gridStyle}
              pagination={true}
              paginationAutoPageSize={true}
              sideBar={itemId ? false : sideBar}
              defaultColDef={defaultColDef}
            ></AgGridReact>
          </ListGridTable>
        </PageContent>
        {/* {itemId ? (
          <PageContent>
            <SingleProductView
              close={resetItemId}
              itemId={itemId}
              data={rowData}
            />
          </PageContent>
        ) : (
          ""
        )} */}
      </Page>
      <AddWarrantyModal
        itemId={itemId}
        type={type}
        open={isModalOpen}
        onClose={handleCloseModal}
      />
    </React.Fragment>
  );
};

const Page = styled.div`
  width: 100%;
  margin-left: 10px;
  margin-top: 10px;
  border-radius: 22px;
  background-color: #fff;
  padding: 1em;
  display: flex;
`;
const PageContent = styled.div`
  // padding: calc(60px + 24px) 0 60px 0;
  width: ${(props) => (props.$singleView ? "350px" : "100%")};
  overflow: auto;
  border-right: ${(props) =>
    props.$singleView ? "0.5px solid #aeaeae;" : "none"};
  transition: all 0.3s ease-out;
  @media (max-width: 1200px) {
    display: ${(props) => (props.$singleView ? "none" : "block")};
  }
`;
const ListHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid #bfbfbf;
  padding: 0 11px 20px 15px;
`;
const ListGridTable = styled.div`
  width: 100%;
  height: 70vh;
`;
export default ServiceCenterList;
