import React, { useEffect, useMemo, useState } from "react";
import { MDBTable, MDBTableHead, MDBTableBody, MDBBtn } from "mdb-react-ui-kit";
import {
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
} from "mdb-react-ui-kit";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import HeaderComponent from "./HeaderComponent";
import { Page, PageContent } from "../../Components/StyledComponents/Common";
import { tryfetchSupplierList } from "../../Slices/VendorSlice";
import SingleSupplierView from "./SingleSupplierView";

const OEMSupplierList = () => {
  const dispatch = useDispatch();
  const ListData = useSelector((state) => state?.vendor?.suppliers);
  const [filterText, setFilterText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [itemId, setItemId] = useState("");
  const itemsPerPage = 10; // Set the number of items per page
  const totalPages = Math.ceil(filteredData?.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // Get the current page data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData?.slice(indexOfFirstItem, indexOfLastItem);
  const columnDefs = useMemo(() => [
    {
      field: "name",
      headerName: "Name",
      headerTooltip: "Name",
    },
    {
      field: "partner_id",
      headerName: "OEM Id",
      headerTooltip: "OEM Id",
    },
    // {
    //   field: "supplier_partner_no",
    //   headerName: "Supplier Partner No",
    //   headerTooltip: "Supplier Partner No",
    // },
    {
      field: "gst_no",
      headerName: "GST",
      headerTooltip: "GST",
    },

    {
      field: "mobile",
      headerName: "Phone",
      headerTooltip: "Phone",
    },
    {
      field: "email",
      headerName: "Email",
      headerTooltip: " Email",
    },
    {
      field: "website",
      headerName: "Website",
      headerTooltip: " Website",
    },
  ]);
  const columnDefs1 = useMemo(() => [
    {
      field: "name",
      headerName: "Name",
      headerTooltip: "Name",
    },
  ]);

  const handleClear = () => {
    if (filterText) {
      setFilterText("");
    }
  };
  const handleSearch = (searchValue) => {
    setFilterText(searchValue);
    const filteredList = ListData.filter((row) => {
      const id = String(row.batch_number).toLowerCase();
      const search = searchValue.toLowerCase();
      return id.includes(search);
    });
    setFilteredData(filteredList);
  };

  const handleRowClick = (row) => {
    console.log("supplier single", row);
    setRowData(row);
    setItemId(row?.id);
    // dispatch(tryfetchOEMDetailByID(row?.id));
  };

  const resetItemId = () => {
    setItemId("");
  };

  const handleAdd = () => {
    // history(`/OEM/Add`);
  };

  useEffect(() => {
    console.log("supplier liest");
    dispatch(tryfetchSupplierList());
  }, []);

  useEffect(() => {
    // console.log("supplier liest", ListData);
    setFilteredData(ListData);
  }, [ListData]);

  return (
    <React.Fragment>
      <Page>
        <PageContent $singleView={itemId}>
          <ListHead>
            <HeaderComponent
              onFilter={handleSearch}
              onClear={handleClear}
              handleAdd={handleAdd}
              itemId={itemId}
              filterText={filterText}
            />
          </ListHead>
          <>
            <div className="list-table">
              <MDBTable>
                <MDBTableHead color="#4D5FFF" align="left">
                  <tr>
                    {!itemId
                      ? columnDefs.map((colDef) => (
                          <th
                            style={customHeaderStyle}
                            key={colDef.field}
                            scope="col"
                            className="header-text"
                          >
                            {colDef.headerName}
                          </th>
                        ))
                      : columnDefs1.map((colDef) => (
                          <th
                            style={customHeaderStyle}
                            key={colDef.field}
                            scope="col"
                            className="header-text"
                          >
                            {colDef.headerName}
                          </th>
                        ))}
                  </tr>
                </MDBTableHead>

                <MDBTableBody align="left">
                  {currentItems?.map((rowData) => (
                    <tr key={rowData.customerId}>
                      {!itemId
                        ? columnDefs?.map((colDef) => (
                            <td
                              key={colDef.field}
                              onClick={() => handleRowClick(rowData)}
                              style={customCellStyle}
                            >
                              {rowData[colDef.field]}
                            </td>
                          ))
                        : columnDefs1?.map((colDef) => (
                            <td
                              key={colDef.field}
                              onClick={() => handleRowClick(rowData)}
                              style={customCellStyle}
                            >
                              {rowData[colDef.field]}
                            </td>
                          ))}
                    </tr>
                  ))}
                </MDBTableBody>
              </MDBTable>
            </div>

            <MDBPagination className="mt-3" end>
              <MDBPaginationItem disabled={currentPage === 1}>
                <MDBPaginationLink
                  className="page-link"
                  aria-label="Previous"
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <span aria-hidden="true">&laquo;</span>
                </MDBPaginationLink>
              </MDBPaginationItem>
              {Array.from({ length: totalPages }, (_, i) => (
                <MDBPaginationItem key={i} active={i + 1 === currentPage}>
                  <MDBPaginationLink
                    className="page-link"
                    onClick={() => handlePageChange(i + 1)}
                  >
                    {i + 1}
                  </MDBPaginationLink>
                </MDBPaginationItem>
              ))}
              <MDBPaginationItem disabled={currentPage === totalPages}>
                <MDBPaginationLink
                  className="page-link"
                  aria-label="Next"
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <span aria-hidden="true">&raquo;</span>
                </MDBPaginationLink>
              </MDBPaginationItem>
            </MDBPagination>
          </>
        </PageContent>
        {itemId ? (
          <PageContent>
            <SingleSupplierView
              close={resetItemId}
              itemId={itemId}
              data={rowData}
            />
          </PageContent>
        ) : (
          ""
        )}
      </Page>
    </React.Fragment>
  );
};

const ListHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid #bfbfbf;
  padding: 0 11px 20px 15px;
  .header-text {
    color: "#4D5FFF"; // Default text color
    font-size: 25px; // Default font size
  }
`;
const customHeaderStyle = {
  // background: "#4D5FFF",
  color: "#4D5FFF",
  fontSize: "13px",
  fontWeight: "500",
  padding: "1.3rem 0.5rem",
};

const customCellStyle = {
  // border: "1px solid #CCCCCC",
  padding: "1.3rem 0.5rem",
};
export default OEMSupplierList;
