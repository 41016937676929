import { put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { apiPost } from "../config/apiConfig.js";
import {
  setFilteredWarrantyListData,
  setWarrantyClaimsList,
  setWarrantysDetailByID,
  setWarrantysListData,
  tryfetchFilteredWarrantyList,
  tryfetchWarrantyClaimsList,
  tryfetchWarrantysDetailByID,
  tryfetchWarrantysList,
} from "../Slices/WarrantySlice.jsx";
import {
  ALL_CLAIM_REGISTRATION,
  FILTER_WARRANTY,
  SINGLE_WARRANTY_DETAILS,
  WARRANTY_LIST,
} from "../config/BaseUrl.js";

function* fetchWarrantyListData() {
  // console.log("PRODUCT_LIST started");
  let token = localStorage.getItem("token");

  try {
    var bodyFormData = new FormData();
    bodyFormData.append("token", token);
    let resp = yield apiPost(WARRANTY_LIST, bodyFormData);
    console.log("WARRANTY_LIST", resp);
    if (resp.response.status === 200) {
      yield put(setWarrantysListData(resp.response.data.warranty));
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.message;
    } else {
      console.log("unexpected error: ", error);
      // return yield put(setUserListData());
    }
  }
}
function* fetchWarrantyDetailByID(data) {
  console.log("fetchWarrantyDetailByIDPRODUCT_LIST started");
  let token = localStorage.getItem("token");
  try {
    var bodyFormData = new FormData();
    bodyFormData.append("token", token);
    bodyFormData.append("id", data.payload);
    let resp = yield apiPost(SINGLE_WARRANTY_DETAILS, bodyFormData);
    console.log("SINGLE_WARRANTY_DETAILS", resp);
    if (resp.response.status === 200) {
      yield put(setWarrantysDetailByID(resp.response.data.products));
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.message;
    } else {
      console.log("unexpected error: ", error);
      // return yield put(setUserListData());
    }
  }
}
function* fetchWarrantyClaimListData(data) {
  // console.log("PRODUCT_LIST started");
  let token = localStorage.getItem("token");

  try {
    var bodyFormData = new FormData();
    bodyFormData.append("token", token);
    bodyFormData.append("type", data.payload);
    let resp = yield apiPost(ALL_CLAIM_REGISTRATION, bodyFormData);
    console.log("WARRANTY_LIST", resp);
    if (resp.response.status === 200) {
      yield put(setWarrantyClaimsList(resp.response.data.products));
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.message;
    } else {
      console.log("unexpected error: ", error);
      // return yield put(setUserListData());
    }
  }
}
function* fetchFilteredWarrantyListData(data) {
  console.log("fetchWarrantyDetailByIDPRODUCT_LIST started");
  let token = localStorage.getItem("token");
  try {
    var bodyFormData = new FormData();
    bodyFormData.append("token", token);
    bodyFormData.append(
      "warranty_package_name",
      data.payload.warranty_package_name
    );
    bodyFormData.append(
      "warranty_period_type",
      data.payload.warranty_period_type
    );
    let resp = yield apiPost(FILTER_WARRANTY, bodyFormData);
    console.log("SINGLE_WARRANTY_DETAILS", resp);
    if (resp.response.status === 200) {
      yield put(setFilteredWarrantyListData(resp?.response?.data?.data));
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.message;
    } else {
      console.log("unexpected error: ", error);
      // return yield put(setUserListData());
    }
  }
}
export default function* WarrantySaga() {
  yield takeEvery(tryfetchWarrantysList, fetchWarrantyListData);
  yield takeEvery(tryfetchFilteredWarrantyList, fetchFilteredWarrantyListData);
  yield takeEvery(tryfetchWarrantysDetailByID, fetchWarrantyDetailByID);
  yield takeEvery(tryfetchWarrantyClaimsList, fetchWarrantyClaimListData);
}
