import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { IoClose, IoPrintOutline } from "react-icons/io5";
import { PiPencilSimple } from "react-icons/pi";
import { TfiTrash } from "react-icons/tfi";
import { Link } from "react-router-dom";
import styled from "styled-components";
import enlargedImg from "../../assets/Product/Png final.png";
import Img1 from "../../assets/Product/Rectangle 1054.png";
import Img2 from "../../assets/Product/Rectangle 1055.png";
import Img3 from "../../assets/Product/Rectangle 1056.png";
import Img4 from "../../assets/Product/Rectangle 1057.png";
import { useDispatch, useSelector } from "react-redux";
import DeleteAisle from "./DeleteModal";
import { ToastContainer, toast } from "react-toastify";
import { DELETE_BATCH } from "../../config/BaseUrl";
import {
  clearBatchDetailsByID,
  tryfetchBatchList,
} from "../../Slices/BatchSlice";
import { apiPost } from "../../config/apiConfig";
import { CiImageOn } from "react-icons/ci";
import { useEffect } from "react";
const SerialNumbersTable = ({ serialNumbers }) => {
  // Chunk the serial numbers into groups of three
  function chunkArray(array, size) {
    return array?.reduce((chunks, element, index) => {
      const chunkIndex = Math.floor(index / size);

      if (!chunks[chunkIndex]) {
        chunks[chunkIndex] = [];
      }

      chunks[chunkIndex].push(element);

      return chunks;
    }, []);
  }
  const chunkedSerialNumbers = chunkArray(serialNumbers, 3);

  return (
    <div>
      {/* <h2 style={{ whiteSpace: "nowrap" }}>Serial Numbers</h2> */}
      <TableContainer>
        <StyledTable>
          <tbody>
            {chunkedSerialNumbers?.map((chunk, rowIndex) => (
              <tr key={rowIndex}>
                {chunk?.map((serial, cellIndex) => (
                  <StyledTd key={cellIndex}>{serial}</StyledTd>
                ))}
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </TableContainer>
    </div>
  );
};
const SingleBatchView = ({
  close,
  data,
  itemId,
  currentPage,
  itemsPerPage,
}) => {
  const dispatch = useDispatch();
  const Data = useSelector((state) => state.batch?.batchDetailByID);
  const [selectedImage, setSelectedImage] = useState(null);
  const [deleteModel, setdeleteModel] = useState(false);

  const handleMiniImgClick = (imgSrc) => {
    setSelectedImage(imgSrc);
  };
  const handleOpenDelete = () => {
    // setId(id);
    setdeleteModel(true);
  };
  const handleDelete = () => {
    let token = localStorage.getItem("token");
    var bodyFormData = new FormData();
    bodyFormData.append("token", token);
    bodyFormData.append("id", itemId);
    let resp = apiPost(DELETE_BATCH, bodyFormData);
    resp.then((resp) => {
      console.log("delete resp", resp);
      switch (resp.response.status) {
        case 200:
          var data = {
            pageNo: currentPage,
            itemPerPage: itemsPerPage,
          };
          dispatch(tryfetchBatchList(data));
          toast.success(resp.response.data.message);
          setTimeout(() => {
            setdeleteModel(false);
            close();
          }, 2000);
          break;

        default:
        // getData();
      }
    });
  };
  useEffect(() => {
    return () => {
      setSelectedImage(null);
      // dispatch(clearBatchDetailsByID());
    };
  }, []);
  useEffect(() => {
    setSelectedImage(Data?.image);
  }, [Data]);

  return (
    <>
      <ToastContainer />

      <RightBar>
        <Container>
          <Row>
            <Col md={6}>
              <Row>
                <Info md={12}>{Data?.batch_number}</Info>
              </Row>
              <Row className="py-5">
                <Col md={6}>
                  <Key>Product Name</Key>
                  <Value>{Data?.product_name}</Value>
                </Col>
                <Col md={6}>
                  <Key>Model Number</Key>
                  <Value>{Data?.model_number}</Value>
                </Col>
                {/* </Row>
              <Row className="py-5"> */}
                <Col md={6}>
                  <Key>MFG Date</Key>
                  <Value>{Data?.manufacturing_date}</Value>
                </Col>
                <Col md={6}>
                  <Key>Product Type</Key>
                  <Value>{Data?.product_type_name}</Value>
                </Col>
                {/* </Row>
              <Row className="py-5"> */}
                <Col md={6}>
                  <Key>Warranty Type</Key>
                  <Value>{Data?.warranty_package_name}</Value>
                </Col>
                <Col md={6}>
                  <Key>Officer id</Key>
                  <Value>{Data?.officer_id}</Value>
                </Col>
                {/* </Row>
              <Row className="py-5"> */}
                <Col md={6}>
                  <Key>Batch Number </Key>
                  <Value>{Data?.batch_number}</Value>
                </Col>
                <Col md={6}>
                  <Key>Officer Name</Key>
                  <Value>{Data?.officer_name}</Value>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row className=" py-4">
                <div className="col-md-2"></div>
                <IconContainer className="">
                  {/* <IconWrapper className="icons">
                    <IoPrintOutline color="838383" />
                  </IconWrapper> */}
                  <Link to={`/Batch/Edit/${itemId}`}>
                    <IconWrapper className="icons">
                      <PiPencilSimple color="838383" />
                    </IconWrapper>
                  </Link>
                  <IconWrapper className="icons" onClick={handleOpenDelete}>
                    <TfiTrash color="#ED2D22" />
                  </IconWrapper>
                  <IconWrapper
                    className="icons"
                    onClick={close}
                    style={{ border: "0.5px solid #ED2D22" }}
                  >
                    <IoClose color="#ED2D22" />
                  </IconWrapper>
                </IconContainer>
              </Row>
              <Row className="d-flex">
                <Col md={2} className="d-grid">
                  {[
                    { id: 1, src: Data?.image },
                    { id: 2, src: Data?.image4 },
                    { id: 3, src: Data?.image2 },
                    { id: 4, src: Data?.image3 },
                  ].map((img, index) => (
                    <MiniImg
                      key={index}
                      md={3}
                      onClick={() => handleMiniImgClick(img.src)}
                    >
                      {img?.src ? (
                        <img src={img.src} alt={`img${img.id}`} srcSet="" />
                      ) : (
                        <CiImageOn size={50} />
                      )}
                    </MiniImg>
                  ))}
                </Col>

                <Col
                  md={10}
                  className="d-flex justify-content-center align-self-center"
                >
                  <EnlargedImg>
                    {selectedImage ? (
                      <img
                        src={selectedImage || Data.image}
                        alt="enlarged Image"
                        srcSet=""
                      />
                    ) : (
                      <CiImageOn size={160} />
                    )}{" "}
                  </EnlargedImg>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <SerialInfo md={12}>
              Serial Numbers
              <Line />
            </SerialInfo>
          </Row>
          <Row>
            <Col md={12}>
              <SerialNumbersTable
                serialNumbers={Data?.serial_nos?.map(
                  (serial) => serial.serial_no
                )}
              />
            </Col>
          </Row>
        </Container>
        <DeleteAisle
          openModal={deleteModel}
          setOpenModel={setdeleteModel}
          handleDeleteUom={handleDelete}
          id={itemId}
        />
      </RightBar>
    </>
  );
};
const RightBar = styled.div`
  width: 100%;
  padding: 2.5rem;
`;
const IconContainer = styled.div`
  display: flex;
  justify-content: end;
  /* width: 100rem; */
  padding-right: 2em;
  gap: 2rem;
`;
const EnlargedImg = styled.div`
  display: flex;
  width: 16rem;
  height: 16rem;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
const MiniImg = styled(Col)`
  display: flex;
  margin-top: 1em;
  width: 5rem;
  height: 5rem;
  justify-content: center;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
const IconWrapper = styled.div`
  border: 0.5px solid #aeaeae;
  border-radius: 5px;
  padding: 0.6em;
  display: flex;
  justify-content: space-around;
  cursor: pointer;
  height: min-content;
`;
const ProductName = styled.div`
  font-size: 20px;
  font-weight: 500;
`;
const SerialNumber = styled.div`
  font-size: 13px;
  margin-top: 1rem;
  color: #8b8b8b;
  font-weight: 500;
  // line-height: 15px;
`;
const Rating = styled.div`
  margin-top: 1rem;
  font-size: 11px;
  font-weight: 400;
`;
const Heading = styled.div`
  margin-top: 1rem;
  font-size: 13px;
  font-weight: 400;
`;
const About = styled.p`
  margin-top: 1rem;
  font-size: 11px;
  font-weight: 400;
`;
const Info = styled(Col)`
  margin-top: 2rem;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
`;
const Line = styled.hr`
  color: #bfbfbf;
`;
const Key = styled(Row)`
  font-weight: 400;
  margin-top: 1em;
`;
const Value = styled(Row)`
  color: #bfbfbf;
`;
// const SerialNumbersTable = styled.table`
//   width: 100%;
//   td {
//     padding: 0.5rem;
//     text-align: center;
//     border: 1px solid #e0e0e0;
//   }
// `;
const TableContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow-x: auto;
  max-height: 28rem;
`;

const StyledTable = styled.table`
  width: 99%;
  border-collapse: collapse;
  margin: 10px 0;
  max-height: 5rem;
`;

const StyledTh = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
`;

const StyledTd = styled.td`
  border: 1px solid #ddd;
  // padding: 8px 15px;
  width: 33%;
  font-style: italic;
  text-align: center;
`;
const SerialInfo = styled.div`
  font-weight: 500;
  text-transform: uppercase;
  font-size: 15px;
`;
export default SingleBatchView;
