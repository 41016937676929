import React, { useState } from "react";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
// import { parseExcelFile } from "./ExcelUtils";
import styled from "styled-components";
import { useRef } from "react";
import * as XLSX from "xlsx";
import {
  SaveButton,
  SubmitButton,
} from "../../Components/StyledComponents/Buttons";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import excel from "../../assets/Images/excel.png";
import axios from "axios";
import {
  ADD_BATCH,
  BATCH_BALANCE_CHECK,
  PRODUCT_LIST,
  SERIAL_NO,
  SETTINGS_TYPE,
  UPDATE_BATCH,
  WARRANTY_LIST,
} from "../../config/BaseUrl";
import { apiPost } from "../../config/apiConfig";
import { useEffect } from "react";
import {
  HeadTitle,
  InputStyle,
  selectStyle,
} from "../../Components/StyledComponents/Common";
import {
  clearProductsDetailsByID,
  tryfetchProductsDetailByID,
  tryfetchProductsList,
} from "../../Slices/ProductSlice";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "reactstrap";
import {
  clearBatchDetailsByID,
  tryfetchBatchDetailByID,
  tryfetchBatchList,
} from "../../Slices/BatchSlice";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import ExistingSerialNumberMoodal from "./ExistingSerialNumberModal";

const SerialNumbersTable = ({ serialNumbers }) => {
  // Chunk the serial numbers into groups of three
  function chunkArray(array, size) {
    return array?.reduce((chunks, element, index) => {
      const chunkIndex = Math.floor(index / size);

      if (!chunks[chunkIndex]) {
        chunks[chunkIndex] = [];
      }

      chunks[chunkIndex].push(element);

      return chunks;
    }, []);
  }
  const chunkedSerialNumbers = chunkArray(serialNumbers, 3);

  return (
    <div>
      {/* <h2 style={{ whiteSpace: "nowrap" }}>Serial Numbers</h2> */}
      <TableContainer>
        <StyledTable>
          <tbody>
            {chunkedSerialNumbers?.map((chunk, rowIndex) => (
              <tr key={rowIndex}>
                {chunk?.map((serial, cellIndex) => (
                  <StyledTd key={cellIndex}>{serial}</StyledTd>
                ))}
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </TableContainer>
    </div>
  );
};

const RenderSerialNumbersTable = ({ chunkedData }) => {
  return (
    <>
      <TableContainer>
        <StyledTable>
          <tbody>
            {chunkedData?.map((chunk, rowIndex) => (
              <tr key={rowIndex}>
                {chunk?.map((row, cellIndex) => (
                  <React.Fragment key={cellIndex}>
                    {row?.map((cell, innerIndex) => (
                      <StyledTd key={innerIndex}>{cell}</StyledTd>
                    ))}
                  </React.Fragment>
                ))}
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </TableContainer>
    </>
  );
};

const AddBatch = () => {
  const Data = useSelector((state) => state.product?.productDetailByID);
  const dispatch = useDispatch();
  const BatchData = useSelector((state) => state?.batch?.batchDetailByID);
  // const ProductListData = useSelector(
  //   (state) => state?.product?.productTableData
  // );

  const params = useParams();
  const navigate = useNavigate();
  const [excelData, setExcelData] = useState([]);
  const [IsSaving, setIsSaving] = useState(false);
  // const [file, setFile] = useState(Array.from({ length: 4 }, () => []));
  const [warranty, setWarranty] = useState([]);
  const [productType, setProductType] = useState([]);
  const [productList, setProductList] = useState([]);
  const [AW, setAW] = useState([]);
  const [activeTab, setActiveTab] = useState("1");
  const [fromSerial, setFromSerial] = useState("");
  const [toSerial, setToSerial] = useState("");
  const [serialNumbers, setSerialNumbers] = useState([]);
  const [placeholder, setPlaceholder] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [packageDetails, setPackageDetails] = useState([]);
  const [openSerialModal, setOpenSerialModal] = useState(false);
  const [existingSerialNos, setExistingSerialNos] = useState([]);
  const [openResponseModal, setOpenResponseModal] = useState(false);
  const [response, setResponse] = useState("");
  const [isExcel, setIsExcel] = useState(0);

  const chunkedData = chunkArray(excelData.slice(1), 3); // Chunk data into groups of 3
  const [values, setValues] = useState({
    product_id: "",
    product_type: "",
    model_number: "",
    batch_number: "",
    manufacturing_date: new Date(),
    warranty_type: "",
    additional_warranty: "",
    officer_name: "",
    officer_id: "",
    serial_no: "",
    serial_no: "",
    serial_no: "",
    from: "",
    to: "",
  });
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const analyzePattern = () => {
    setExcelData([]);
    const num1 = parseInt(fromSerial, 10);
    const num2 = parseInt(toSerial, 10);

    if (isNaN(num1) || isNaN(num2)) {
      setSerialNumbers([]);
      // alert("Please enter valid numbers");
      return;
    }

    const numbersBetween = [];

    for (let i = num1; i <= num2; i++) {
      numbersBetween.push(i);
    }

    setSerialNumbers(numbersBetween);
  };
  useEffect(() => {
    if (fromSerial > 0 && toSerial >= fromSerial) {
      analyzePattern();
    } else {
      // setSerialNumbers([]);
    }
  }, [fromSerial, toSerial]);
  // const handleError = () => {
  //   if (fromSerial < toSerial) {
  //     toast.error("");
  //     setToSerial("");
  //   }
  // };
  const [toSerialError, setToSerialError] = useState(false);
  const [toSerialErrorMsg, setToSerialErrorMsg] = useState("");

  useEffect(() => {
    if (toSerial !== "" && toSerial < fromSerial) {
      setToSerialErrorMsg(
        "To serial number should be greater than starting serial number"
      );
      setToSerialError(true);
      // setToSerial("");
    } else {
      setToSerialError(false);
    }
    // if (toSerial !== "" && parseInt(toSerial) - parseInt(fromSerial) > 100) {
    //   setToSerialErrorMsg(
    //     `Please enter the number upto ${parseInt(fromSerial + 100)}`
    //   );
    //   setToSerialError(true);
    // } else {
    //   setToSerialError(false);
    // }
  }, [toSerial]);

  const handleInputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const addBatch = async (event) => {
    // console.log("addBatch", event);
    setIsSaving(true);
    try {
      var token = localStorage.getItem("token");
      var bodyFormData = new FormData();
      bodyFormData.append("token", token);
      bodyFormData.append("product_id", values.product_id);
      bodyFormData.append("product_type", values.product_type);
      bodyFormData.append("model_number", values.model_number);
      bodyFormData.append("batch_number", values.batch_number);
      bodyFormData.append("manufacturing_date", values.manufacturing_date);
      bodyFormData.append("warranty_type", values.warranty_type);
      bodyFormData.append("additional_warranty", values.additional_warranty);
      bodyFormData.append("officer_name", values.officer_name);
      bodyFormData.append("officer_id", values.officer_id);
      bodyFormData.append("is_excel", isExcel);
      serialNumbers?.forEach((serial, index) => {
        console.log(`${index}`, serial);
        // if (serial.length) {
        bodyFormData.append(`serial_no[${index}]`, serial.toString());
        // }
      });

      // console.log("serialNumbersArray", serialNumbersArray);
      // bodyFormData.append("serial_numbers", JSON.stringify(serialNumbersArray));
      // bodyFormData.append("serial_numbers", JSON.stringify(serialNumbers));
      // console.log("formdata_________is", serialNumbers);
      setIsSaving(false);

      let resp = await apiPost(ADD_BATCH, bodyFormData);
      console.log("batch add resp", resp);

      if (
        resp.response.data.message === "Batch Added successfully" &&
        !resp.response.data.data.exist_serialno.length
      ) {
        console.log(
          "batch add resp with no exixt serial nnumber nd successfulll"
        );

        toast.success(resp.response.data.message);
        setTimeout(() => {
          dispatch(clearProductsDetailsByID());
          // dispatch(tryfetchBatchList());
          if (event === true) {
            navigate("/Batch-List");
            setSerialNumbers([]);
            setValues({});
          }
          setIsSaving(false);
        }, 2000);
      } else if (
        resp.response.data.message === "Reached Your Limit" ||
        resp.response.data.message ===
          "Batch Not Added,The serial numbers have already been taken." ||
        resp.response.data.message === "Please subscribe to the new package."
      ) {
        console.log(
          "reached ur limit , Batch Not Added,The serial numbers have already been taken.,Please subscribe to the new package."
        );
        // toast.error(resp.response.data.message);
        setResponse(resp.response.data.message);
        setOpenResponseModal(true);
        // setTimeout(() => {
        dispatch(clearProductsDetailsByID());
        // dispatch(tryfetchBatchList());
        if (event === true) {
          // navigate("/Batch-List");
          setSerialNumbers([]);
          setValues({});
        }
        setIsSaving(false);
        // }, 3000);
      } else if (resp.response.data.message === "Renew") {
        toast.error(resp.response.data.message);
        setPackageDetails(resp?.response?.data?.data); // changed on 25/07/2024
        setIsSaving(false);
        handleOpenModal();
      } else if (
        resp.response.data.message?.toLowerCase?.() ===
          "batch added successfully" &&
        resp.response.data.data?.exist_serialno?.length > 0
      ) {
        // console.log("esleresp", resp.response.data.data);
        console.log("esleresp not working case  exixting serial number exixt");
        setOpenSerialModal(true);
        setExistingSerialNos(resp.response.data?.data?.exist_serialno);
        setPackageDetails(resp?.response?.data?.data);
        setIsSaving(false);
      } else if (
        resp.response.data.message === "Renew" &&
        resp.response.data.data?.exist_serialno?.length
      ) {
        console.log("rfenew and exixting serial number exixts");
        setOpenSerialModal(true);
        setExistingSerialNos(resp.response.data?.data?.exist_serialno);
        setPackageDetails(resp?.response?.data?.data);
        setIsSaving(false);
        // handleOpenModal();
        // console.log("esleresp", resp);
        // } else if (resp.response.data.message === "Batch Number Already Exists") {
        //   toast.error(resp.response.data.message);
        //   console.log("Batch Number Already Exists");
      }
      console.log("exception");
    } catch (error) {
      console.log("catch");
      setIsSaving(false);
      toast.error(error.message);
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  };

  async function handleConfirm() {
    let noOfProduct = packageDetails?.serial_no?.length;
    localStorage.setItem("serialNumbers", JSON.stringify(packageDetails));
    let months = packageDetails?.no_of_periods || 0;
    // if (packageDetails?.exist_serialno.length > 0) {
    navigate(`/Subscription/Edit/${months}/${noOfProduct}`);
    setIsSaving(false);
    setSerialNumbers([]);
    setValues({});
    // } else {
    //   navigate(`/Subscription`);
    // }
  }
  const updateBatch = async (e) => {
    setIsSaving(true);
    try {
      var token = localStorage.getItem("token");
      var bodyFormData = new FormData();
      bodyFormData.append("token", token);
      bodyFormData.append("id", params.id);
      bodyFormData.append("product_id", values.product_id);
      bodyFormData.append("product_type", values.product_type);
      bodyFormData.append("model_number", values.model_number);
      bodyFormData.append("batch_number", values.batch_number);
      bodyFormData.append("manufacturing_date", values.manufacturing_date);
      bodyFormData.append("warranty_type", values.warranty_type);
      bodyFormData.append("additional_warranty", values.additional_warranty);
      bodyFormData.append("officer_name", values.officer_name);
      bodyFormData.append("officer_id", values.officer_id);
      // serialNumbers?.forEach((serial, index) => {
      //   bodyFormData.append(`serial_no[${index}]`, serial.toString());
      // });
      // console.log(" formdata is ", bodyFormData);
      let resp = await apiPost(UPDATE_BATCH, bodyFormData);
      if (resp.response.status === 200) {
        // console.log(" file values is asd", resp);
        toast.success(resp?.response?.data?.message);
        setTimeout(() => {
          // dispatch(tryfetchBatchList());
          dispatch(clearProductsDetailsByID());
          navigate("/Batch-List");
          setIsSaving(false);
          setSerialNumbers([]);
          setValues({});
        }, 2000);
      } else {
        toast.error(resp.response.data.message);
      }
    } catch (error) {
      setIsSaving(false);
      toast.error(error.message);
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  };
  const handleFileChange = async (e, inputIndex) => {
    setIsExcel(1);
    setSerialNumbers([]);
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      // Check if the file type is either xls or xlsx
      if (
        selectedFile.type === "application/vnd.ms-excel" || // For .xls
        selectedFile.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" // For .xlsx
      ) {
        // Update the state with the selected file
        setPlaceholder(selectedFile);

        // Parse the selected file using the parseExcelFile function
        const data = await parseExcelFile(selectedFile);
        // console.log("exceldata", data);
        // Check if data is an array and not empty
        if (Array.isArray(data) && data.length > 0) {
          const extractedSerialNumbers = data.flat();
          // Remove possible header row and empty rows (assuming the first row is a header)
          const serialNumbers = extractedSerialNumbers
            .slice(1)
            .filter((num) => typeof num === "number" && !isNaN(num));

          console.log("excelData", serialNumbers);

          setExcelData(data);
          setSerialNumbers(serialNumbers);
          setFromSerial(serialNumbers[0]);
          setToSerial(serialNumbers[serialNumbers.length - 1]);

          // Convert the extracted serial numbers array to a comma-separated string
          const serialNumbersString = serialNumbers.join(", ");
          // console.log("Comma-separated serial numbers:", serialNumbersString);
          // setSerialNumbers(serialNumbersString);
        } else {
          toast.error("Excel file is empty or not formatted correctly.");
        }
      } else {
        toast.error("Invalid file type. Please upload an Excel file.");
      }
    } else {
      // Handle the case when no file is selected
      toast.error("Please select a file.");
    }

    // This part of the code will always be executed, consider adjusting its purpose
    setFromSerial(0);
    setToSerial(0);
  };

  const parseExcelFile = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        resolve(parsedData);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsBinaryString(file);
    });
  };

  async function checkSubscription() {
    var token = localStorage.getItem("token");
    try {
      var bodyFormData = new FormData();
      bodyFormData.append("token", token);
      // bodyFormData.append("type", "product_type");
      let resp = await apiPost(SERIAL_NO, bodyFormData);
      console.log("respodnnre", resp);

      // console.log(
      //   "nofo product",
      //   parseInt(resp.response.data.data[0].no_of_products)
      // );
      // setPackageDetails(resp.response.data.data[0]);
      if (resp?.response?.status === 200) {
        let value = resp?.response.data.data;
        setValues({ ...values, batch_number: value.new_batch_no });
        setFromSerial(value.new_serial_no);
      } else {
        // addBatch(false);
        // handleOpenModal();
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.message;
      } else {
        console.log("unexpected error: ", error);
      }
    }
  }
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    navigate("/Batch-List");
  };
  useEffect(() => {
    console.log("serial numbers change", serialNumbers);
  }, [serialNumbers]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (params.type === "Add") {
      // checkSubscription();
      addBatch(true);
    } else {
      updateBatch();
    }
  };
  const handleCancel = () => {
    navigate(-1);
  };
  function chunkArray(array, size) {
    return array?.reduce((chunks, element, index) => {
      const chunkIndex = Math.floor(index / size);

      if (!chunks[chunkIndex]) {
        chunks[chunkIndex] = [];
      }

      chunks[chunkIndex].push(element);

      return chunks;
    }, []);
  }
  async function getProductType() {
    var token = localStorage.getItem("token");
    try {
      var bodyFormData = new FormData();
      bodyFormData.append("token", token);
      bodyFormData.append("type", "product_type");
      let resp = await apiPost(SETTINGS_TYPE, bodyFormData);
      // console.log("PRODUC_TYPE", resp);
      if (resp?.response?.status === 200) {
        setProductType(resp?.response?.data?.Details);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.message;
      } else {
        console.log("unexpected error: ", error);
      }
    }
  }
  async function getProductList() {
    var token = localStorage.getItem("token");
    try {
      var bodyFormData = new FormData();
      bodyFormData.append("token", token);
      bodyFormData.append("type", "product_type");
      let resp = await apiPost(PRODUCT_LIST, bodyFormData);
      console.log("PRODUC_LISTt", resp);
      if (resp?.response?.status === 200) {
        setProductList(resp?.response?.data?.products);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.message;
      } else {
        console.log("unexpected error: ", error);
      }
    }
  }
  const productTypes = productType?.map((item) => {
    // console.log("tyep", item);
    return {
      label: item.name,
      value: item.id,
    };
  });
  async function getWarranty() {
    var token = localStorage.getItem("token");
    try {
      var bodyFormData = new FormData();
      bodyFormData.append("token", token);
      // bodyFormData.append("type", "Warranty");
      let resp = await apiPost(WARRANTY_LIST, bodyFormData);
      // console.log("Warranty", resp);
      if (resp.response.status === 200) {
        setWarranty(resp.response.data.warranty);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.message;
      } else {
        console.log("unexpected error: ", error);
        // return yield put(setUserListData());
      }
    }
  }
  const warrantyOptions = warranty?.map((item) => {
    // console.log("warranty_package_name", item);
    return {
      label: item.warranty_package_name,
      value: item.id,
    };
  });
  async function getAdditionalWarranty() {
    var token = localStorage.getItem("token");
    try {
      var bodyFormData = new FormData();
      bodyFormData.append("token", token);
      bodyFormData.append("type", "Additional Warranty");
      let resp = await apiPost(SETTINGS_TYPE, bodyFormData);
      // console.log("getAdditionalWarranty", resp);
      if (resp?.response?.status === 200) {
        setAW(resp?.response?.data?.Details);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.message;
      } else {
        console.log("unexpected error: ", error);
      }
    }
  }
  const AWOptions = AW?.map((item) => {
    return {
      label: item.name,
      value: item.id,
    };
  });
  const handleCloseESNM = () => {
    setOpenSerialModal(false);
    navigate("/Batch-List");
  };
  const handleCloseRRM = () => {
    setOpenResponseModal(false);
    setSerialNumbers([]);
    setValues({});
    // dispatch(tryfetchBatchList());
    dispatch(clearProductsDetailsByID());
    navigate("/Batch-List");
  };
  useEffect(() => {
    getProductList();
    getProductType();
    getWarranty();
    // getAdditionalWarranty();
    // console.log("values files", params);
    if (params?.type === "Edit") {
      dispatch(tryfetchBatchDetailByID(params.id));
    }
    if (params?.type == "Add") {
      checkSubscription();
      dispatch(clearProductsDetailsByID());
      dispatch(clearBatchDetailsByID());
      setValues({
        product_id: "",
        product_type: "",
        model_number: "",
        batch_number: "",
        manufacturing_date: new Date("yyyy-mm-dd"),
        warranty_type: "",
        additional_warranty: "",
        officer_name: "",
        officer_id: "",
        serial_no: "",
        serial_no: "",
        from: "",
        to: "",
      });
      setSerialNumbers([]);
    }
  }, [params]);
  useEffect(() => {
    if (params.type === "Edit") {
      var serialnumber = BatchData?.serial_nos?.map(
        (serial) => serial.serial_no
      );
      // console.log("batchdata", BatchData);
      setValues(BatchData);
      setSerialNumbers(serialnumber);
      setFromSerial(serialnumber[0]);
      setToSerial(serialnumber[serialnumber.length - 1]);
    }
  }, [BatchData]);
  useEffect(() => {
    if (values.product_id) {
      dispatch(tryfetchProductsDetailByID(values.product_id));
    }
  }, [values.product_id]);
  useEffect(() => {
    if (Data) {
      // console.log("Dataedit", Data);
      setValues({
        ...values,
        product_type: Data?.product_type,
        model_number: Data?.model_number,
        warranty_type: Data?.warranty_type,
      });
    }
  }, [Data]);

  // useEffect(() => {
  //   return () => {
  //     dispatch(clearProductsDetailsByID());
  //     setSerialNumbers([]);
  //     setValues({});
  //   };
  // }, []);

  // useEffect(() => {
  //   if (params.type === "Add") {
  //     setSerialNumbers([]);
  //     setValues({
  //       product_id: "",
  //       product_type: "",
  //       model_number: "",
  //       batch_number: "",
  //       manufacturing_date: new Date(),
  //       warranty_type: "",
  //       additional_warranty: "",
  //       officer_name: "",
  //       officer_id: "",
  //       serial_no: "",
  //       serial_no: "",
  //       serial_no: "",
  //       from: "",
  //       to: "",
  //     });
  //   }
  // }, [params.type]);
  // number generator
  // useEffect(() => {
  //   console.log("placeholder", placeholder);
  // }, [placeholder]);

  return (
    <div className="page">
      <ToastContainer />
      <div className="page-inner-content">
        <div className="row">
          <HeadTitle className="col-md-12">
            <span>
              {params.type === "Add" ? "Add New Batch" : "Edit Batch"}
            </span>
          </HeadTitle>
        </div>
        <form action="" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-4">
              <div className="row mb-5">
                <div className="col-md-12 px-2">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Product Name *
                    </InputLabel>
                    <Select
                      sx={selectStyle}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={values.product_id}
                      required
                      IconComponent={(props) => (
                        <ExpandMoreIcon size={25} {...props} />
                      )}
                      label="Product Name"
                      name="product_id"
                      onChange={handleInputChange}
                    >
                      {productList?.map((items) => (
                        <MenuItem
                          key={items.product_id}
                          value={items.product_id}
                        >
                          {items.product_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div className="row mb-5">
                <div className="col-md-12 px-2">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Product Type *
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      sx={selectStyle}
                      value={values.product_type}
                      IconComponent={(props) => (
                        <ExpandMoreIcon size={25} {...props} />
                      )}
                      required
                      label=" Product Type"
                      name="product_type"
                      onChange={handleInputChange}
                    >
                      {productTypes?.map((type) => (
                        <MenuItem key={type.value} value={type.value}>
                          {type.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-md-12 px-2">
                  <TextField
                    label="Model Number"
                    variant="outlined"
                    fullWidth
                    name="model_number"
                    className="muiinputField"
                    required
                    sx={InputStyle}
                    value={values.model_number}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-md-12 px-2">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Warranty Type *
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={values.warranty_type}
                      label=" Warranty Type"
                      sx={selectStyle}
                      IconComponent={(props) => (
                        <ExpandMoreIcon size={25} {...props} />
                      )}
                      name="warranty_type"
                      required
                      onChange={handleInputChange}
                    >
                      {warrantyOptions?.map((type) => (
                        <MenuItem key={type.value} value={type.value}>
                          {type.label}
                        </MenuItem>
                      ))}
                      {/* <MenuItem value={"Express warranty"}>
                              Express warranty
                            </MenuItem>
                            <MenuItem value={"Lifetime warranties"}>
                              Lifetime warranties
                            </MenuItem>
                            <MenuItem value={"Factory warranties"}>
                              Factory warranties
                            </MenuItem>
                            <MenuItem value={"Extended warranty"}>
                              Extended warranty
                            </MenuItem> */}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-md-12 px-2">
                  <TextField
                    label="Batch Number"
                    sx={InputStyle}
                    variant="outlined"
                    fullWidth
                    required
                    name="batch_number"
                    value={values.batch_number}
                    disabled
                    // onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-md-12 px-2">
                  <TextField
                    label="Manufacturing Date"
                    type="date"
                    variant="outlined"
                    fullWidth
                    sx={InputStyle}
                    required
                    // multiline
                    // minRows="3"
                    name="manufacturing_date"
                    value={values.manufacturing_date}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              {/* <div className="row mb-5">
                <div className="col-md-12 px-2">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Additional Warranty
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={values.additional_warranty}
                      name="additional_warranty"
                      sx={selectStyle}
                       IconComponent={(props) => (
                        <ExpandMoreIcon size={25} {...props} />
                      )}
                      label="Additional Warranty"
                      onChange={handleInputChange}
                    >
                      {AWOptions?.map((type) => (
                        <MenuItem key={type.value} value={type.value}>
                          {type.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div> */}
              <div className="row mb-5">
                <div className="col-md-12 px-2">
                  <TextField
                    label="Officer Name"
                    variant="outlined"
                    required
                    fullWidth
                    name="officer_name"
                    sx={InputStyle}
                    value={values.officer_name}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-md-12 px-2">
                  <TextField
                    label="Officer Id"
                    variant="outlined"
                    type="number"
                    fullWidth
                    required
                    name="officer_id"
                    sx={InputStyle}
                    value={values.officer_id}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
            <Divider style={{ padding: "0" }} />
            <div className="col-md-7">
              <HeadTitle>
                <span style={{ color: "#515151", fontWeight: "500" }}>
                  {" "}
                  Serial Number
                </span>
                <h5 style={{ color: "#515151" }}>
                  Effortlessly upload serial numbers in bulk via Excel or range
                  method with our OEM app.
                </h5>
              </HeadTitle>
              <div className="row my-5">
                <div className="col-md-12 px-2"></div>

                <div className="row d-flex align-items-center">
                  <div className="col-md-5 py-5">
                    <ImageInputSection
                      placeholder={
                        !placeholder?.name ? (
                          params.type === "Edit" ? (
                            `Serial numbers are not editable`
                          ) : (
                            `Drop your Excel sheet or select Click to browse`
                          )
                        ) : (
                          <PlaceHolder>{placeholder?.name}</PlaceHolder>
                        )
                      }
                      inputIndex={0}
                      // onChange={handleFileChange}
                      // file={file}
                      params={params}
                      handleFileChange={handleFileChange}
                    />
                  </div>{" "}
                  <div className="col-md-2">or</div>
                  <div className="col-md-5">
                    <div className="row">
                      <div className="col-md-6 py-5">
                        <FormControl fullWidth>
                          {/* <InputLabel id="from-label">From</InputLabel> */}
                          <TextField
                            id="from"
                            value={fromSerial}
                            sx={InputStyle}
                            label="From"
                            onChange={(e) => setFromSerial(e.target.value)}
                            name="from"
                            variant="outlined"
                            disabled
                            // disabled={params?.type === "Edit"}
                          />
                        </FormControl>
                      </div>
                      <div className="col-md-6 py-5">
                        <FormControl fullWidth>
                          {/* <InputLabel id="to-label">To</InputLabel> */}
                          <TextField
                            id="to"
                            type="number"
                            sx={InputStyle}
                            label="To"
                            value={toSerial}
                            // onBlur={handleError}
                            onChange={(e) => setToSerial(e.target.value)}
                            name="to"
                            variant="outlined"
                            // inputProps={{ min: parseInt(fromSerial + 1) }}
                            disabled={params?.type === "Edit"}
                            error={toSerialError}
                            helperText={toSerialError ? toSerialErrorMsg : ""}
                          />
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  {excelData.length > 0 ? (
                    <RenderSerialNumbersTable chunkedData={chunkedData} />
                  ) : (
                    <SerialNumbersTable serialNumbers={serialNumbers} />
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 buttons d-flex justify-content-end px-2">
                <SaveButton
                  type="button"
                  onClick={handleCancel}
                  className="mx-5 save-button"
                >
                  Cancel
                </SaveButton>
                <SubmitButton
                  type="submit"
                  className="submit-button"
                  disabled={IsSaving}
                >
                  {params.type == "Add" ? "Add Batch" : "Update Batch"}
                </SubmitButton>
                {/* <Button onClick={handleOpenModal} color="primary">
                  open modal
                </Button> */}
              </div>
            </div>
          </div>
        </form>
      </div>
      <Dialog open={openModal} onClose={handleCloseModal} fullWidth>
        {/* <DialogTitle>{"Your Modal Title"}</DialogTitle> */}
        <DialogContent>
          <DialogContentText>
            <strong>Subscription Limit Exceeded:</strong> You have reached the
            limit of your current subscription.
            <p>
              To add this batch, you need to subscribe to{" "}
              {/* Number of available products for subscription:{" "} */}
              {packageDetails?.balance || packageDetails?.length} more.
            </p>
            {packageDetails?.exist_serialno?.length > 0 ? (
              <>
                <h3>These serial numbers already exists</h3>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {packageDetails.exist_serialno?.map((serial, index) => (
                    <div>{serial},</div>
                  ))}
                </div>
              </>
            ) : (
              ""
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} style={{ color: "black" }}>
            Close
          </Button>
          <SubmitButton onClick={handleConfirm} color="primary">
            Renew
          </SubmitButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openSerialModal}
        onClose={() => setOpenSerialModal(false)}
        fullWidth
      >
        <DialogContent>
          <strong>Serial numbers already exists</strong>
          <DialogContentText>
            You have provided used serial numbers.Please Provide unique seirial
            numbers
            <div style={{ maxWidth: "100%", display: "flex" }}>
              {existingSerialNos.map((serial, i) => (
                <p>{serial},</p>
              ))}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Link to="/Batch-list"> */}
          <Button
            onClick={() => {
              handleCloseESNM();
              // props.clearData([]);
            }}
            style={{ color: "black" }}
          >
            Close
          </Button>
          {/* </Link> */}
        </DialogActions>
      </Dialog>
      <Dialog
        open={openResponseModal}
        onClose={() => setOpenResponseModal(false)}
        fullWidth
      >
        <DialogContent>
          <strong>Renew</strong>
          <DialogContentText>{response}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Link to="/Batch-list"> */}
          <Button
            onClick={() => {
              handleCloseRRM();
              // props.clearData([]);
            }}
            style={{ color: "black" }}
          >
            Close
          </Button>
          {/* </Link> */}
        </DialogActions>
      </Dialog>
    </div>
  );
};
const ImageInputSection = ({
  placeholder,
  inputIndex,
  file,
  params,
  handleFileChange,
}) => {
  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  return (
    <>
      <div className="col-md-12 px-2 d-flex justify-content-between">
        <TextField
          fullWidth
          value=""
          onClick={handleClick}
          sx={{ display: "none" }}
        />
        <TextLabel onClick={handleClick}>
          {/* {file && file[inputIndex]?.length > 0 ? (
            file[inputIndex]?.map((file, index) => (
              <ImageFile
                key={index}
                src={URL.createObjectURL(file)}
                alt={`Selected Image ${index + 1}`}
              />
            ))
        ) : ( */}
          <div className="d-column">
            <img src={excel} alt="" srcset="" />
            <div className="">{placeholder}</div>
          </div>
          {/* )} */}
        </TextLabel>
        <input
          type="file"
          accept=".xlsx, .xls"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={(e) => handleFileChange(e, inputIndex)}
          disabled={params?.type === "Edit"}
        />
      </div>
    </>
  );
};

const TextLabel = styled.div`
  position: relative;
  cursor: pointer;
  font-size: 12px;
  user-select: none;
  text-align: center;
  padding: 15px;
  color: #aaaaaa;
  border: 2px dotted rgb(206, 212, 218);
  border-radius: 11px;
  width: 245px;
  height: 135px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2em;
  flex-direction: column;
  .placeholder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
const ImageFile = styled.img`
  position: absolute;
  // top: 0;
  // left: 0;
  width: 169px;
  height: 135px;
  z-index: 1;
`;
const Divider = styled.div`
  width: 1px;
  // height: 100%;
  margin: 0 1em;
  background-color: #ccc; /* Line color */
`;
const NavStyled = styled(NavLink)`
  cursor: pointer;
`;
const TableContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow-x: auto;
  max-height: 28rem;
`;
const StyledTable = styled.table`
  width: 99%;
  border-collapse: collapse;
  margin: 10px 0;
  max-height: 5rem;
`;
const StyledTh = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
`;
const StyledTd = styled.td`
  border: 1px solid #ddd;
  // padding: 8px 15px;
  width: 33%;
  text-align: center;
`;
const PlaceHolder = styled.div`
  border: 1px solid #ddd;
  padding: 2px 8px;
  margin-top: 10px;
  width: max-content;
  /* width: 33%; */
  text-align: center;
  border-radius: 18px;
`;

export default AddBatch;
