import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { tryfetchNotificationsList } from "../../Slices/HeaderSlice";

const Dashboard = () => {
  const dispatch = useDispatch();
  const [state, setstate] = useState(false);

  useEffect(() => {
    dispatch(tryfetchNotificationsList());
  }, []);
  return (
    <div className="page">
      <div className="row">
        <HeadTitle className="col-md-12">
          <span>Dashboard</span>
        </HeadTitle>
      </div>
      <div className="page-inner-content">
        <Title onClick={() => setstate(!state)} $state={state}>
          {/* Dashboard xyz */}
        </Title>
      </div>
    </div>
  );
};
const HeadTitle = styled.div`
  span {
    font-size: 20px;
    // font-weight: 600;
  }
`;
const Title = styled.span`
  font-weight: 600;
  // background: red;
`;
export default Dashboard;
