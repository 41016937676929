import { createSlice } from "@reduxjs/toolkit";

const InitState = {
  isLoading: false,
  productTableData: [],
  productDetailByID: [],
  productType: [],
};

const ProductSlice = createSlice({
  name: "Product",
  initialState: InitState,
  reducers: {
    tryfetchProductsList: (state) => {
      state.isLoading = true;
    },
    setProductsListData: (state, action) => {
      state.isLoading = false;
      state.productTableData = action.payload;
    },
    tryfetchProductsFilteredList: (state) => {
      state.isLoading = true;
    },
    setProductsFilteredListData: (state, action) => {
      state.isLoading = false;
      state.productTableData = action.payload;
    },
    tryfetchProductsDetailByID: (state) => {
      state.isLoading = true;
    },
    setProductsDetailByID: (state, action) => {
      state.isLoading = false;
      state.productDetailByID = action.payload;
    },
    tryfetchProductType: (state) => {
      state.isLoading = true;
    },
    setProductType: (state, action) => {
      state.isLoading = false;
      state.productType = action.payload;
    },
    clearProductsDetailsByID: (state) => {
      state.isLoading = false;
      state.productDetailByID = [];
      state.productType = [];
    },
  },
});

export const {
  tryfetchProductsList,
  setProductsListData,
  tryfetchProductsDetailByID,
  setProductsDetailByID,
  clearProductsDetailsByID,
  tryfetchProductType,
  setProductType,
  tryfetchProductsFilteredList,
  setProductsFilteredListData,
} = ProductSlice.actions;

export default ProductSlice.reducer;
