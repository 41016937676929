import React, { useState } from "react";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
} from "@mui/material";
import styled from "styled-components";
import { useRef } from "react";
import {
  SaveButton,
  SubmitButton,
} from "../../Components/StyledComponents/Buttons";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import excel from "../../assets/Images/excel.png";
import axios from "axios";
import {
  OEM_PROFILE_EDIT,
  OEM_REGISTERATION,
  PRODUC_TYPE,
  SETTINGS_TYPE,
} from "../../config/BaseUrl";
import { apiPost } from "../../config/apiConfig";
import { useEffect } from "react";
import {
  HeadTitle,
  InputStyle,
  selectStyle,
} from "../../Components/StyledComponents/Common";
import { clearProductsDetailsByID } from "../../Slices/ProductSlice";
import { useDispatch, useSelector } from "react-redux";
import { AiFillCloseCircle } from "react-icons/ai";
import {
  tryfetchOEMDetailByID,
  tryfetchOEMList,
  tryfetchOEMProfileByID,
} from "../../Slices/OEMSlice";
import { HiUser } from "react-icons/hi";
import DeleteIcon from "@mui/icons-material/Delete";

const AddOEM = () => {
  const Data = useSelector((state) => state.OEM?.OEMDetailByID);
  const dispatch = useDispatch();
  const roleName = localStorage.getItem("roleName");
  const params = useParams();
  const navigate = useNavigate();
  const [IsSaving, setIsSaving] = useState(false);
  const [warranty, setWarranty] = useState([]);
  const [productType, setProductType] = useState([]);
  const [error, setError] = useState({
    mobile: false,
    email: false,
    website: false,
    contact_no0: false,
    contact_no1: false,
    contact_no2: false,
    contact_email0: false,
    contact_email1: false,
    contact_email2: false,
  });
  const [values, setValues] = useState({
    partner_id: "",
    oem_name: "",
    display_name: "",
    mobile: "",
    landline: "",
    email: "",
    website: "",
    fax: "",
    gst_treatment: "",
    gst_number: "",
    pan: "",
    shipping_address: "",
    billing_address: "",
    product_category: "",
    type_of_sale: "",
    currency: "",
    payment_terms: "",
    tds: "",
    contact_person: [
      { designation: "", name: "", email: "", contact_no: "" },
      { designation: "", name: "", email: "", contact_no: "" },
      { designation: "", name: "", email: "", contact_no: "" },
    ],
  });
  function isUrlValid(userInput) {
    var regexQuery =
      /^(http(s)?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
    var url = new RegExp(regexQuery);
    if (url.test(userInput)) {
      // alert("Great, you entered a valid URL");
      return true;
    }
    // alert("Invalid URL");
    return false;
  }
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
  const handleInputChange = (e) => {
    if (e.target.name == "mobile") {
      if (e.target.value.length !== 10) {
        setError({ ...error, mobile: true });
      } else {
        setError({ ...error, mobile: false });
      }
    }
    if (e.target.name == "email") {
      if (!emailRegex.test(e.target.value)) {
        setError({ ...error, email: true });
      } else {
        setError({ ...error, email: false });
      }
    }
    if (e.target.name === "website") {
      if (!isUrlValid(e.target.value)) {
        setError({ ...error, website: true });
      } else {
        setError({ ...error, website: false });
      }
    }
    // if (e.target.name == "website") {
    //   if (!urlRegex.test(e.target.value)) {
    //     setError({ ...error, website: true });
    //   } else {
    //     setError({ ...error, website: false });
    //   }
    // }

    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleGSTChange = (e) => {
    const uppercaseValue = e.target.value.toUpperCase();
    setValues({ ...values, gst_number: uppercaseValue });
  };

  const handleContactChange = (event, index) => {
    const { name, value } = event.target;
    if (name === "contact_no") {
      const isInvalidLength = value.length !== 10;
      setError((prevError) => ({
        ...prevError,
        [`contact_no${index}`]: isInvalidLength,
      }));
    }
    if (name === "email") {
      const isInvalidEmail = !emailRegex.test(value);
      setError((prevError) => ({
        ...prevError,
        [`contact_email${index}`]: isInvalidEmail,
      }));
    }
    setValues((prevValues) => {
      const newValues = { ...prevValues };
      newValues.contact_person = newValues.contact_person || [];
      newValues.contact_person[index] = newValues.contact_person[index] || {};
      newValues.contact_person[index][name] = value;
      return newValues;
    });
  };

  const addBatch = async (e) => {
    setIsSaving(true);
    try {
      var token = localStorage.getItem("token");
      var bodyFormData = new FormData();
      bodyFormData.append("token", token);
      // bodyFormData.append("partner_id", values.partner_id);
      bodyFormData.append("oem_name", values.oem_name);
      bodyFormData.append("display_name", values.display_name);
      bodyFormData.append("mobile", values.mobile);
      bodyFormData.append("landline", values.landline);
      bodyFormData.append("email", values.email);
      bodyFormData.append("website", values.website);
      bodyFormData.append("fax", values.fax);
      bodyFormData.append("shipping_address", values.shipping_address);
      bodyFormData.append("billing_address", values.billing_address);
      bodyFormData.append("gst_treatment", values.gst_treatment);
      bodyFormData.append("gst_number", values.gst_number);
      bodyFormData.append("pan", values.pan);
      bodyFormData.append("image", image);
      bodyFormData.append("product_category", values.product_category);
      bodyFormData.append("type_of_sale", values.type_of_sale);
      bodyFormData.append("currency", values.currency);
      bodyFormData.append("payment_terms", values.payment_terms);
      bodyFormData.append("tds", values.tds);
      values.contact_person?.forEach((person, index) => {
        bodyFormData.append(
          `contact_person[${index}][designation]`,
          person.designation
        );
        bodyFormData.append(`contact_person[${index}][name]`, person.name);
        bodyFormData.append(`contact_person[${index}][email]`, person.email);
        bodyFormData.append(
          `contact_person[${index}][contact_no]`,
          person.contact_no
        );
      });
      let resp = await apiPost(OEM_REGISTERATION, bodyFormData);
      console.log("file values is asd", resp);
      if (resp.response.data.status === true) {
        toast.success(resp.response.data.message);
        setTimeout(() => {
          dispatch(tryfetchOEMList());
          navigate("/OEM-List");
          setIsSaving(false);
          setValues({
            partner_id: "",
            oem_name: "",
            display_name: "",
            mobile: "",
            landline: "",
            email: "",
            website: "",
            fax: "",
            gst_treatment: "",
            gst_number: "",
            pan: "",
            shipping_address: "",
            billing_address: "",
            product_category: "",
            type_of_sale: "",
            currency: "",
            payment_terms: "",
            tds: "",
            contact_person: [
              { designation: "", name: "", email: "", contact_no: "" },
              { designation: "", name: "", email: "", contact_no: "" },
              { designation: "", name: "", email: "", contact_no: "" },
            ],
          });
        }, 2000);
      } else {
        setIsSaving(false);
        toast.error(resp.response.data.message);
      }
    } catch (error) {
      setIsSaving(false);
      toast.error(error.message);
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  };
  const updateBatch = async (e) => {
    setIsSaving(true);
    try {
      var token = localStorage.getItem("token");
      var userId = localStorage.getItem("userId");
      var bodyFormData = new FormData();
      bodyFormData.append("token", token);
      bodyFormData.append("id", values.id);
      // bodyFormData.append("partner_id", values.partner_id);
      bodyFormData.append("oem_name", values.oem_name);
      bodyFormData.append("display_name", values.display_name);
      bodyFormData.append("mobile", values.mobile);
      bodyFormData.append("landline", values.landline);
      bodyFormData.append("email", values.email);
      bodyFormData.append("website", values.website);
      bodyFormData.append("shipping_address", values.shipping_address);
      bodyFormData.append("billing_address", values.billing_address);
      bodyFormData.append("fax", values.fax);
      bodyFormData.append("gst_treatment", values.gst_treatment);
      bodyFormData.append("gst_number", values.gst_number);
      bodyFormData.append("pan", values.pan);
      bodyFormData.append("file", image);
      bodyFormData.append("product_category", values.product_category);
      bodyFormData.append("type_of_sale", values.type_of_sale);
      bodyFormData.append("currency", values.currency);
      bodyFormData.append("payment_terms", values.payment_terms);
      bodyFormData.append("tds", values.tds);
      values.contact_person?.forEach((person, index) => {
        bodyFormData.append(
          `contact_person[${index}][designation]`,
          person.designation ? person.designation : ""
        );
        // if (person.id) {
        bodyFormData.append(
          `contact_person[${index}][id]`,
          person.id ? person.id : ""
        );
        // }
        bodyFormData.append(
          `contact_person[${index}][name]`,
          person.name ? person.name : ""
        );
        bodyFormData.append(
          `contact_person[${index}][email]`,
          person.email ? person.email : ""
        );
        bodyFormData.append(
          `contact_person[${index}][contact_no]`,
          person.contact_no ? person.contact_no : ""
        );
      });
      let resp = await apiPost(OEM_PROFILE_EDIT, bodyFormData);
      console.log(" oem resp is asd", resp);
      if (resp.response.data.status === true) {
        toast.success(resp?.response?.data?.message);
        setTimeout(() => {
          dispatch(tryfetchOEMList());
          if (roleName !== "admin") {
            if (userId == values.id) {
              dispatch(tryfetchOEMProfileByID(values.id));
            }
            navigate("/");
          } else {
            navigate("/OEM-List");
          }
          setIsSaving(false);
          setValues({
            partner_id: "",
            oem_name: "",
            display_name: "",
            mobile: "",
            landline: "",
            email: "",
            website: "",
            fax: "",
            gst_treatment: "",
            gst_number: "",
            image: "",
            pan: "",
            shipping_address: "",
            billing_address: "",
            product_category: "",
            type_of_sale: "",
            currency: "",
            payment_terms: "",
            tds: "",
            contact_person: [
              { designation: "", name: "", email: "", contact_no: "" },
              { designation: "", name: "", email: "", contact_no: "" },
              { designation: "", name: "", email: "", contact_no: "" },
            ],
          });
        }, 2000);
      } else {
        setIsSaving(false);
        toast.error(resp.response.data.message);
      }
    } catch (error) {
      setIsSaving(false);
      toast.error(error.message);
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    // Perform validation for all fields
    const errors = {
      mobile: values.mobile.length !== 10,
      email: !emailRegex.test(values.email),
      website: !isUrlValid(values.website),
      contact_no0: values.contact_person[0].contact_no.length !== 10,
      // contact_no1: values.contact_person[1].contact_no.length !== 10,
      // contact_no2: values.contact_person[2].contact_no.length !== 10,
      contact_email0: !emailRegex.test(values.contact_person[0].email),
      // contact_email1: !emailRegex.test(values.contact_person[1].email),
      // contact_email2: !emailRegex.test(values.contact_person[2].email),
    };

    setError(errors);

    // Check if there are any errors
    const hasErrors = Object.values(errors).some((error) => error);

    if (!hasErrors) {
      if (params.type === "Add") {
        addBatch();
      } else {
        updateBatch();
      }
    } else {
      toast.error("Please fix the errors before submitting.");
    }
  };
  const handleCancel = () => {
    navigate(-1);
  };

  async function getProductType() {
    var token = localStorage.getItem("token");
    try {
      var bodyFormData = new FormData();
      bodyFormData.append("token", token);
      let resp = await apiPost(PRODUC_TYPE, bodyFormData);
      // console.log("PRODUC_TYPE", resp);
      if (resp?.response?.status === 200) {
        setProductType(resp?.response?.data?.products_type);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.message;
      } else {
        console.log("unexpected error: ", error);
      }
    }
  }
  const productTypes = productType?.map((item) => {
    // console.log("tyep", item);
    return {
      label: item.product_type_name,
      value: item.id,
    };
  });
  async function getGst() {
    var token = localStorage.getItem("token");
    try {
      var bodyFormData = new FormData();
      bodyFormData.append("token", token);
      bodyFormData.append("type", "gst");
      let resp = await apiPost(SETTINGS_TYPE, bodyFormData);
      console.log("gst", resp);
      if (resp.response.status === 200) {
        setWarranty(resp.response.data.Details);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.message;
      } else {
        console.log("unexpected error: ", error);
        // return yield put(setUserListData());
      }
    }
  }
  const warrantyOptions = warranty?.map((item) => {
    // console.log("warranty_package_name", item);
    return {
      label: item.name,
      value: item.id,
    };
  });

  useEffect(() => {
    getGst();
    if (params?.type == "Add") {
      setValues({
        partner_id: "",
        oem_name: "",
        display_name: "",
        mobile: "",
        landline: "",
        email: "",
        website: "",
        shipping_address: "",
        billing_address: "",
        fax: "",
        gst_treatment: "",
        gst_number: "",
        pan: "",
        product_category: "",
        type_of_sale: "",
        currency: "",
        payment_terms: "",
        tds: "",
        contact_person: [
          { designation: "", name: "", email: "", contact_no: "" },
          { designation: "", name: "", email: "", contact_no: "" },
          { designation: "", name: "", email: "", contact_no: "" },
        ],
      });
    }
    if (params?.type === "Edit") {
      dispatch(tryfetchOEMDetailByID(params.id));
    }
  }, [params]);

  useEffect(() => {
    if (Data && params.type === "Edit") {
      setValues(Data);
      setValues({
        ...Data,
        contact_person: [
          {
            id: Data?.contact_peron?.[0]?.id,
            designation: Data?.contact_peron?.[0]?.designation,
            name: Data?.contact_peron?.[0]?.name,
            email: Data?.contact_peron?.[0]?.email,
            contact_no: Data?.contact_peron?.[0]?.contact_no,
          },
          {
            id: Data?.contact_peron?.[1]?.id,
            designation: Data?.contact_peron?.[1]?.designation,
            name: Data?.contact_peron?.[1]?.name,
            email: Data?.contact_peron?.[1]?.email,
            contact_no: Data?.contact_peron?.[1]?.contact_no,
          },
          {
            id: Data?.contact_peron?.[2]?.id,
            designation: Data?.contact_peron?.[2]?.designation,
            name: Data?.contact_peron?.[2]?.name,
            email: Data?.contact_peron?.[2]?.email,
            contact_no: Data?.contact_peron?.[2]?.contact_no,
          },
        ],
      });
    }
  }, [Data]);
  useEffect(() => {
    return () => {
      dispatch(clearProductsDetailsByID());
    };
  }, []);
  useEffect(() => {
    return () => {
      setValues({
        partner_id: "",
        oem_name: "",
        display_name: "",
        mobile: "",
        landline: "",
        email: "",
        website: "",
        fax: "",
        gst_treatment: "",
        gst_number: "",
        pan: "",
        product_category: "",
        type_of_sale: "",
        currency: "",
        payment_terms: "",
        tds: "",
        contact_person: [
          { designation: "", name: "", email: "", contact_no: "" },
          { designation: "", name: "", email: "", contact_no: "" },
          { designation: "", name: "", email: "", contact_no: "" },
        ],
      });
    };
  }, []);

  const handleKeyPress = (event) => {
    const keyCode = event.which || event.keyCode;
    const isNumber = keyCode >= 48 && keyCode <= 57;

    if (isNumber) {
      event.preventDefault();
    }
  };
  const [image, setimage] = useState(null);

  const handleUpload = (event) => {
    setValues({ ...values, image: event.target.files[0] });
    setimage(event.target.files[0]);
    console.log("image", event.target.files[0]);
  };
  const handleRemoveImage = () => {
    setValues({ ...values, image: null });
    setimage(null);
  };

  useEffect(() => {
    console.log("image", image);
  }, [image]);

  return (
    <div className="page">
      <ToastContainer />
      <div className="page-inner-content">
        <div className="row">
          <HeadTitle className="col-md-12">
            {/* <span> */}
            {params.type === "Add"
              ? "Registration Details"
              : "Edit Profile Details"}
            {/* </span> */}
          </HeadTitle>
        </div>
        <form action="" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-8">
                  <Title className="row mb-5">Basic Details</Title>
                  <div className="row">
                    {roleName === "oem" ? (
                      <div className="col-md-6 px-5">
                        <Txtfld
                          label="OEM ID"
                          variant="outlined"
                          // fullWidth
                          name="id"
                          className="muiinputField mb-5"
                          required
                          sx={InputStyle}
                          disabled
                          value={values?.id || " "}
                          onChange={handleInputChange}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="col-md-6 px-5 mb-5">
                      <Txtfld
                        label="OEM Display Name"
                        variant="outlined"
                        // fullWidth
                        sx={InputStyle}
                        name="display_name"
                        className="muiinputField"
                        required
                        value={values.display_name}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                {roleName !== "admin" && (
                  <UploadContainer className="col-md-4">
                    <FormControl className="upload-box">
                      <input
                        className="input-file"
                        type="file"
                        name="image"
                        onChange={handleUpload}
                        accept=".jpg, .jpeg, .png"
                      />
                      <div className="box-text">
                        {values?.image || image ? (
                          <>
                            <img
                              src={
                                image
                                  ? URL.createObjectURL(image)
                                  : values.image
                              }
                              alt=""
                            />
                            <IconButton
                              className="button-remove"
                              onClick={handleRemoveImage}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </>
                        ) : (
                          <HiUser size={150} fill="#ecf9fe" />
                        )}
                      </div>
                    </FormControl>
                  </UploadContainer>
                )}
              </div>
              <div className="row ">
                <div className="col-md-4 px-5 mb-5">
                  <Txtfld
                    label="OEM Name"
                    variant="outlined"
                    // fullWidth
                    name="oem_name"
                    className="muiinputField"
                    required
                    onKeyPress={handleKeyPress}
                    sx={InputStyle}
                    value={values.oem_name}
                    onChange={handleInputChange}
                  />
                </div>
                {/* </div>
              <div className="row mb-5"> */}
                <div className="col-md-4 px-5 mb-5">
                  <Txtfld
                    label="Mobile No."
                    variant="outlined"
                    required
                    type="number"
                    inputProps={{ maxLength: 10 }}
                    sx={InputStyle}
                    name="mobile"
                    value={values.mobile}
                    onChange={handleInputChange}
                    error={error.mobile}
                    helperText={error.mobile ? "10 digits required" : ""}
                  />
                </div>
                <div className="col-md-4 px-5">
                  <Txtfld
                    label="Landline No."
                    variant="outlined"
                    className="mb-5"
                    type="number"
                    // multiline
                    // minRows="3"
                    sx={InputStyle}
                    name="landline"
                    value={values.landline}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-4 px-5">
                  <Txtfld
                    label="Fax No."
                    variant="outlined"
                    className="mb-5"
                    required
                    // multiline
                    // minRows="3"
                    sx={InputStyle}
                    name="fax"
                    value={values.fax}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-4 px-5">
                  <Txtfld
                    label="Email Id"
                    variant="outlined"
                    className="mb-5"
                    required
                    type="email"
                    // multiline
                    // minRows="3"
                    sx={InputStyle}
                    name="email"
                    value={values.email}
                    onChange={handleInputChange}
                    error={error.email}
                    helperText={error.email ? "Invalid email id" : ""}
                  />
                </div>
                {/* </div>
              <div className="row mb-5"> */}
                <div className="col-md-4 px-5">
                  <Txtfld
                    label="Website"
                    variant="outlined"
                    className="mb-5"
                    // type="url"
                    required
                    // multiline
                    // minRows="3"
                    sx={InputStyle}
                    name="website"
                    value={values.website}
                    onChange={handleInputChange}
                    error={error.website}
                    helperText={error.website ? "Invalid web address" : ""}
                  />
                </div>
                <div className="col-md-4 px-5">
                  <Txtfld
                    label="PAN"
                    variant="outlined"
                    className="mb-5"
                    required
                    // multiline
                    // minRows="3"
                    sx={InputStyle}
                    disabled={params.type === "Edit"}
                    name="pan"
                    value={values.pan}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-4 px-5">
                  <Txtfld
                    label="GST"
                    variant="outlined"
                    className="mb-5"
                    // required
                    // multiline
                    // minRows="3"
                    // type="text"
                    disabled={params.type === "Edit"}
                    sx={InputStyle}
                    name="gst_number"
                    value={values.gst_number}
                    onChange={handleGSTChange}
                  />
                </div>
                {/* </div> */}
                {/* <Title className="row mb-3">Additional information</Title> */}

                {/* 
              <div className="row mb-5">
                <div className="col-md-4 px-5">
                  <Txtfld
                    label="TDS"
                    variant="outlined"
                    required
                    // multiline
                    // minRows="3"
                    sx={InputStyle}
                    name="tds"
                    value={values.tds}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="col-md-4 px-5">
                  <Txtfld
                    label="Type Of Sales OEM"
                    variant="outlined"
                    required
                    // multiline
                    // minRows="3"
                    sx={InputStyle}
                    name="type_of_sale"
                    value={values.type_of_sale}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="col-md-4 px-5">
                  <Txtfld
                    label="Currency"
                    variant="outlined"
                    required
                    // multiline
                    // minRows="3"
                    sx={InputStyle}
                    name="currency"
                    value={values.currency}
                    onChange={handleInputChange}
                  />
                </div> 
              </div>
                */}
                {/* <div className="row mb-5"> */}
                {/* <div className="col-md-4 px-5">
                  <Txtfld
                    label="Payment Terms"
                    variant="outlined"
                    required
                    // multiline
                    // minRows="3"
                    sx={InputStyle}
                    name="payment_terms"
                    value={values.payment_terms}
                    onChange={handleInputChange}
                  />
                </div> */}
                <div className="col-md-4 px-5">
                  {/* <FormControl style={{ width: "300px" }}>
                    <InputLabel id="">GST Type</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="GSTType"
                      variant="outlined"
                      className="mb-5"
                      // disabled={params.type === "Edit"}
                      required
                      // multiline
                      // minRows="3"
                      sx={selectStyle}
                      name="gst_treatment"
                      value={parseInt(values.gst_treatment)}
                      onChange={handleInputChange}
                    >
                      {warrantyOptions?.map((type) => (
                        <MenuItem key={type.value} value={type.value}>
                          {type.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}

                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      GST Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="GSTType"
                      variant="outlined"
                      className="mb-5"
                      // disabled={params.type === "Edit"}
                      required
                      // multiline
                      // minRows="3"
                      sx={selectStyle}
                      name="gst_treatment"
                      value={values.gst_treatment}
                      onChange={handleInputChange}
                    >
                      {warrantyOptions?.map((type) => (
                        <MenuItem key={type.value} value={type.value}>
                          {type.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
            {/* <Divider style={{ padding: "0" }} /> */}
            <Title className="row mb-3">Addresses</Title>
            <div className="row mb-5">
              <div className="col-md-4 px-5">
                <Txtfld
                  label="Billing Address"
                  variant="outlined"
                  required
                  multiline
                  minRows="4"
                  sx={InputStyle}
                  name="billing_address"
                  value={values.billing_address}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-4 px-5">
                <Txtfld
                  label="Shipping Address"
                  variant="outlined"
                  required
                  multiline
                  minRows="4"
                  sx={InputStyle}
                  name="shipping_address"
                  value={values.shipping_address}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <Title className="row mb-3">Contact Person</Title>
            <div className="row mb-5">
              <div className="col-md-4 px-5">
                <PersonHead className="row  py-2">
                  <span> Person 1</span>
                </PersonHead>
                <div className="py-4">
                  <Txtfld
                    label="Designation"
                    variant="outlined"
                    required
                    // className="py-4"
                    // multiline
                    // minRows="4"
                    sx={InputStyle}
                    name="designation"
                    value={values?.contact_person?.[0]?.designation}
                    onChange={(e) => handleContactChange(e, 0)}
                  />
                </div>
                <div className="py-4">
                  <Txtfld
                    label="Name"
                    variant="outlined"
                    required
                    // className="py-4"
                    // multiline
                    // minRows="4"
                    sx={InputStyle}
                    name="name"
                    value={values?.contact_person?.[0]?.name}
                    onChange={(e) => handleContactChange(e, 0)}
                  />
                </div>
                <div className="py-4">
                  <Txtfld
                    label="Email"
                    variant="outlined"
                    required
                    type="email"
                    // className="py-4"
                    // multiline
                    // minRows="4"
                    sx={InputStyle}
                    name="email"
                    error={error.contact_email0}
                    helperText={error.contact_email0 ? "Invalid email id" : ""}
                    value={values?.contact_person?.[0]?.email}
                    onChange={(e) => handleContactChange(e, 0)}
                  />
                </div>
                <div className="py-4">
                  <Txtfld
                    label="Contact No."
                    variant="outlined"
                    type="number"
                    required
                    // className="py-4"
                    // multiline
                    // minRows="4"
                    sx={InputStyle}
                    name="contact_no"
                    value={values?.contact_person?.[0].contact_no}
                    onChange={(e) => handleContactChange(e, 0)}
                    error={error.contact_no0}
                    helperText={error.contact_no0 ? "10 digits required" : ""}
                  />
                </div>
                <div className="py-4"></div>
              </div>
              <div className="col-md-4 px-5">
                <PersonHead className="row  py-2">
                  <span> Person 2</span>
                </PersonHead>
                <div className="py-4">
                  <Txtfld
                    label="Designation"
                    variant="outlined"
                    // required
                    // className="py-4"
                    // multiline
                    // minRows="4"
                    sx={InputStyle}
                    name="designation"
                    value={values?.contact_person?.[1]?.designation}
                    onChange={(e) => handleContactChange(e, 1)}
                  />
                </div>
                <div className="py-4">
                  <Txtfld
                    label="Name"
                    variant="outlined"
                    // required
                    // className="py-4"
                    // multiline
                    // minRows="4"
                    name="name"
                    sx={InputStyle}
                    value={values?.contact_person?.[1]?.name}
                    onChange={(e) => handleContactChange(e, 1)}
                  />
                </div>
                <div className="py-4">
                  <Txtfld
                    label="Email"
                    variant="outlined"
                    // required
                    type="email"
                    // className="py-4"
                    // multiline
                    // minRows="4"
                    sx={InputStyle}
                    name="email"
                    error={error.contact_email1}
                    helperText={error.contact_email1 ? "Invalid email id" : ""}
                    value={values?.contact_person?.[1]?.email}
                    onChange={(e) => handleContactChange(e, 1)}
                  />
                </div>
                <div className="py-4">
                  <Txtfld
                    label="Contact No."
                    variant="outlined"
                    type="number"
                    // required
                    // className="py-4"
                    // multiline
                    // minRows="4"
                    sx={InputStyle}
                    name="contact_no"
                    value={values?.contact_person?.[1]?.contact_no}
                    onChange={(e) => handleContactChange(e, 1)}
                    error={error.contact_no1}
                    helperText={error.contact_no1 ? "10 digits required" : ""}
                  />
                </div>
              </div>
              <div className="col-md-4 px-5">
                <PersonHead className="row  py-2">
                  <span> Person 3</span>
                </PersonHead>
                <div className="py-4">
                  <Txtfld
                    label="Designation"
                    variant="outlined"
                    // required
                    // className="py-4"
                    // multiline
                    // minRows="4"
                    sx={InputStyle}
                    name="designation"
                    value={values?.contact_person?.[2]?.designation}
                    onChange={(e) => handleContactChange(e, 2)}
                  />
                </div>
                <div className="py-4">
                  <Txtfld
                    label="Name"
                    variant="outlined"
                    // required
                    // className="py-4"
                    // multiline
                    // minRows="4"
                    sx={InputStyle}
                    name="name"
                    value={values?.contact_person?.[2]?.name}
                    onChange={(e) => handleContactChange(e, 2)}
                  />
                </div>
                <div className="py-4">
                  <Txtfld
                    label="Email"
                    variant="outlined"
                    // required
                    type="email"
                    // className="py-4"
                    // multiline
                    // minRows="4"
                    sx={InputStyle}
                    name="email"
                    error={error.contact_email2}
                    helperText={error.contact_email2 ? "Invalid email id" : ""}
                    value={values?.contact_person?.[2]?.email}
                    onChange={(e) => handleContactChange(e, 2)}
                  />
                </div>
                <div className="py-4">
                  <Txtfld
                    label="Contact No."
                    variant="outlined"
                    type="number"
                    // required
                    // className="py-4"
                    // multiline
                    // minRows="4"
                    sx={InputStyle}
                    name="contact_no"
                    value={values?.contact_person?.[2]?.contact_no}
                    onChange={(e) => handleContactChange(e, 2)}
                    error={error.contact_no2}
                    helperText={error.contact_no2 ? "10 digits required" : ""}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 buttons d-flex justify-content-center px-5">
                <SaveButton
                  type="button"
                  onClick={handleCancel}
                  className="mx-5 save-button"
                >
                  Cancel
                </SaveButton>
                <SubmitButton
                  type="submit"
                  className="submit-button"
                  disabled={IsSaving}
                >
                  {params.type == "Add" ? "Register" : "Update"}
                </SubmitButton>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
const ImageInputSection = ({
  placeholder,
  inputIndex,
  file,
  handleFileChange,
}) => {
  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  return (
    <>
      <div className="col-md-12 px-5 d-flex justify-content-between">
        <TextField
          fullWidth
          value=""
          onClick={handleClick}
          sx={{ display: "none" }}
        />
        <TextLabel onClick={handleClick}>
          {/* {file && file[inputIndex]?.length > 0 ? (
            file[inputIndex]?.map((file, index) => (
              <ImageFile
                key={index}
                src={URL.createObjectURL(file)}
                alt={`Selected Image ${index + 1}`}
              />
            ))
        ) : ( */}
          <img src={excel} alt="" srcset="" />
          <div className="">{placeholder}</div>
          {/* )} */}
        </TextLabel>
        <input
          type="file"
          accept=".xlsx, .xls"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={(e) => handleFileChange(e, inputIndex)}
        />
      </div>
    </>
  );
};
const TextLabel = styled.div`
  position: relative;
  cursor: pointer;
  font-size: 12px;
  user-select: none;
  text-align: center;
  padding: 15px;
  color: #aaaaaa;
  border: 2px dotted rgb(206, 212, 218);
  border-radius: 4px;
  width: 169px;
  height: 135px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2em;
  flex-direction: column;
  .placeholder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
const Txtfld = styled(TextField)`
  // cursor: pointer;
  border-radius: 21px !important;
  width: 300px;
`;
const Title = styled.div`
  font-weight: 500;
  font-size: 16px;
`;
const PersonHead = styled.div`
  font-weight: 500;

  color: #515151b2;
  font-size: 14px;
`;
const UploadContainer = styled.div`
  display: flex;
  margin-top: -4.5rem;
  justify-content: center;
  .upload-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15rem;
    height: 15rem;
    background-color: #ffffff;
    border-radius: 22px;
    cursor: pointer;
    object-fit: contain;
    border: 1px dotted #bfbfbf;
    @media only screen and (max-width: 1200px) {
      width: 50%;
      height: 50%;
      margin-bottom: 1rem;
    }
    object-fit: contain;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 22px;
    }
    input {
      position: absolute;
      width: 15rem;
      height: 15rem;
      opacity: 0;
      background-color: #b6e8fb;
      cursor: pointer;
    }
  }
  .upload-box:hover {
    border-color: #666;
  }
  .box-text {
    font-size: 16px;
    /* text-align: center; */
    color: #666;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .upload-icon-div {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #898989;
    cursor: pointer;
  }
  .button-remove {
    position: absolute;
    top: 5px;
    right: 5px;
    border: none;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.7);
    border: none;
    color: #ff0000;
    cursor: pointer;
  }
`;

export default AddOEM;
