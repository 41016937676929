import React, { useEffect, useRef, useState } from "react";
import { FormControl } from "@mui/material";
import styled from "styled-components";
import {
  FilterButton,
  FilterButtonCancel,
  SaveButton,
  SubmitButton,
} from "../../Components/StyledComponents/Buttons";
import Select from "react-select";

const FilterBox = styled.div`
  right: 4rem;
  width: 50rem;
  top: 16rem;
  border: solid 1px #cbc2c2;
  background-color: #ffffff;
  padding: 1em 2em;
  box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.25);
  position: absolute;
  z-index: 9999;
  border-radius: 6px;
`;

const FilterDropdown = ({ onSave, onClose, warranty }) => {
  // Define your filter options and state to manage selected options
  const [selectedOption, setSelectedOption] = useState("");
  const filterBoxRef = useRef(null);
  const handleClickOutside = (event) => {
    if (filterBoxRef.current && !filterBoxRef.current.contains(event.target)) {
      onClose();
    }
  };
  const warrantyOptions = warranty?.map((item) => {
    // console.log("warranty_package_name", item);
    return {
      label: item.name,
      value: item.id,
    };
  });
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [filterBoxRef, onClose]);
  const filterData = () => {
    // console.log("filterData");
    var data = {
      selectedOption,
      // selectedUnitType,
    };
    // dispatch(tryfetchFilteredBatchList(data));
    onSave();
  };
  return (
    <FilterBox className="filter-dropdown" ref={filterBoxRef}>
      <h4>Filter Options</h4>
      <div className="d-flex row py-3 ">
        <div className="col-md-6 px-3">
          {/* <select
          value={selectedOption}
          onChange={(e) => setSelectedOption(e.target.value)}
        >
          <option value="option1">Option 1</option>
          <option value="option2">Option 2</option>
        </select> */}
          <FormControl fullWidth>
            {/* <InputLabel id="">GST Treatment</InputLabel> */}
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="GSTTreatment"
              variant="outlined"
              required
              options={warrantyOptions}
              // multiline
              // minRows="3"
              name="gst_treatment"
              value={selectedOption.value}
              onChange={(e) => setSelectedOption(e.value)}
            />
          </FormControl>
        </div>
      </div>

      {/* <div>
        <button onClick={onSave}>Save</button>
        <button onClick={onSave}}>Close</button>
      </div> */}
      {/* <div className="row" style={{ position: "relative" }}>
        <div className="col-md-12 buttons d-flex justify-content-end px-5 py-3 ">
          <SaveButton
            type="button"
            onClick={onSave}
            className="mx-5 save-button"
          >
            Cancel
          </SaveButton>
          <SubmitButton
            type="submit"
            className="submit-button"
            style={{ whiteSpace: "nowrap" }}
            onClick={filterData}
          >
            Filter Data
          </SubmitButton>
        </div>
      </div> */}
      <div className="row px-2">
        <div className="col-md-6 buttons d-flex justify-content-end ">
          <FilterButtonCancel type="button" onClick={onSave}>
            Cancel
          </FilterButtonCancel>
        </div>
        <div className="col-md-6 buttons d-flex justify-content-end">
          <FilterButton
            type="submit"
            style={{ whiteSpace: "nowrap" }}
            onClick={filterData}
          >
            Filter Data
          </FilterButton>
        </div>
      </div>
    </FilterBox>
  );
};
export default FilterDropdown;
