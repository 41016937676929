import { put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { apiPost } from "../config/apiConfig.js";
import {
  SALES_PARTNERS_BY_ID,
  SALES_PARTNERS_LIST,
  SERVICE_CENTER_LIST,
  SUPPLIER_LIST,
} from "../config/BaseUrl.js";
import {
  setSalesPartnerDetailByID,
  setSalesPartnersListData,
  setServiceCentersListData,
  setSupplierListData,
  tryfetchSalesPartnerDetailByID,
  tryfetchSalesPartnersList,
  tryfetchServiceCentersList,
  tryfetchSupplierList,
} from "../Slices/VendorSlice.jsx";

function* fetchSalesParntersListData() {
  // console.log("PRODUCT_LIST started");
  let token = localStorage.getItem("token");

  try {
    var bodyFormData = new FormData();
    bodyFormData.append("token", token);
    let resp = yield apiPost(SALES_PARTNERS_LIST, bodyFormData);
    console.log("SALES_PARTNERS_LIST", resp);
    if (resp.response.status === 200) {
      yield put(setSalesPartnersListData(resp.response.data.oem_sales_parter));
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.message;
    } else {
      console.log("unexpected error: ", error);
      // return yield put(setUserListData());
    }
  }
}
function* fetchServiceCenterListData() {
  // console.log("PRODUCT_LIST started");
  let token = localStorage.getItem("token");

  try {
    var bodyFormData = new FormData();
    bodyFormData.append("token", token);
    let resp = yield apiPost(SERVICE_CENTER_LIST, bodyFormData);
    console.log("SERVICE_CENTER_LIST", resp);
    if (resp.response.status === 200) {
      yield put(
        setServiceCentersListData(resp.response.data.oem_service_center)
      );
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.message;
    } else {
      console.log("unexpected error: ", error);
      // return yield put(setUserListData());
    }
  }
}
function* fetchSupplierListData() {
  // console.log("PRODUCT_LIST started");
  let token = localStorage.getItem("token");

  try {
    var bodyFormData = new FormData();
    bodyFormData.append("token", token);
    let resp = yield apiPost(SUPPLIER_LIST, bodyFormData);
    console.log("SUPPLIER_LIST", resp);
    if (resp.response.status === 200) {
      yield put(setSupplierListData(resp.response.data.oem_supplier));
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.message;
    } else {
      console.log("unexpected error: ", error);
      // return yield put(setUserListData());
    }
  }
}
function* fetchSalesPartnerDetailsByID(data) {
  console.log("fetchBatchDetailByIDPRODUCT_LIST started");
  let token = localStorage.getItem("token");
  try {
    var bodyFormData = new FormData();
    bodyFormData.append("token", token);
    bodyFormData.append("id", data.payload);
    let resp = yield apiPost(SALES_PARTNERS_BY_ID, bodyFormData);
    console.log("salespartnerdetails", resp);
    if (resp.response.status === 200) {
      yield put(setSalesPartnerDetailByID(resp.response.data.data));
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.message;
    } else {
      console.log("unexpected error: ", error);
      // return yield put(setUserListData());
    }
  }
}
// function* fetchServiceCenterDetailByID(data) {
//   console.log("fetchBatchDetailByIDPRODUCT_LIST started");
//   let token = localStorage.getItem("token");
//   try {
//     var bodyFormData = new FormData();
//     bodyFormData.append("token", token);
//     bodyFormData.append("id", data.payload);
//     let resp = yield apiPost(SINGLE_BATCH_DETAILS, bodyFormData);
//     console.log("SINGLE_WARRANTY_DETAILS", resp);
//     if (resp.response.status === 200) {
//       yield put(setBatchDetailByID(resp.response.data.batch));
//     }
//   } catch (error) {
//     if (axios.isAxiosError(error)) {
//       return error.message;
//     } else {
//       console.log("unexpected error: ", error);
//       // return yield put(setUserListData());
//     }
//   }
// }

export default function* VendorsSaga() {
  yield takeEvery(tryfetchSalesPartnersList, fetchSalesParntersListData);
  yield takeEvery(tryfetchServiceCentersList, fetchServiceCenterListData);
  yield takeEvery(tryfetchSalesPartnerDetailByID, fetchSalesPartnerDetailsByID);
  yield takeEvery(tryfetchSupplierList, fetchSupplierListData);
}
