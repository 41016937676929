import styled from "styled-components";

export const SaveButton = styled.button`
  background: #ffffff;
  border: 2px solid #4d5fff;
  padding: 0.8em 6em 0.8em 6em;
  border-radius: 4px;
  font-size: 11px;
  font-family: Public Sans;
  font-weight: 400;
  color: #4d5fff;
  transition: 0.2s ease;
  &:hover {
    color: #ffffff;
    background: #4d5fff;
    //   border: 2px solid #4d5fff;
  }
`;
export const SubmitButton = styled.button`
  background: #4d5fff;
  border: 2px solid #4d5fff;
  padding: 0.8em 6em 0.8em 6em;
  border-radius: 4px;
  font-size: 11px;
  font-family: Public Sans;
  font-weight: 400;
  color: #ffffff;
  transition: 0.2s ease;
  &:hover {
    color: #4d5fff;
    background: #ffffff;
    //   border: 2px solid #4d5fff;
  }
`;
export const WhiteButton = styled.button`
  border: 1px solid #cdcdcd;
  height: 38px;
  background: #ffffff;
  gap: 3px;
  margin-right: 15px;
  padding: 5px 15px;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  color: black;
  display: flex;
  align-items: center;
  font-weight: 500;
`;
export const BlueButton = styled.button`
  background: #4d5fff;
  font-weight: 500;
  color: white;
  cursor: pointer;
  border: none;
  display: flex;
  gap: 3px;
  width: 88px;
  height: 38px;
  font-size: 13px;
  cursor: pointer;
  border-radius: 4px;
  padding: 5px 15px;
  align-items: center;
`;

export const FilterButton = styled.button`
  background: #ffffff;
  border: 2px solid #4d5fff;
  width: 100%;
  /* background: #4d5fff; */
  color: #4d5fff;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  font-size: 11px;
  transition: 0.2s ease;
  &:hover {
    background: #4d5fff;
    color: #ffffff;
    //   border: 2px solid #4d5fff;
  }
`;
export const FilterButtonCancel = styled.button`
  color: #ffffff;
  border: 2px solid #ffffff;
  width: 100%;
  background: #4d5fff;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  font-size: 11px;
  transition: 0.2s ease;
  &:hover {
    color: #4d5fff;
    border: 2px solid #4d5fff;
    background: #ffffff;
  }
`;
