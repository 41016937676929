import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import HeaderComponent from "./HeaderComponent";
import AddSupplierModal from "./AddSettingsModal";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import {
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
} from "mdb-react-ui-kit";
import {
  ActionIcons,
  Actions,
  customCellStyle,
  customHeaderStyle,
} from "../../Components/StyledComponents/Common";
import { tryfetchSettingsByID } from "../../Slices/SettingsSlice";
import { useParams } from "react-router-dom";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { PiPencilSimple } from "react-icons/pi";
import { TfiTrash } from "react-icons/tfi";
import DeleteAisle from "./DeleteModal";
import { apiPost } from "../../config/apiConfig";
import { ToastContainer, toast } from "react-toastify";
import deleteIcon from "../../assets/helperIcons/delete.svg";
import pencil from "../../assets/helperIcons/pencil.svg";
import { DELETE_SETTINGS } from "../../config/BaseUrl";
const SettingsList = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const ListData = useSelector((state) => state?.settings?.settingsTableData);
  const [filterText, setFilterText] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [type, setType] = useState(null);
  const [unit, setUnit] = useState(null);
  const [row, setRow] = useState(null);
  const [itemId, setItemId] = useState("");
  const [deleteModel, setdeleteModel] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const itemsPerPage = 10; // Set the number of items per page
  const totalPages = Math.ceil(filteredData?.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // Get the current page data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData?.slice(indexOfFirstItem, indexOfLastItem);
  const getPageNumbers = () => {
    const maxPagesToShow = 3;
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
    let startPage = currentPage - halfMaxPagesToShow;
    let endPage = currentPage + halfMaxPagesToShow;

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(totalPages, maxPagesToShow);
    } else if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, totalPages - maxPagesToShow + 1);
    }

    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  };
  const columnDefs = useMemo(() => [
    {
      field: "name",
      headerName: "Type",
      headerTooltip: "Type",
    },
    // {
    //   field: "Action",
    //   headerName: "Action",
    //   headerTooltip: "Action",
    // },
    // {
    //   field: "coverage_details",
    //   headerName: "Coverage details",
    //   headerTooltip: "Coverage details",
    // },
    // {
    //   field: "warranty_period_type",
    //   headerName: "Warranty period type",
    //   headerTooltip: "Warranty period type",
    // },
    // {
    //   field: "warranty_period",
    //   headerName: "Warranty period",
    //   headerTooltip: "Warranty period",
    // },

    // {
    //   field: "features",
    //   headerName: "Features",
    //   headerTooltip: " Features",
    // },
    // {
    //   field: "unit",
    //   headerName: "Unit",
    //   headerTooltip: " Unit",
    // },
  ]);
  // const columnDefs1 = useMemo(() => [
  //   {
  //     field: "name",
  //     headerName: "Type",
  //     headerTooltip: "Type",
  //   },
  // ]);
  // const defaultColDef = useMemo(
  //   () => ({
  //     flex: 1,
  //     filter: true,
  //     sortable: true,
  //     resizable: true,
  //   }),
  //   []
  // );

  const handleClear = () => {
    if (filterText) {
      // setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };
  const handleSearch = (searchValue) => {
    const searchTerm =
      searchValue && typeof searchValue === "string"
        ? searchValue.toLowerCase()
        : "";
    const filteredList = ListData?.filter((row) => {
      console.log("row listdata", row);
      const name = row?.name?.toLowerCase();
      return name?.includes(searchTerm);
    });
    setFilteredData(filteredList);
  };
  const handleRowClick = (row) => {
    setType("edit");
    setRow(row);
    setItemId(row.id);
    setModalOpen(true);
  };
  // const resetItemId = () => {
  //   setItemId("");
  // };

  // const cellClicked = (params) => {
  //   params.node.setSelected(true);
  // };
  const handleAdd = () => {
    setType("add");
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
    setRow(null);
  };
  // const gridStyle = useMemo(() => ({ height: "60vh", width: "100%" }), []);

  const handleOpenDelete = (id) => {
    setItemId(id);
    setdeleteModel(true);
  };
  const handleDelete = (id) => {
    let token = localStorage.getItem("token");
    var bodyFormData = new FormData();
    bodyFormData.append("token", token);
    bodyFormData.append("id", itemId);
    let resp = apiPost(DELETE_SETTINGS, bodyFormData);
    resp.then((resp) => {
      console.log("delete resp", resp);
      switch (resp.response.status) {
        case 200:
          dispatch(tryfetchSettingsByID(params.type));
          toast.success(resp.response.data.message);
          setTimeout(() => {
            setdeleteModel(false);
            // close();
          }, 2000);
          break;

        default:
        // getData();
      }
    });
  };

  useEffect(() => {
    // console.log("ListData", ListData);
    setFilteredData(ListData);
  }, [ListData]);
  useEffect(() => {
    setUnit(
      params.type === "product_type"
        ? "Product Type"
        : params.type === "unit"
        ? "Unit"
        : params.type === "gst"
        ? "Gst Type"
        : params.type === "Additional Warranty"
        ? "Additional Warranty"
        : ""
    );
    dispatch(tryfetchSettingsByID(params.type));
  }, [params]);

  return (
    <div className="d-flex" style={{ overflowY: "auto" }}>
      <Page>
        <ToastContainer />
        <PageContent>
          <ListHead>
            {/* <Title>Products</Title>
            <ListButtons>
              <Button onClick={handleAdd}>
                <i class="fa fa-plus" aria-hidden="true"></i> New
              </Button>
              <Button $primary>
                <i class="fa fa-cog" aria-hidden="true"></i>
              </Button>
            </ListButtons> */}
            <HeaderComponent
              onFilter={handleSearch}
              onClear={handleClear}
              filterText={filterText}
              handleAdd={handleAdd}
            />
          </ListHead>
          <>
            <div className="list-table">
              <MDBTable>
                <MDBTableHead color="#4D5FFF">
                  <tr>
                    {/* Display "unit" at the beginning */}
                    <th
                      md={8}
                      style={customHeaderStyle}
                      className="header-text"
                      colSpan={3}
                    >
                      {unit}
                    </th>
                    <th></th>
                    <th></th>
                    {/* {columnDefs?.map((colDef, index) => (
                      <th
                        style={customHeaderStyle}
                        key={index}
                        scope="col"
                        className="header-text"
                      >
                        {colDef.headerName}
                      </th>
                    ))} */}
                    {/* Display "actions" at the end */}
                    <th
                      md={4}
                      style={customHeaderStyle}
                      className="header-text"
                    >
                      Actions
                    </th>
                  </tr>
                </MDBTableHead>

                <MDBTableBody align="left">
                  {currentItems?.map((rowData) => (
                    <tr key={rowData.customerId}>
                      {columnDefs?.map((colDef, index) => (
                        <td
                          key={index}
                          // onClick={() => handleRowClick(rowData)}
                          style={customCellStyle}
                          colSpan={3}
                        >
                          {rowData[colDef.field]}
                        </td>
                      ))}
                      <td></td>
                      <td></td>
                      <td className="">
                        <Actions>
                          <ActionIcons onClick={() => handleRowClick(rowData)}>
                            {/* <PiPencilSimple size={24} color="838383" /> */}
                            <img src={pencil} alt="" srcset="" />
                          </ActionIcons>
                          <ActionIcons
                            onClick={() => handleOpenDelete(rowData.id)}
                          >
                            {/* <TfiTrash size={21} color="838383" /> */}
                            <img src={deleteIcon} alt="" srcset="" />
                          </ActionIcons>
                        </Actions>
                        {/* <Actions> 
                        <ActionIcons onClick={() => handleRowClick(rowData)}>
                            <PiPencilSimple />
                          </ActionIcons>
                          <ActionIcons
                            onClick={() => handleOpenDelete(rowData.id)}
                          >
                            <TfiTrash />
                          </ActionIcons>
                        </Actions> */}
                      </td>
                    </tr>
                  ))}
                </MDBTableBody>
              </MDBTable>
            </div>

            {/* <MDBPagination className="mt-3" end>
              <MDBPaginationItem disabled={currentPage === 1}>
                <MDBPaginationLink
                  className="page-link"
                  aria-label="Previous"
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <span aria-hidden="true">&laquo;</span>
                </MDBPaginationLink>
              </MDBPaginationItem>
              {Array.from({ length: totalPages }, (_, i) => (
                <MDBPaginationItem key={i} active={i + 1 === currentPage}>
                  <MDBPaginationLink
                    className="page-link"
                    onClick={() => handlePageChange(i + 1)}
                  >
                    {i + 1}
                  </MDBPaginationLink>
                </MDBPaginationItem>
              ))}
              <MDBPaginationItem disabled={currentPage === totalPages}>
                <MDBPaginationLink
                  className="page-link"
                  aria-label="Next"
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <span aria-hidden="true">&raquo;</span>
                </MDBPaginationLink>
              </MDBPaginationItem>
            </MDBPagination> */}
            <MDBPagination className="py-3 gap-4 justify-content-end">
              <MDBPaginationItem disabled={currentPage === 1}>
                <MDBPaginationLink
                  style={{ border: "none", background: "none" }}
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <MdKeyboardArrowLeft />
                </MDBPaginationLink>
              </MDBPaginationItem>
              {getPageNumbers().map((page) => (
                <MDBPaginationItem key={page} active={page === currentPage}>
                  <MDBPaginationLink
                    className="page-link"
                    onClick={() => handlePageChange(page)}
                    style={{
                      backgroundColor: page === currentPage ? "black" : "white",
                      color: page === currentPage ? "white" : "black",
                      border: "1px solid #aeaeae",
                    }}
                  >
                    {page}
                  </MDBPaginationLink>
                </MDBPaginationItem>
              ))}
              <MDBPaginationItem disabled={currentPage === totalPages}>
                <MDBPaginationLink
                  onClick={() => handlePageChange(currentPage + 1)}
                  style={{ border: "none", background: "none" }}
                >
                  <MdKeyboardArrowRight />
                </MDBPaginationLink>
              </MDBPaginationItem>
            </MDBPagination>
          </>
        </PageContent>
      </Page>
      <AddSupplierModal
        itemId={itemId}
        type={type}
        unit={unit}
        row={row}
        settingsType={params.type}
        open={isModalOpen}
        onClose={handleCloseModal}
      />{" "}
      <DeleteAisle
        openModal={deleteModel}
        setOpenModel={setdeleteModel}
        handleDeleteUom={handleDelete}
        unit={unit}
        id={itemId}
      />
    </div>
  );
};

const Page = styled.div`
  width: 100%;
  margin-left: 10px;
  margin-top: 10px;
  border-radius: 22px;
  background-color: #fff;
  padding: 1em;
  display: flex;
`;
const PageContent = styled.div`
  // padding: calc(60px + 24px) 0 60px 0;
  width: ${(props) => (props.$singleView ? "350px" : "100%")};
  overflow: auto;
  border-right: ${(props) =>
    props.$singleView ? "0.5px solid #aeaeae;" : "none"};
  transition: all 0.3s ease-out;
  @media (max-width: 1200px) {
    display: ${(props) => (props.$singleView ? "none" : "block")};
  }
`;
const ListHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border-bottom: 0.5px solid #bfbfbf;
  padding: 0 11px 20px 15px;
`;
// const ListGridTable = styled.div`
//   width: 100%;
//   height: 70vh;
// `;

export default SettingsList;
