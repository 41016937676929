import React, { useEffect, useMemo, useState } from "react";
import { MDBTable, MDBTableHead, MDBTableBody, MDBBtn } from "mdb-react-ui-kit";
import {
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
} from "mdb-react-ui-kit";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HeaderComponent from "./HeaderComponent";
import {
  tryfetchBatchDetailByID,
  tryfetchBatchList,
} from "../../Slices/BatchSlice";
import SingleBatchView from "./SingleOEMView";
import { Page, PageContent } from "../../Components/StyledComponents/Common";
import { tryfetchOEMDetailByID, tryfetchOEMList } from "../../Slices/OEMSlice";
import SingleOEMView from "./SingleOEMView";
import { tryfetchSubscriptionList } from "../../Slices/SubscriptionSlice";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

const SubscriptionList = () => {
  const history = useNavigate();
  const role_name = localStorage.getItem("roleName");

  const dispatch = useDispatch();
  const ListData = useSelector(
    (state) => state?.subscription?.subscriptionTableData
  );
  const [filterText, setFilterText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [itemId, setItemId] = useState("");

  const itemsPerPage = 10; // Set the number of items per page
  const totalPages = Math.ceil(filteredData?.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const getPageNumbers = () => {
    const maxPagesToShow = 3;
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
    let startPage = currentPage - halfMaxPagesToShow;
    let endPage = currentPage + halfMaxPagesToShow;

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(totalPages, maxPagesToShow);
    } else if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, totalPages - maxPagesToShow + 1);
    }

    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  };
  // Get the current page data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData?.slice(indexOfFirstItem, indexOfLastItem);
  const columnDefs = useMemo(() => [
    role_name === "admin"
      ? {
          field: "oem_name",
          headerName: "OEM Name",
          headerTooltip: "OEM Name",
        }
      : "",
    {
      field: "no_of_products",
      headerName: "No of Products",
      headerTooltip: "No of Products",
    },
    // {
    //   field: "supplier_partner_no",
    //   headerName: "Supplier Partner No",
    //   headerTooltip: "Supplier Partner No",
    // },
    {
      field: "no_of_periods",
      headerName: "No of Period",
      headerTooltip: "No of Period",
    },
    {
      field: "period_type",
      headerName: "Type of Period",
      headerTooltip: "Type of Period",
    },

    {
      field: "cost",
      headerName: "Amount",
      headerTooltip: "Amount",
    },
    {
      field: "subscribe_date",
      headerName: "Purchase Date",
      headerTooltip: "Purchase Date",
    },
    {
      field: "expiry_date",
      headerName: "Expiry Date",
      headerTooltip: " Expiry Date",
    },
  ]);
  const columnDefs1 = useMemo(() => [
    {
      field: "oem_name",
      headerName: "Company Name",
      headerTooltip: "Company Name",
    },
  ]);

  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      filter: true,
      sortable: true,
      resizable: true,
    }),
    []
  );

  const handleClear = () => {
    if (filterText) {
      setFilterText("");
    }
  };

  const handleSearch = (searchValue) => {
    setFilterText(searchValue);
    const filteredList = ListData.filter((row) => {
      // const id = String(row.batch_number).toLowerCase();
      const id = String(row.oem_name).toLowerCase();
      const search = searchValue.toLowerCase();
      return id.includes(search);
    });
    setFilteredData(filteredList);
  };

  const handleRowClick = (row) => {
    console.log("rowsadra", row);
    setRowData(row);
    setItemId(row?.id);
    dispatch(tryfetchOEMDetailByID(row?.id));
  };

  const resetItemId = () => {
    setItemId("");
  };

  const handleAdd = () => {
    history(`/Subscription`);
  };

  const gridStyle = useMemo(() => ({ height: "60vh", width: "100%" }), []);
  useEffect(() => {
    dispatch(tryfetchSubscriptionList());
  }, []);

  useEffect(() => {
    setFilteredData(ListData);
  }, [ListData]);

  return (
    <div className="d-flex" style={{ overflow: "auto" }}>
      <Page>
        <PageContent $singleView={itemId}>
          <ListHead>
            <HeaderComponent
              onFilter={handleSearch}
              onClear={handleClear}
              handleAdd={handleAdd}
              itemId={itemId}
              filterText={filterText}
            />
          </ListHead>
          <>
            <div className="list-table" style={{ overflow: "auto" }}>
              <MDBTable>
                <MDBTableHead color="#4D5FFF" align="left">
                  <tr>
                    {!itemId
                      ? columnDefs.map((colDef) => (
                          <th
                            style={customHeaderStyle}
                            key={colDef.field}
                            scope="col"
                            className="header-text"
                          >
                            {colDef.headerName}
                          </th>
                        ))
                      : columnDefs1.map((colDef) => (
                          <th
                            style={customHeaderStyle}
                            key={colDef.field}
                            scope="col"
                            className="header-text"
                          >
                            {colDef.headerName}
                          </th>
                        ))}
                  </tr>
                </MDBTableHead>

                <MDBTableBody align="left">
                  {currentItems?.map((rowData) => (
                    <tr key={rowData.customerId}>
                      {!itemId
                        ? columnDefs?.map((colDef) => (
                            <td
                              key={colDef.field}
                              // onClick={() => handleRowClick(rowData)}
                              style={customCellStyle}
                            >
                              {rowData[colDef.field]}
                            </td>
                          ))
                        : columnDefs1?.map((colDef) => (
                            <td
                              key={colDef.field}
                              // onClick={() => handleRowClick(rowData)}
                              style={customCellStyle}
                            >
                              {rowData[colDef.field]}
                            </td>
                          ))}
                    </tr>
                  ))}
                </MDBTableBody>
              </MDBTable>
            </div>

            {/* <MDBPagination className="mt-3" end>
              <MDBPaginationItem disabled={currentPage === 1}>
                <MDBPaginationLink
                  className="page-link"
                  aria-label="Previous"
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <span aria-hidden="true">&laquo;</span>
                </MDBPaginationLink>
              </MDBPaginationItem>
              {Array.from({ length: totalPages }, (_, i) => (
                <MDBPaginationItem key={i} active={i + 1 === currentPage}>
                  <MDBPaginationLink
                    className="page-link"
                    onClick={() => handlePageChange(i + 1)}
                  >
                    {i + 1}
                  </MDBPaginationLink>
                </MDBPaginationItem>
              ))}
              <MDBPaginationItem disabled={currentPage === totalPages}>
                <MDBPaginationLink
                  className="page-link"
                  aria-label="Next"
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <span aria-hidden="true">&raquo;</span>
                </MDBPaginationLink>
              </MDBPaginationItem>
            </MDBPagination> */}
            <MDBPagination className="py-3 gap-4 justify-content-end">
              <MDBPaginationItem disabled={currentPage === 1}>
                <MDBPaginationLink
                  style={{ border: "none", background: "none" }}
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <MdKeyboardArrowLeft />
                </MDBPaginationLink>
              </MDBPaginationItem>
              {getPageNumbers().map((page) => (
                <MDBPaginationItem key={page} active={page === currentPage}>
                  <MDBPaginationLink
                    className="page-link"
                    onClick={() => handlePageChange(page)}
                    style={{
                      backgroundColor: page === currentPage ? "black" : "white",
                      color: page === currentPage ? "white" : "black",
                      border: "1px solid #aeaeae",
                    }}
                  >
                    {page}
                  </MDBPaginationLink>
                </MDBPaginationItem>
              ))}
              <MDBPaginationItem disabled={currentPage === totalPages}>
                <MDBPaginationLink
                  onClick={() => handlePageChange(currentPage + 1)}
                  style={{ border: "none", background: "none" }}
                >
                  <MdKeyboardArrowRight />
                </MDBPaginationLink>
              </MDBPaginationItem>
            </MDBPagination>
          </>
        </PageContent>
        {itemId ? (
          <PageContent>
            <SingleOEMView close={resetItemId} itemId={itemId} data={rowData} />
          </PageContent>
        ) : (
          ""
        )}
      </Page>
    </div>
  );
};

const ListHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid #bfbfbf;
  padding: 0 11px 20px 15px;
  .header-text {
    color: "#4D5FFF"; // Default text color
    font-size: 25px; // Default font size
  }
`;
const customHeaderStyle = {
  // background: "#4D5FFF",
  color: "#4D5FFF",
  fontSize: "13px",
  fontWeight: "500",
  padding: "1.3rem 0.5rem",
};

const customCellStyle = {
  // border: "1px solid #CCCCCC",
  padding: "1.3rem 0.5rem",
};
export default SubscriptionList;
