import { put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { apiPost } from "../config/apiConfig.js";
import {
  setNotificationsListData,
  tryfetchNotificationsList,
} from "../Slices/HeaderSlice.jsx";
import { NOTIFICATIONS } from "../config/BaseUrl.js";

function* fetchBatchListData() {
  // console.log("PRODUCT_LIST started");
  let token = localStorage.getItem("token");
  let userId = localStorage.getItem("userId");

  try {
    var bodyFormData = new FormData();
    bodyFormData.append("token", token);
    bodyFormData.append("user_id", userId);
    let resp = yield apiPost(NOTIFICATIONS, bodyFormData);
    // console.log("tryfetchNotificationsList", resp);
    if (resp.response.status === 200) {
      yield put(setNotificationsListData(resp.response.data));
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.message;
    } else {
      console.log("unexpected error: ", error);
      // return yield put(setUserListData());
    }
  }
}
// function* fetchBatchDetailByID(data) {
//   console.log("fetchBatchDetailByIDPRODUCT_LIST started");
//   let token = localStorage.getItem("token");
//   try {
//     var bodyFormData = new FormData();
//     bodyFormData.append("token", token);
//     bodyFormData.append("id", data.payload);
//     let resp = yield apiPost(SINGLE_BATCH_DETAILS, bodyFormData);
//     console.log("SINGLE_WARRANTY_DETAILS", resp);
//     if (resp.response.status === 200) {
//       yield put(setBatchDetailByID(resp.response.data.batch));
//     }
//   } catch (error) {
//     if (axios.isAxiosError(error)) {
//       return error.message;
//     } else {
//       console.log("unexpected error: ", error);
//       // return yield put(setUserListData());
//     }
//   }
// }

export default function* HeaderSaga() {
  yield takeEvery(tryfetchNotificationsList, fetchBatchListData);
  //   yield takeEvery(tryfetchBatchDetailByID, fetchBatchDetailByID);
}
