import styled from "styled-components";

export const HeadTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2em;
`;
export const HeadSubTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 1.5em;
  color: #515151;
`;
export const PageInnerContent = styled.div`
  height: calc(100vh-87px);
  overflow: auto;
  padding: 3em 1.5em;
  width: 100%;
`;
// export const Page = styled.div`
//   // width: 100%;
//   height: calc(100% - 85px);
//   margin-left: 10px;
//   margin-top: 10px;
//   border-radius: 22px;
//   overflow-y: auto;
//   background-color: #fff;
//   padding: 1em;
//   display: flex;
// `;
// export const PageContent = styled.div`
//   // padding: calc(60px + 24px) 0 60px 0;
//   width: ${(props) => (props.$singleView ? "22rem" : "100%")};
//   overflow: auto;
//   border-right: ${(props) =>
//     props.$singleView ? "0.5px solid #aeaeae;" : "none"};
//   transition: all 0.3s ease-out;
//   @media (max-width: 1200px) {
//     display: ${(props) => (props.$singleView ? "none" : "block")};
//   }
// `;
export const Page = styled.div`
  width: ${(props) => (props.$view ? "22em" : "99%")};
  margin-left: 10px;
  margin-top: 10px;
  border-radius: ${(props) => (props.$view ? "0" : "22px")};
  overflow-y: auto;
  background-color: ${(props) => (props.$view ? "#f3f3f3" : "#fff")};
  flex-shrink: 0;
  transition: all 0.3s ease-out;
  padding: ${(props) => (props.$view ? "0" : "1rem")};
  @media (max-width: 1200px) {
    display: ${(props) => (props.$view ? "none" : "flex")};
  }
  // display: flex;
`;
export const PageSingle = styled.div`
  width: ${(props) => (!props.$singleView ? "" : "100%")};
  margin-left: 10px;
  margin-top: 10px;
  border-radius: 22px;
  overflow-y: auto;
  transition: all 0.3s ease-out;
  height: 98%;
  background-color: #fff;
  // padding: 0.5em;
  display: ${(props) => (!props.$singleView ? "flex" : "")};
`;
export const PageContent = styled.div`
  // padding: calc(60px + 24px) 0 60px 0;
  // width: ${(props) => (props.$singleView ? "300px" : "100%")};
  width: 100%;
  overflow: auto;
  transition: all 0.3s ease-out;
`;
export const customHeaderStyle = {
  // background: "#4D5FFF",
  color: "#4D5FFF",
  fontSize: "13px",
  fontWeight: "500",
  padding: "1.3rem 0.5rem",
  width: "200px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};
export const customHeaderStyleId = {
  background: "#e6e6e6",
  color: "#000000",
  fontSize: "13px",
  fontWeight: "500",
  padding: "1.3rem 0.5rem",
  width: "200px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

export const customCellStyle = {
  fontSize: "13px",
  // border: "1px solid #CCCCCC",
  padding: "1.3rem 0.5rem",
  whiteSpace: "nowrap",
  maxWidth: "200px",
  overflow: "hidden",
  textOverflow: "ellipsis",
};
export const customPagination = {
  fontSize: "25px",
  // border: "1px solid #CCCCCC",
  // padding: "1.3rem 0.5rem",
};
export const Actions = styled.div`
  gap: 1rem;
  display: flex;
`;
export const ActionIcons = styled.div`
  // display: flex;
  padding-right: 0.8rem;
  cursor: pointer;
`;
export const InputStyle = {
  ".MuiOutlinedInput-root": {
    borderRadius: "11px",
  },
  ".MuiOutlinedInput-input": {
    height: "15px",
  },
};
export const InputPinStyle = {
  width: "3.5em",
  ".MuiOutlinedInput-root": {
    borderRadius: "11px",
  },
  ".MuiOutlinedInput-input": {
    height: "15px",
  },
};
export const selectStyle = {
  borderRadius: "11px",
  "& .MuiOutlinedInput-input": {
    // height: "15px",
  },
  height: "48px", // Set the desired height
  "& .MuiInputBase-root": {
    height: "100%", // Set the input base height to 100% for proper alignment
  },
};
export const SecondarySearch = styled.div`
  width: 195px;
  // margin: 0.5em 0 0 0.5em;
`;
