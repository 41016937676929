import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PublicRoute = ({ children }) => {
  // const isAuthenticate = localStorage.getItem("isAuthenticated");
  const isAuthenticate = localStorage.getItem("token");
  if (!isAuthenticate === "invalidToken") {
    // console.log("isAuthenticate", isAuthenticate);
    return <Navigate to="/" />;
  }

  return (
    <>
      <Outlet />
    </>
  );
};

export default PublicRoute;
