import { put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { apiPost } from "../config/apiConfig.js";
import {
  setSettingsByID,
  tryfetchSettingsByID,
} from "../Slices/SettingsSlice.jsx";
import { SETTINGS_TYPE } from "../config/BaseUrl.js";

// function* fetchBatchListData() {
//   // console.log("PRODUCT_LIST started");
//   let token = localStorage.getItem("token");

//   try {
//     var bodyFormData = new FormData();
//     bodyFormData.append("token", token);
//     let resp = yield apiPost(BATCH_LIST, bodyFormData);
//     console.log("BATCH_LIST", resp);
//     if (resp.response.status === 200) {
//       yield put(setBatchListData(resp.response.data.batch));
//     }
//   } catch (error) {
//     if (axios.isAxiosError(error)) {
//       return error.message;
//     } else {
//       console.log("unexpected error: ", error);
//       // return yield put(setUserListData());
//     }
//   }
// }
function* fetchSettingsByID(data) {
  // console.log("f");
  let token = localStorage.getItem("token");
  try {
    var bodyFormData = new FormData();
    bodyFormData.append("token", token);
    bodyFormData.append("type", data.payload);
    let resp = yield apiPost(SETTINGS_TYPE, bodyFormData);
    console.log("SETTINGS_TYPE", resp);
    if (resp.response.status === 200) {
      yield put(setSettingsByID(resp.response.data.Details));
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.message;
    } else {
      console.log("unexpected error: ", error);
      // return yield put(setUserListData());
    }
  }
}
// function* fetchSettingsByID(data) {
//   console.log("f");
//   let token = localStorage.getItem("token");
//   try {
//     var bodyFormData = new FormData();
//     bodyFormData.append("token", token);
//     bodyFormData.append("type", data.payload);
//     let resp = yield apiPost(SETTINGS_TYPE, bodyFormData);
//     console.log("SETTINGS_TYPE", resp);
//     if (resp.response.status === 200) {
//       yield put(setSettingsByID(resp.response.data.batch));
//     }
//   } catch (error) {
//     if (axios.isAxiosError(error)) {
//       return error.message;
//     } else {
//       console.log("unexpected error: ", error);
//       // return yield put(setUserListData());
//     }
//   }
// }

export default function* SettingsSaga() {
  // yield takeEvery(tryfetchSettingsByID, fetchBatchListData);
  yield takeEvery(tryfetchSettingsByID, fetchSettingsByID);
}
