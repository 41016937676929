import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import styled from "styled-components";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from "@mui/material";

import { Col, Row } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { apiPost } from "../../config/apiConfig";
import {
  ADD_PACKAGE,
  ADD_WARRANTY,
  UPDATE_WARRANTY,
} from "../../config/BaseUrl";
import {
  SaveButton,
  SubmitButton,
} from "../../Components/StyledComponents/Buttons";
import {
  clearWarrantysDetailsByID,
  tryfetchWarrantysDetailByID,
  tryfetchWarrantysList,
} from "../../Slices/WarrantySlice";
import { useDispatch, useSelector } from "react-redux";
import {
  InputStyle,
  selectStyle,
} from "../../Components/StyledComponents/Common";
import { tryfetchPackageList } from "../../Slices/SubscriptionSlice";

const AddSubscriptionModal = ({ open, type, itemId, onClose }) => {
  const WRData = useSelector((state) => state?.warranty?.warrantyDetailByID);

  const dispatch = useDispatch();
  const [IsSaving, setIsSaving] = useState(false);
  const [values, setValues] = useState({
    package_name: "",
    time_period: "",
    cost: "",
  });

  const [checkedValues, setCheckedValues] = useState({
    extendedWarranty: false,
    workstation: false,
    onSiteSupport: false,
    required: false,
    disabled: false,
  });
  const handleChange = (event) => {
    const value = event.target.value;

    setValues((prevValues) => ({
      ...prevValues,
      [event.target.name]: value,
    }));
  };

  const handleAdd = async (e) => {
    console.log("add");
    const checkedCheckboxes = Object.keys(checkedValues).filter(
      (key) => checkedValues[key]
    );
    setIsSaving(true);
    try {
      var token = localStorage.getItem("token");
      var bodyFormData = new FormData();

      bodyFormData.append("token", token);
      bodyFormData.append("package_name", values.package_name);
      bodyFormData.append("time_period", values.time_period);
      bodyFormData.append("cost", values.cost);

      console.log(" formdata is ", bodyFormData);
      let resp = await apiPost(ADD_PACKAGE, bodyFormData);
      if (
        resp.response.status === 200
        // resp.response.data.message === "User Added Successfully"
      ) {
        console.log(" file values is asd", resp);
        toast.success(resp.response.data.message);
        setTimeout(() => {
          //   navigate("/");
          setIsSaving(false);
        }, 2000);
        dispatch(tryfetchPackageList());
        setValues({
          package_name: "",
          time_period: "",
          cost: "",
        });
      } else {
        toast.error(resp.response.data.message);
      }
    } catch (error) {
      setIsSaving(false);
      toast.error(error.message);
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
    onClose();
  };
  const handleUpdate = async (e) => {
    console.log("updated");
    const checkedCheckboxes = Object.keys(checkedValues).filter(
      (key) => checkedValues[key]
    );
    setIsSaving(true);
    try {
      var token = localStorage.getItem("token");
      var bodyFormData = new FormData();

      bodyFormData.append("id", itemId);
      bodyFormData.append("token", token);
      bodyFormData.append(
        "warranty_package_name",
        values.warranty_package_name
      );
      bodyFormData.append("warranty_period", values.warranty_period);
      bodyFormData.append("warranty_period_type", values.warranty_period_type);
      bodyFormData.append("coverage_details", values.coverage_details);
      bodyFormData.append("features", checkedCheckboxes);
      console.log(" formdata is ", bodyFormData);
      let resp = await apiPost(UPDATE_WARRANTY, bodyFormData);
      if (
        resp.response.status === 200
        // resp.response.data.message === "User Added Successfully"
      ) {
        console.log(" file values is asd", resp);
        toast.success(resp.response.data.message);
        setTimeout(() => {
          //   navigate("/");
          setIsSaving(false);
        }, 2000);
        dispatch(tryfetchWarrantysList());
      } else {
        toast.error(resp.response.data.message);
      }
    } catch (error) {
      setIsSaving(false);
      toast.error(error.message);
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
    onClose();
  };
  //   const style = {
  //     position: "absolute",
  //     top: "150px",
  //     right: "40px",
  //     bgcolor: "background.paper",
  //     borderRadius: "22px",
  //     width: 500,
  //     height: calculateSize("100%" - "150px"),
  //     boxShadow: 24,
  //     padding: "3em 2em",
  //     // border: "2px solid #000",
  //     // pt: 2,
  //     // px: 4,
  //     // pb: 3,
  //   };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (type === "edit") {
      handleUpdate();
    } else {
      handleAdd();
    }
  };

  useEffect(() => {
    return () => {
      // dispatch(clearWarrantysDetailsByID());
      setValues({
        package_name: "",
        time_period: "",
        cost: "",
      });
    };
  }, []);

  useEffect(() => {
    if (type === "add") {
      setValues({
        package_name: "",
        time_period: "",
        cost: "",
      });
    }
  }, [type]);
  return (
    <>
      <ToastContainer />
      {/* <Button onClick={onClose}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <ModalContainer
        // sx={{ ...style }}
        >
          <h2 id="parent-modal-title">
            {type === "add" ? "Add New Package" : "Edit Package"}
          </h2>
          {/* <p id="parent-modal-description">
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </p> */}
          <form action="" onSubmit={handleSubmit}>
            <Row>
              <Col md={12} className=" px-5 pt-5">
                <Row className="">
                  <TextField
                    label="Package Name"
                    variant="outlined"
                    fullWidth
                    required
                    name="package_name"
                    value={values.package_name}
                    onChange={handleChange}
                    sx={InputStyle}
                  />
                </Row>{" "}
                <Row className="py-5">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Time Period
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={values.time_period}
                      label="Time Period"
                      name="time_period"
                      sx={selectStyle}
                      required
                      onChange={handleChange}
                    >
                      <MenuItem value="day">Day</MenuItem>
                      <MenuItem value="month">Month</MenuItem>
                      <MenuItem value="year">Year</MenuItem>
                    </Select>
                  </FormControl>
                </Row>
                <Row className="">
                  <TextField
                    label="Cost Per Product"
                    variant="outlined"
                    type="number"
                    required
                    sx={InputStyle}
                    fullWidth
                    name="cost"
                    value={values.cost}
                    onChange={handleChange}
                  />
                </Row>
              </Col>
            </Row>
            <Row>
              <div className="col-md-12 buttons d-flex justify-content-between  p-5">
                <SaveButton
                  type="button"
                  onClick={onClose}
                  className=" save-button"
                >
                  Cancel
                </SaveButton>
                <SubmitButton
                  type="submit"
                  disabled={IsSaving}
                  className="submit-button"
                >
                  Save
                </SubmitButton>
              </div>
            </Row>
          </form>
        </ModalContainer>
      </Modal>
    </>
  );
};
const ModalContainer = styled(Box)`
  position: absolute;
  top: 150px;
  right: 40px;
  background-color: #fff;
  border-radius: 22px;
  width: 500px;
  // height: 80vh;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 3em 2em;
  overflow-y: auto;
`;
const Outer = styled.div`
  border: 1px solid #c4c4c4;
  width: 96%;
  justify-self: center;
  border-radius: 4px;
  //   margin: 28px 0 0 0;
`;
const Checkboxs = styled(Checkbox)`
  display: flex;
  padding: 2em;
  white-space: nowrap;
  color: #c4c4c4;
  font-size: 14px;
`;

export default AddSubscriptionModal;
