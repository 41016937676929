import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { IoClose, IoPrintOutline } from "react-icons/io5";
import { PiPencilSimple } from "react-icons/pi";
import { TfiTrash } from "react-icons/tfi";
import { Link } from "react-router-dom";
import styled from "styled-components";
import enlargedImg from "../../assets/Product/Png final.png";
import Img1 from "../../assets/Product/Rectangle 1054.png";
import Img2 from "../../assets/Product/Rectangle 1055.png";
import Img3 from "../../assets/Product/Rectangle 1056.png";
import Img4 from "../../assets/Product/Rectangle 1057.png";
import { useDispatch, useSelector } from "react-redux";
// import DeleteAisle from "./DeleteModal";
import { apiPost } from "../../config/apiConfig";
import { DELETE_PRODUCT } from "../../config/BaseUrl";
import { tryfetchProductsList } from "../../Slices/ProductSlice";

const SingleProductView = ({ close, data, itemId }) => {
  const dispatch = useDispatch();
  const Data = useSelector((state) => state.product?.productDetailByID);
  const [selectedImage, setSelectedImage] = useState(enlargedImg);
  const [deleteModel, setdeleteModel] = useState(false);
  const handleMiniImgClick = (imgSrc) => {
    setSelectedImage(imgSrc);
  };
  //delete product

  const handleOpenDelete = (id) => {
    // setId(id);
    setdeleteModel(true);
  };
  const handleDelete = (id) => {
    let token = localStorage.getItem("token");
    var bodyFormData = new FormData();
    bodyFormData.append("token", token);
    bodyFormData.append("product_id", itemId);
    let resp = apiPost(DELETE_PRODUCT, bodyFormData);
    resp.then((resp) => {
      console.log("delete resp", resp);
      switch (resp.response.status) {
        case 200:
          setdeleteModel(false);
          close();
          // toast.success(resp.response.data.data);
          dispatch(tryfetchProductsList());
          break;

        default:
        // getData();
      }
    });
  };
  return (
    <RightBar>
      <Container>
        <Row>
          <Col md={4}>
            <Row>
              <Col md={12} className="d-flex justify-content-center">
                <EnlargedImg>
                  <img src={selectedImage} alt="enlarged Image" srcSet="" />
                </EnlargedImg>{" "}
              </Col>
            </Row>
            <Row className="d-flex justify-content-around">
              {[
                { id: 1, src: Data?.image || Img1 },
                { id: 2, src: Data?.image1 || Img2 },
                { id: 3, src: Data?.image2 || Img3 },
                { id: 4, src: Data?.image3 || Img4 },
              ].map((img, index) => (
                <MiniImg
                  key={index}
                  md={3}
                  onClick={() => handleMiniImgClick(img.src)}
                >
                  <img src={img.src} alt={`img${img.id}`} srcSet="" />
                </MiniImg>
              ))}
            </Row>
          </Col>
          <Col md={8}>
            <div className="row">
              <div className="col-md-8"></div>
              <IconContainer className="col-md-4">
                <IconWrapper className="icons">
                  <IoPrintOutline />
                </IconWrapper>
                <Link to={`/Product/Edit/${data.product_id}`}>
                  <IconWrapper className="icons">
                    <PiPencilSimple />
                  </IconWrapper>
                </Link>
                <IconWrapper className="icons" onClick={handleOpenDelete}>
                  <TfiTrash color="#ED2D22" />
                </IconWrapper>
                <IconWrapper
                  className="icons"
                  onClick={close}
                  style={{ border: "0.5px solid #ED2D22" }}
                >
                  <IoClose color="#ED2D22" />
                </IconWrapper>
              </IconContainer>
            </div>
            <div className="row">
              <Col md={12}>
                <ProductName>{Data?.product_name}</ProductName>
              </Col>
              <Col md={12}>
                <SerialNumber>{Data?.model_number}</SerialNumber>
              </Col>
              <Col md={12}>
                <Rating>Customer Rating: </Rating>
              </Col>
              <Col md={12}>
                <Heading> About The Product</Heading>
                <About>{Data?.description}</About>
              </Col>
            </div>
          </Col>
        </Row>
        <Row>
          <Info md={12}>
            InFormation
            <Line />
          </Info>
        </Row>
        <Row className="py-3">
          <Col md={4}>
            {" "}
            <Key>Model Number</Key>
            <Value>{Data?.model_number}</Value>
          </Col>
          <Col md={4}>
            {" "}
            <Key>Warranty Type</Key>
            <Value>{Data?.warranty_type}</Value>
          </Col>
          <Col md={4}>
            {" "}
            <Key>HSN Code</Key>
            <Value>{Data?.hsn_code}</Value>
          </Col>
        </Row>
        <Row className="py-3">
          <Col md={4}>
            {" "}
            <Key>Type</Key>
            <Value>{Data?.product_type}</Value>
          </Col>
          <Col md={4}>
            {" "}
            <Key>Unit</Key>
            <Value>{Data?.unit}</Value>
          </Col>
          <Col md={4}>
            {" "}
            <Key>Tax Preference</Key>
            <Value>{Data?.tax_preference}</Value>
          </Col>
        </Row>
      </Container>
      {/* <DeleteAisle
        openModal={deleteModel}
        setOpenModel={setdeleteModel}
        handleDeleteUom={handleDelete}
        id={itemId}
      /> */}
    </RightBar>
  );
};
const RightBar = styled.div`
  width: 100%;
  padding: 2.5rem;
`;
const IconContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;
const EnlargedImg = styled.div`
  display: flex;
  width: 16rem;
  height: 16rem;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
const MiniImg = styled(Col)`
  display: flex;
  margin-top: 1em;
  width: 5rem;
  height: 5rem;
  justify-content: center;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
const IconWrapper = styled.div`
  border: 0.5px solid #aeaeae;
  border-radius: 5px;
  padding: 0.6em;
  display: flex;
  justify-content: space-around;
  cursor: pointer;
`;
const ProductName = styled.div`
  font-size: 20px;
  font-weight: 500;
`;
const SerialNumber = styled.div`
  font-size: 13px;
  margin-top: 1rem;
  color: #8b8b8b;
  font-weight: 500;
  // line-height: 15px;
`;
const Rating = styled.div`
  margin-top: 1rem;
  font-size: 11px;
  font-weight: 400;
`;
const Heading = styled.div`
  margin-top: 1rem;
  font-size: 13px;
  font-weight: 400;
`;
const About = styled.p`
  margin-top: 1rem;
  font-size: 11px;
  font-weight: 400;
`;
const Info = styled(Col)`
  margin-top: 2rem;
  // font-size: 11px;
  font-weight: 500;
`;
const Line = styled.hr`
  color: #bfbfbf;
`;
const Key = styled(Row)`
  font-weight: 400;
`;
const Value = styled(Row)`
  color: #bfbfbf;
`;

export default SingleProductView;
