import { createSlice } from "@reduxjs/toolkit";

const authState = {
  isLoading: false,
  isLoggedIn: false,
  invalidLogin: [],
  isEmailSendSuccess: {
    // email: "",
    // otp: "",
    // status: "",
    // message: "",
  },
  isEmailSendFailed: "",
  otpVerification: {},
  passwordChange: {},
};

const authenticationSlice = createSlice({
  name: "authentication",
  initialState: authState,
  reducers: {
    tryUserLogin: (state, action) => {
      state.isLoading = true;
      state.invalidLogin = "";
    },
    setUser: (state, action) => {
      state.isLoading = false;
      state.isLoggedIn = true;
      state.invalidLogin = action.payload;
    },
    setInvalidLogin: (state, action) => {
      console.log("action", action.payload);
      state.isLoading = false;
      state.invalidLogin = action.payload;
    },
    tryUserLogOut: (state, action) => {
      state.isLoggedIn = false;
      state.invalidLogin = "";
    },
    tryUserResetPasswordEmailSend: (state, action) => {
      state.isLoading = true;
    },
    resetPasswordEmailSendSuccess: (state, action) => {
      console.log("action", action);
      state.isLoading = false;
      state.isEmailSendSuccess = action.payload;
      // state.isEmailSendSuccess.email = action.payload.email;
      // state.isEmailSendSuccess.otp = action.payload.otp;
      // state.isEmailSendSuccess.status = action.payload.status;
      // state.isEmailSendSuccess.message = action.payload.message;
    },
    ClearPasswordEmailSend: (state, action) => {
      // console.log("action", action);
      state.isLoading = false;
      state.isEmailSendSuccess = {};
      // state.isEmailSendSuccess.email = "";
      // state.isEmailSendSuccess.otp = "";
      // state.isEmailSendSuccess.status = "";
      // state.isEmailSendSuccess.message = "";
    },
    resetPasswordEmailSendFailed: (state, action) => {
      state.isLoading = false;
      state.isEmailSendFailed = action.payload;
    },
    tyrFetchUserOtpVerify: (state) => {
      state.isLoading = true;
    },
    tyrFetchRecoverOtpVerify: (state) => {
      state.isLoading = true;
    },
    setUserOtpVerify: (state, action) => {
      state.isLoading = false;
      state.otpVerification = action.payload;
    },
    clearUserOtpVerify: (state, action) => {
      state.isLoading = false;
      state.otpVerification = {};
    },
    tryPasswordChange: (state) => {
      state.isLoading = true;
    },
    setPasswordChange: (state, action) => {
      state.isLoading = false;
      state.passwordChange = action.payload;
    },
    clearPasswordChange: (state, action) => {
      state.isLoading = false;
      state.passwordChange = {};
    },
    clearAuthMessages: (state) => {
      state.isLoading = false;
      state.invalidLogin = "";
      state.isEmailSendSuccess = "";
      state.isEmailSendFailed = "";
    },
  },
});

export const {
  tryUserLogin,
  setInvalidLogin,
  setUser,
  tryUserLogOut,
  tryUserResetPasswordEmailSend,
  resetPasswordEmailSendSuccess,
  resetPasswordEmailSendFailed,
  ClearPasswordEmailSend,
  tyrFetchUserOtpVerify,
  tyrFetchRecoverOtpVerify,
  setUserOtpVerify,
  clearUserOtpVerify,
  tryPasswordChange,
  setPasswordChange,
  clearPasswordChange,
  clearAuthMessages,
} = authenticationSlice.actions;

export default authenticationSlice.reducer;
