import React from "react";
import { Button, Modal } from "react-bootstrap";
// import { Row, Col, Modal } from "reactstrap";

function DeleteAisle(props) {
  return (
    <Modal show={props.openModal} onHide={() => props.setOpenModel(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete this warranty ?</Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={() => props.setOpenModel(false)}>
          Cancel
        </Button>
        <Button
          variant="danger"
          onClick={() => props.handleDeleteUom(props.id)}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
    // <Modal className="export-modal" isOpen={props.openModal}>
    //   <Row>
    //     <Col md={12} className="modal-head">
    //       <div>
    //         <h5>Are you sure you want to delete this Product </h5>
    //       </div>
    //       <div onClick={() => props.setOpenModel(false)}>
    //         <i class="bx bx-x"></i>
    //       </div>
    //     </Col>
    //   </Row>
    //   <Row>
    //     <Col md={12} className="declineModal">
    //       <button onClick={() => props.handleDeleteUom(props.id)}>Yes</button>
    //       <button onClick={() => props.setOpenModel(false)}>No</button>
    //     </Col>
    //   </Row>
    // </Modal>
  );
}

export default DeleteAisle;
