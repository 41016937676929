import { createSlice } from "@reduxjs/toolkit";

const InitState = {
  isLoading: false,
  salesPartnersTableData: [],
  serviceCenter: [],
  suppliers: [],
  salesPartnerDetailByID: [],
  batchDetailByID: [],
};

const VendorSlice = createSlice({
  name: "vendor",
  initialState: InitState,
  reducers: {
    tryfetchSalesPartnersList: (state) => {
      state.isLoading = true;
    },
    setSalesPartnersListData: (state, action) => {
      state.isLoading = false;
      state.salesPartnersTableData = action.payload;
    },
    tryfetchServiceCentersList: (state) => {
      state.isLoading = true;
    },
    setServiceCentersListData: (state, action) => {
      state.isLoading = false;
      state.serviceCenter = action.payload;
    },
    tryfetchSupplierList: (state) => {
      state.isLoading = true;
    },
    setSupplierListData: (state, action) => {
      state.isLoading = false;
      state.suppliers = action.payload;
    },
    tryfetchBatchDetailByID: (state) => {
      state.isLoading = true;
    },
    setBatchDetailByID: (state, action) => {
      state.isLoading = false;
      state.batchDetailByID = action.payload;
    },
    tryfetchSalesPartnerDetailByID: (state) => {
      state.isLoading = true;
    },
    setSalesPartnerDetailByID: (state, action) => {
      state.isLoading = false;
      state.salesPartnerDetailByID = action.payload;
    },
    clearSalesPartnerDetailByID: (state, action) => {
      state.isLoading = false;
      state.salesPartnerDetailByID = [];
    },
    clearBatchDetailsByID: (state) => {
      state.isLoading = false;
      state.batchDetailByID = [];
    },
  },
});

export const {
  tryfetchSalesPartnersList,
  setSalesPartnersListData,
  tryfetchBatchDetailByID,
  setBatchDetailByID,
  tryfetchSupplierList,
  setSupplierListData,
  tryfetchServiceCentersList,
  setServiceCentersListData,
  clearBatchDetailsByID,
  setSalesPartnerDetailByID,
  tryfetchSalesPartnerDetailByID,
  clearSalesPartnerDetailByID,
} = VendorSlice.actions;

export default VendorSlice.reducer;
