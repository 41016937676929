import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { IoClose, IoPrintOutline } from "react-icons/io5";
import { PiPencilSimple } from "react-icons/pi";
import { TfiTrash } from "react-icons/tfi";
import { Link } from "react-router-dom";
import styled from "styled-components";
import enlargedImg from "../../assets/Product/Png final.png";
import Img1 from "../../assets/Product/Rectangle 1054.png";
import Img2 from "../../assets/Product/Rectangle 1055.png";
import Img3 from "../../assets/Product/Rectangle 1056.png";
import Img4 from "../../assets/Product/Rectangle 1057.png";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import DeleteAisle from "./DeleteModal";
import { ToastContainer, toast } from "react-toastify";
import { DELETE_BATCH } from "../../config/BaseUrl";
import { tryfetchBatchList } from "../../Slices/BatchSlice";
import { apiPost } from "../../config/apiConfig";
import { IoIosArrowDown } from "react-icons/io";
const SingleSupplierView = ({ close, data, itemId }) => {
  const dispatch = useDispatch();
  const Data = useSelector((state) => state.OEM?.OEMDetailByID);
  const [selectedImage, setSelectedImage] = useState(enlargedImg);
  const [deleteModel, setdeleteModel] = useState(false);
  const [showData, setShowData] = useState(0);

  const handleMiniImgClick = (imgSrc) => {
    setSelectedImage(imgSrc);
  };
  const handleOpenDelete = (id) => {
    // setId(id);
    setdeleteModel(true);
  };
  const handleDelete = (id) => {
    let token = localStorage.getItem("token");
    var bodyFormData = new FormData();
    bodyFormData.append("token", token);
    bodyFormData.append("id", itemId);
    let resp = apiPost(DELETE_BATCH, bodyFormData);
    resp.then((resp) => {
      console.log("delete resp", resp);
      switch (resp.response.status) {
        case 200:
          dispatch(tryfetchBatchList());
          toast.success(resp.response.data.message);
          setTimeout(() => {
            setdeleteModel(false);
            close();
          }, 2000);
          break;

        default:
        // getData();
      }
    });
  };
  return (
    <>
      <ToastContainer />

      <RightBar>
        <Container>
          <Row>
            <Col md={8} className="d-flex gap-5">
              <div>
                <Heading style={{ fontWeight: "500" }} md={12}>
                  {Data?.oem_name}
                </Heading>
                {!Data?.partner_id === null ? (
                  <span>{Data?.partner_id}</span>
                ) : (
                  <span>PRT0123</span>
                )}
              </div>
              <div>
                {showData === 0 ? (
                  <Heading onClick={() => setShowData(1)}>
                    <BsToggleOff
                      size={25}
                      color="#a8a2a2"
                      style={{ cursor: "pointer", transition: "0.5s" }}
                    />
                  </Heading>
                ) : (
                  <Heading onClick={() => setShowData(0)}>
                    <BsToggleOn
                      size={25}
                      color="#0cb513"
                      style={{ cursor: "pointer", transition: "0.5s" }}
                    />
                  </Heading>
                )}
              </div>
            </Col>
            <Col md={4}>
              <Row className=" py-4">
                <IconContainer className="col-md-12">
                  <IconWrapper className="icons">
                    <IoPrintOutline />
                  </IconWrapper>
                  <Link to={`/OEM/Edit/${itemId}`}>
                    <IconWrapper className="icons">
                      <PiPencilSimple />
                    </IconWrapper>
                  </Link>
                  <IconWrapper className="icons" onClick={handleOpenDelete}>
                    <TfiTrash color="#ED2D22" />
                  </IconWrapper>
                  <IconWrapper
                    className="icons"
                    onClick={close}
                    style={{ border: "0.5px solid #ED2D22" }}
                  >
                    <IoClose color="#ED2D22" />
                  </IconWrapper>
                </IconContainer>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Row className="py-4">
                <Info md={12}>
                  <span>INFORMATION</span>
                  <span>
                    <IoIosArrowDown />
                  </span>
                </Info>
                <Line />
              </Row>
              <Row className="py-3">
                <Col md={4}>
                  <Key>ID</Key>
                  <Value>{data?.partner_id}</Value>
                </Col>
                <Col md={4}>
                  <Key>Email ID</Key>
                  <Value>{data?.email}</Value>
                </Col>
                <Col md={4}>
                  <Key>Contact</Key>
                  <Value>{data?.mobile}</Value>
                </Col>
              </Row>
              <Row className="py-3">
                <Col md={4}>
                  <Key>Address</Key>
                  <Value>{data?.address}</Value>
                </Col>
                <Col md={4}>
                  <Key>GST</Key>
                  <Value>{data?.gst_no}</Value>
                </Col>
                <Col md={4}>
                  <Key>PAN</Key>
                  <Value>{data?.pan_no}</Value>
                </Col>
              </Row>
              {/* <Row className="py-3">
                <Col md={4}>
                  <Key>Fax Number</Key>
                  <Value>{data?.fax}</Value>
                </Col>
                <Col md={4}>
                  <Key>GST</Key>
                  <Value>{data?.gst_number}</Value>
                </Col>
                <Col md={4}>
                  <Key>PAN</Key>
                  <Value>{data?.pan}</Value>
                </Col>
              </Row> */}
            </Col>
          </Row>
        </Container>
        <DeleteAisle
          openModal={deleteModel}
          setOpenModel={setdeleteModel}
          handleDeleteUom={handleDelete}
          id={itemId}
        />
      </RightBar>
    </>
  );
};
const RightBar = styled.div`
  width: 100%;
  padding: 2rem 2.5rem;
`;
const IconContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;
const EnlargedImg = styled.div`
  display: flex;
  width: 16rem;
  height: 16rem;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
const MiniImg = styled(Col)`
  display: flex;
  margin-top: 1em;
  width: 5rem;
  height: 5rem;
  justify-content: center;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
const IconWrapper = styled.div`
  border: 0.5px solid #aeaeae;
  border-radius: 5px;
  padding: 0.6em;
  display: flex;
  justify-content: space-around;
  cursor: pointer;
  height: min-content;
`;
const ProductName = styled.div`
  font-size: 20px;
  font-weight: 500;
`;
const SerialNumber = styled.div`
  font-size: 13px;
  margin-top: 1rem;
  color: #8b8b8b;
  font-weight: 500;
  // line-height: 15px;
`;
const Rating = styled.div`
  margin-top: 1rem;
  font-size: 11px;
  font-weight: 400;
`;
const Heading = styled.div`
  margin-top: 1rem;
  font-size: 20px;
  font-weight: 400;
`;
const About = styled.p`
  margin-top: 1rem;
  font-size: 11px;
  font-weight: 400;
`;
const Info = styled(Col)`
  margin-top: 2rem;
  // font-size: 11px;
  display: flex;
  font-weight: 400;
  justify-content: space-between;
  // width: 100;
`;
const Line = styled.hr`
  color: #bfbfbf;
`;
const Key = styled(Row)`
  font-weight: 300;
`;
const Value = styled(Row)`
  color: #bfbfbf;
`;

export default SingleSupplierView;
