import React, { useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
// import "ag-grid-enterprise";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  tryfetchProductsDetailByID,
  tryfetchProductsList,
} from "../../Slices/ProductSlice";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import { sideBar } from "../../Components/AgGrid/AggridFunctions";
import { MDBTable, MDBTableHead, MDBTableBody, MDBBtn } from "mdb-react-ui-kit";
import {
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
} from "mdb-react-ui-kit";
import SingleProductView from "./SingleProductView";
import HeaderComponent from "./HeaderComponent";
import {
  Page,
  PageContent,
  PageSingle,
  customCellStyle,
  customHeaderStyle,
  customHeaderStyleId,
  customPagination,
} from "../../Components/StyledComponents/Common";
import { apiPost } from "../../config/apiConfig";
import {
  PRODUC_TYPE,
  SETTINGS_TYPE,
  WARRANTY_LIST,
} from "../../config/BaseUrl";
import axios from "axios";
const ProductView = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const ListData = useSelector((state) => state?.product?.productTableData);
  const [filterText, setFilterText] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  // const [loading, setLoading] = useState(false);
  // const [totalRows, setTotalRows] = useState(0);
  // const [perPage, setPerPage] = useState(100000000);
  const [rowData, setRowData] = useState([]);
  const [itemId, setItemId] = useState("");

  const itemsPerPage = 10; // Set the number of items per page
  const totalPages = Math.ceil(ListData?.total_pages || 5);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const getPageNumbers = () => {
    const maxPagesToShow = 5;
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
    let startPage = currentPage - halfMaxPagesToShow;
    let endPage = currentPage + halfMaxPagesToShow;

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(totalPages, maxPagesToShow);
    } else if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, totalPages - maxPagesToShow + 1);
    }

    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  };
  // Get the current page data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // const currentItems = filteredData?.slice(indexOfFirstItem, indexOfLastItem);
  const currentItems = filteredData;
  const columnDefs = useMemo(() => [
    {
      field: "product_name",
      headerName: "Product Name",
      headerTooltip: "Product Name",
      width: "150px",
    },
    {
      field: "product_type_name",
      headerName: "Product Type",
      headerTooltip: "Product Type",
      width: "",
    },
    {
      field: "warranty_package_name",
      headerName: "Warranty Type",
      headerTooltip: "Warranty Type",
      width: "",
    },
    {
      field: "model_number",
      headerName: "Model No",
      headerTooltip: "Model No",
      width: "",
    },
    // {
    //   field: "description",
    //   headerName: "Description",
    //   headerTooltip: "Description",
    // },
    {
      field: "hsn_code",
      headerName: "HSN Code",
      headerTooltip: " HSN Code",
      width: "",
    },
    {
      field: "unit_name",
      headerName: "Unit",
      headerTooltip: " Unit",
      width: "",
    },
  ]);
  const columnDefs1 = useMemo(() => [
    {
      field: "product_name",
      headerName: "Product Name",
      headerTooltip: "Product Name",
      width: "",
    },
  ]);

  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      filter: true,
      sortable: true,
      resizable: true,
    }),
    []
  );

  const handleClear = () => {
    if (filterText) {
      // setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };
  // const handleSearch = (searchValue) => {
  //   setFilterText(searchValue);
  //   const searchTerm =
  //     searchValue && typeof searchValue === "string"
  //       ? searchValue.toLowerCase()
  //       : "";

  //   const filteredList = ListData?.filter((row) => {
  //     const name = row?.data?.product_name?.toLowerCase();
  //     return name && name.includes(searchTerm);
  //   });

  //   setFilteredData(filteredList);
  // };

  const handleSearch = (searchValue) => {
    setFilterText(searchValue);
    const filteredList = ListData?.products?.filter((row) => {
      const id = String(row?.product_id)?.toLowerCase(); // Convert to string
      const name = String(row?.product_name)?.toLowerCase(); // Convert to string
      const search = searchValue.toLowerCase();
      return id.includes(search) || name.includes(search);
    });
    setFilteredData(filteredList);
  };
  const handleRowClick = (row) => {
    console.log("rowdata", row);
    setRowData(row);
    setItemId(row.product_id);
    dispatch(tryfetchProductsDetailByID(row.product_id));
  };
  const resetItemId = () => {
    setItemId("");
  };
  // useEffect(() => {
  //   getData(1);
  // }, [filterText]);
  const cellClicked = (params) => {
    params.node.setSelected(true);
  };
  const handleAdd = () => {
    history(`/Product/Add`);
  };

  useEffect(() => {
    console.log("newpage", currentPage);
    var data = {
      pageNo: currentPage,
      itemPerPage: itemsPerPage,
    };
    dispatch(tryfetchProductsList(data));
  }, [currentPage, itemsPerPage]);

  useEffect(() => {
    setFilteredData(ListData?.products);
  }, [ListData]);

  return (
    <div className="d-flex" style={{ overflowY: "auto" }}>
      <Page $view={itemId}>
        <PageContent $singleView={itemId}>
          <ListHead>
            {/* <Title>Products</Title>
            <ListButtons>
              <Button onClick={handleAdd}>
                <i class="fa fa-plus" aria-hidden="true"></i> New
              </Button>
              <Button $primary>
                <i class="fa fa-cog" aria-hidden="true"></i>
              </Button>
            </ListButtons> */}
            <HeaderComponent
              onFilter={handleSearch}
              onClear={handleClear}
              handleAdd={handleAdd}
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              filterText={filterText}
              itemId={itemId}
            />
          </ListHead>
          {/* <ListGridTable className="ag-theme-alpine">
            <AgGridReact
              rowData={filteredData}
              columnDefs={itemId ? columnDefs1 : columnDefs}
              onRowClicked={handleRowClick}
              animateRows={true}
              gridStyle={gridStyle}
              pagination={true}
              paginationAutoPageSize={true}
              sideBar={itemId ? false : sideBar}
              defaultColDef={defaultColDef}
            ></AgGridReact>
          </ListGridTable> */}
          <>
            <div className="list-table" style={{ overflow: "auto" }}>
              {currentItems?.length > 0 ? (
                <MDBTable>
                  <MDBTableHead color="#4D5FFF" align="left">
                    <tr>
                      {!itemId ? (
                        columnDefs.length > 0 ? (
                          columnDefs?.map((colDef, index) => (
                            <th
                              style={customHeaderStyle}
                              key={colDef.field}
                              // scope="col"
                              // className="header-text"
                            >
                              {colDef.headerName}
                            </th>
                          ))
                        ) : (
                          columnDefs1?.map((colDef, index) => (
                            <th
                              className={itemId ? "px-5" : ""}
                              style={customHeaderStyleId}
                              key={colDef.field}
                              // scope="col"
                              // className="header-text"
                            >
                              {colDef.headerName}
                            </th>
                          ))
                        )
                      ) : (
                        <div className="d-flex justify-content-center">
                          No data found
                        </div>
                      )}
                    </tr>
                  </MDBTableHead>

                  <MDBTableBody align="left">
                    {currentItems.length === 0 ? (
                      <div className="d-flex justify-content-center">
                        No data found
                      </div>
                    ) : (
                      currentItems?.map((rowData) => (
                        <tr
                          key={rowData.id}
                          style={{ cursor: "pointer", borderRadius: "22px" }}
                          // style={{ borderRadius: "22px" }}
                        >
                          {!itemId
                            ? columnDefs?.map((colDef, index) => (
                                <td
                                  key={colDef.field}
                                  onClick={() => handleRowClick(rowData)}
                                  style={customCellStyle}
                                >
                                  {rowData[colDef.field]}
                                </td>
                              ))
                            : columnDefs1?.map((colDef, index) => (
                                <td
                                  className={itemId ? "px-5" : ""}
                                  key={colDef.field}
                                  onClick={() => handleRowClick(rowData)}
                                  style={customCellStyle}
                                >
                                  {rowData[colDef.field]}
                                </td>
                              ))}
                        </tr>
                      ))
                    )}
                  </MDBTableBody>
                </MDBTable>
              ) : (
                <div className="d-flex justify-content-center">
                  No data found
                </div>
              )}
            </div>

            {/* {!itemId ? ( */}
            {/* // <MDBPagination className="mt-3" end>
              //   <MDBPaginationItem disabled={currentPage === 1}>
              //     <MDBPaginationLink
              //       className="page-link"
              //       aria-label="Previous"
              //       onClick={() => handlePageChange(currentPage - 1)}
              //     >
              //       <span aria-hidden="true">&laquo;</span>
              //     </MDBPaginationLink>
              //   </MDBPaginationItem>
              //   {Array.from({ length: totalPages }, (_, i) => (
              //     <MDBPaginationItem key={i} active={i + 1 === currentPage}>
              //       <MDBPaginationLink
              //         className="page-link"
              //         onClick={() => handlePageChange(i + 1)}
              //       >
              //         {i + 1}
              //       </MDBPaginationLink>
              //     </MDBPaginationItem>
              //   ))}
              //   <MDBPaginationItem disabled={currentPage === totalPages}>
              //     <MDBPaginationLink
              //       className="page-link"
              //       aria-label="Next"
              //       onClick={() => handlePageChange(currentPage + 1)}
              //     >
              //       <span aria-hidden="true">&raquo;</span>
              //     </MDBPaginationLink>
              //   </MDBPaginationItem>
              // </MDBPagination>
              // <nav aria-label="..."> */}
            <MDBPagination className="py-3 gap-4 justify-content-end">
              <MDBPaginationItem disabled={currentPage === 1}>
                <MDBPaginationLink
                  style={{ border: "none", background: "none" }}
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <MdKeyboardArrowLeft />
                </MDBPaginationLink>
              </MDBPaginationItem>
              {getPageNumbers().map((page) => (
                <MDBPaginationItem key={page} active={page === currentPage}>
                  <MDBPaginationLink
                    className="page-link"
                    onClick={() => handlePageChange(page)}
                    style={{
                      backgroundColor: page === currentPage ? "black" : "white",
                      color: page === currentPage ? "white" : "black",
                      border: "1px solid #aeaeae",
                    }}
                  >
                    {page}
                  </MDBPaginationLink>
                </MDBPaginationItem>
              ))}
              <MDBPaginationItem disabled={currentPage === totalPages}>
                <MDBPaginationLink
                  onClick={() => handlePageChange(currentPage + 1)}
                  style={{ border: "none", background: "none" }}
                >
                  <MdKeyboardArrowRight />
                </MDBPaginationLink>
              </MDBPaginationItem>
            </MDBPagination>
            {/* ) : (
              ""
            )} */}
          </>
        </PageContent>
      </Page>
      {itemId ? (
        <PageSingle $singleView={itemId}>
          <PageContent>
            <SingleProductView
              close={resetItemId}
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              itemId={itemId}
              data={rowData}
            />
          </PageContent>
        </PageSingle>
      ) : (
        ""
      )}
    </div>
  );
};

// border-right: ${(props) =>
//   props.$singleView ? "0.5px solid #aeaeae;" : "none"};
const PageContent1 = styled.div`
  display: flex;
`;
const PageView = styled.div`
  display: flex;
`;
const ListHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border-bottom: 0.5px solid #bfbfbf;
  // padding: 0 11px 20px 15px;
  .header-text {
    color: "#4D5FFF"; // Default text color
    font-size: 25px; // Default font size
  }
`;
const Title = styled.h4`
  margin-bottom: 0;
`;
const ListButtons = styled.div`
  display: flex;
  gap: 1.5em;
  align-items: center;

  @media (max-width: 1366px) {
    gap: 1em;
  }
`;
const Button = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${(props) => (props.$primary ? "#ffffff" : "#3cba00")};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: ${(props) => (props.$primary ? "0.5px solid #aeaeae;" : "none")};
  padding: 0 1.5em;
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
  color: ${(props) => (props.$primary ? "black" : "white")};
  height: 40px;
  transition: all 0.3s ease-in-out;
  @media (max-width: 1366px) {
    padding: 0 1em;
  }
`;
const ListGridTable = styled.div`
  width: 100%;
  height: 70vh;
`;
export default ProductView;
