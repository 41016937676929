import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import closebtn from "../../assets/Images/close.png";
import { useSelector } from "react-redux";

const NotificationDropdown = ({ closeDropdown }) => {
  const dropdownRef = useRef();
  const notificationData = useSelector(
    (state) => state.header?.notificationsListData.notif
  );

  const [notifications, setnotifications] = useState([]);

  useEffect(() => {
    setnotifications(notificationData);
  }, [notificationData]);

  //   close on ouside click
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [closeDropdown]);

  return (
    <DropdownContainer ref={dropdownRef}>
      <DropdownHeader>
        <DropdownHeading>Notifications</DropdownHeading>
        <CloseButton onClick={closeDropdown}>
          <img src={closebtn} alt="" srcSet="" />
        </CloseButton>
      </DropdownHeader>
      <NotificationList>
        {notifications?.length === 0 ? (
          <NoNotifications>No notifications</NoNotifications>
        ) : (
          notifications?.map((notification) => (
            <NotificationItem key={notification.id}>
              <NotificationDetails>
                <NotificationDescription>
                  <NotificationHeading>
                    {notification.title}
                  </NotificationHeading>
                  {notification.description}
                </NotificationDescription>
              </NotificationDetails>
              <NotificationTime>{notification?.time}</NotificationTime>
            </NotificationItem>
          ))
        )}
      </NotificationList>
    </DropdownContainer>
  );
};

NotificationDropdown.propTypes = {
  closeDropdown: PropTypes.func.isRequired,
  notifications: PropTypes.array.isRequired,
};
const DropdownContainer = styled.div`
  position: absolute;
  top: 5.3125em;
  right: 8em;
  width: 300px;
  max-height: 40vh;
  overflow: auto;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 22px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  z-index: 10;
`;

const DropdownHeader = styled.div`
  display: flex;
  justify-content: space-between;
  /* padding: 20px; */
  border-bottom: 1px solid #ddd;
  padding: 2rem 1.5rem;
`;

const DropdownHeading = styled.h3`
  margin: 0;
  font-size: 18px;
  color: #000000;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 16px;
  color: #888;
  cursor: pointer;
`;

const NotificationList = styled.ul`
  list-style: none;
  /* padding: 10px; */
  margin: 0;
`;

const NotificationItem = styled.li`
  /* padding: 10px; */
  border-bottom: 1px solid #ddd;
`;

const NotificationDetails = styled.div`
  display: flex;
  /* justify-content: space-between; */
`;

const NotificationHeading = styled.p`
  margin: 0;
  font-size: 13px;
  font-weight: bold;
`;

const NotificationTime = styled.p`
  margin: 0;
  font-size: 10px;
  color: #888;
  /* position: absolute; */
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
  align-content: flex-end;
  align-items: flex-end;
`;
const NotificationDescription = styled.div`
  font-size: 13px;
  margin: 0;
  font-weight: normal;
`;

const NoNotifications = styled.p`
  padding: 10px;
  margin: 0;
  text-align: center;
  color: #888;
`;

export default NotificationDropdown;
