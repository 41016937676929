import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Rectangle from "../../assets/Images/Rectangle.png";
import { tryUserLogOut } from "../../Slices/AuthenticationSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SaveButton } from "../StyledComponents/Buttons";
import { AiFillEdit } from "react-icons/ai";
import { IoMdLogOut } from "react-icons/io";
import { FaRegCircleUser } from "react-icons/fa6";
import {
  clearOEMProfileByID,
  tryfetchOEMDetailByID,
} from "../../Slices/OEMSlice";

const ProfileModal = ({ closeModal }) => {
  const profileData = useSelector((state) => state.OEM?.OEMProfileByID);

  let data = localStorage.getItem("userdetails");
  let roleName = localStorage.getItem("roleName");
  const [userData, setUserData] = useState([]);
  const getUserDetails = () => {
    if (roleName !== "admin") {
      let objectData = JSON?.parse(data);
      setUserData(objectData);
      // console.log("editProfile", objectData);
    }
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch(clearOEMProfileByID());
    dispatch(tryUserLogOut({ navigate }));
  };
  const editProfile = () => {
    navigate(`OEM/Edit/${userData?.id}`);
    // dispatch(tryfetchOEMDetailByID(userData?.id));
    closeModal();
  };
  const modalContentRef = useRef();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        modalContentRef.current &&
        !modalContentRef.current.contains(event.target)
      ) {
        closeModal();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [closeModal]);
  useEffect(() => {
    getUserDetails();
  }, [data]);

  return (
    <ModalOverlay id="profile-div" ref={modalContentRef}>
      <ModalContent>
        <TopSection>
          {/* Rectangle background image */}
          <BackgroundImage src={Rectangle} />
          {/* Circular profile picture */}
          <ProfilePicture>
            {profileData.image ? (
              <img src={profileData?.image} alt="Profile" />
            ) : (
              <FaRegCircleUser size={80} color="4d5fff" />
            )}
          </ProfilePicture>
          {/* Name and User ID */}
          <UserInfo>
            <UserName>
              {roleName === "admin" ? "Admin" : profileData?.display_name}
            </UserName>
            <UserId>
              {roleName === "admin" ? "admin@gmail.com" : profileData?.email}
            </UserId>
          </UserInfo>
        </TopSection>
        {/* Buttons */}
        <BottomSection>
          {roleName !== "admin" ? (
            <SaveButton
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "3px",
                alignItems: "center",
              }}
              onClick={editProfile}
            >
              {" "}
              <AiFillEdit />
              Edit Profile
            </SaveButton>
          ) : (
            ""
          )}
          <Button onClick={handleLogout}>
            {" "}
            <IoMdLogOut />
            Logout
          </Button>
        </BottomSection>
      </ModalContent>
    </ModalOverlay>
  );
};

const ModalOverlay = styled.div`
  position: absolute;
  top: 5.3125em;
  right: 60px;
  z-index: 10;
`;

const ModalContent = styled.div`
  background: #fff;
  border-radius: 10px;
  width: 300px;
  //   padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const TopSection = styled.div`
  position: relative;
  height: 200px; /* Adjust the height as needed */
`;

const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: url(${(props) => props.src}) center/cover;
`;

const ProfilePicture = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const UserInfo = styled.div`
  position: absolute;
  margin-top: 2rem;
  top: 90%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
`;

const UserName = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const UserId = styled.div`
  font-size: 14px;
  color: #777;
`;

const BottomSection = styled.div`
  margin-top: 8rem;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  padding: 2rem;
`;

const Button = styled.button`
  background: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  border: 1px solid #000000;
  padding: 0.8em 6em 0.8em 6em;
  border-radius: 4px;
  font-size: 11px;
  font-family: "Public Sans", sans-serif;
  font-weight: 400;
  color: #000000;
  transition: 0.2s ease;
  &:hover {
    color: #000000;
    background: #ffffff;
    border: 1px solid #000000;
  }
`;

export default ProfileModal;
