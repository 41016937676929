import { put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { apiGet, apiPost } from "../config/apiConfig.js";
import {
  setSubscriptionDetailByID,
  setSubscriptionListData,
  setFilteredSubscriptionListData,
  tryfetchSubscriptionDetailByID,
  tryfetchSubscriptionList,
  tryfetchFilteredSubscriptionList,
  setPackageListData,
  tryfetchPackageList,
} from "../Slices/SubscriptionSlice.jsx";
import {
  FILTER_BATCH,
  PACKAGE_LIST,
  SINGLE_BATCH_DETAILS,
  SUBSCRIPTION_LIST,
} from "../config/BaseUrl.js";

function* fetchSubscriptionListData() {
  // console.log("PRODUCT_LIST started");
  let token = localStorage.getItem("token");

  try {
    var bodyFormData = new FormData();
    // bodyFormData.append("token", token);
    let resp = yield apiGet(SUBSCRIPTION_LIST, bodyFormData);
    // console.log("SUBSCRIPTION_LIST", resp);
    if (resp.response.status === 200) {
      yield put(setSubscriptionListData(resp.response.data.data));
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.message;
    } else {
      console.log("unexpected error: ", error);
      // return yield put(setUserListData());
    }
  }
}
function* fetchPackageListData() {
  // console.log("PRODUCT_LIST started");
  let token = localStorage.getItem("token");

  try {
    var bodyFormData = new FormData();
    bodyFormData.append("token", token);
    let resp = yield apiPost(PACKAGE_LIST, bodyFormData);
    console.log("package list data", resp.response.data);
    if (resp.response.status === 200) {
      yield put(setPackageListData(resp.response.data.products));
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.message;
    } else {
      console.log("unexpected error: ", error);
      // return yield put(setUserListData());
    }
  }
}
function* fetchSubscriptionDetailByID(data) {
  console.log("fetchSubscriptionDetailByIDPRODUCT_LIST started");
  let token = localStorage.getItem("token");
  try {
    var bodyFormData = new FormData();
    bodyFormData.append("token", token);
    bodyFormData.append("id", data.payload);
    let resp = yield apiPost(SINGLE_BATCH_DETAILS, bodyFormData);
    console.log("SINGLE_WARRANTY_DETAILS", resp);
    if (resp.response.status === 200) {
      yield put(setSubscriptionDetailByID(resp.response.data.batch));
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.message;
    } else {
      console.log("unexpected error: ", error);
      // return yield put(setUserListData());
    }
  }
}
function* fetchSubscriptionFilteredData(data) {
  // console.log("PRODUCT_LIST started");
  let token = localStorage.getItem("token");

  try {
    var bodyFormData = new FormData();
    bodyFormData.append("token", token);
    bodyFormData.append("product_type", data?.payload?.selectedProductType);
    bodyFormData.append("warranty_type", data?.payload?.selectedWarrantyType);
    let resp = yield apiPost(FILTER_BATCH, bodyFormData);
    console.log("FILTER_BATCH", resp);
    if (resp.response.status === 200) {
      yield put(setFilteredSubscriptionListData(resp?.response?.data?.data));
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.message;
    } else {
      console.log("unexpected error: ", error);
      // return yield put(setUserListData());
    }
  }
}
export default function* SubscriptionSaga() {
  yield takeEvery(
    tryfetchFilteredSubscriptionList,
    fetchSubscriptionFilteredData
  );
  yield takeEvery(tryfetchPackageList, fetchPackageListData);
  yield takeEvery(tryfetchSubscriptionList, fetchSubscriptionListData);
  yield takeEvery(tryfetchSubscriptionDetailByID, fetchSubscriptionDetailByID);
}
