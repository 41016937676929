import { createSlice } from "@reduxjs/toolkit";

const InitState = {
  isLoading: false,
  batchTableData: [],
  batchDetailByID: [],
  batchFilter: [],
};

const BatchSlice = createSlice({
  name: "batch",
  initialState: InitState,
  reducers: {
    tryfetchBatchList: (state) => {
      state.isLoading = true;
      state.batchFilter = [];
    },
    setBatchListData: (state, action) => {
      state.isLoading = false;
      state.batchTableData = action.payload;
    },
    tryfetchFilteredBatchList: (state, action) => {
      state.isLoading = true;
      state.batchFilter = action.payload;
    },
    setFilteredBatchListData: (state, action) => {
      state.isLoading = false;
      state.batchTableData = action.payload;
    },
    tryfetchBatchDetailByID: (state) => {
      state.isLoading = true;
    },
    setBatchDetailByID: (state, action) => {
      state.isLoading = false;
      state.batchDetailByID = action.payload;
    },
    clearBatchDetailsByID: (state) => {
      state.isLoading = false;
      state.batchDetailByID = [];
      state.batchTableData = [];
    },
  },
});

export const {
  tryfetchBatchList,
  setBatchListData,
  tryfetchBatchDetailByID,
  setBatchDetailByID,
  clearBatchDetailsByID,
  tryfetchFilteredBatchList,
  setFilteredBatchListData,
} = BatchSlice.actions;

export default BatchSlice.reducer;
