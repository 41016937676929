import React, { useState } from "react";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
} from "@mui/material";
import styled from "styled-components";
import { useRef } from "react";
import {
  SaveButton,
  SubmitButton,
} from "../../Components/StyledComponents/Buttons";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import {
  ADD_PRODUCT,
  SETTINGS_TYPE,
  UPDATE_PRODUCT,
  WARRANTY_LIST,
} from "../../config/BaseUrl";
import { apiPost } from "../../config/apiConfig";
import { useEffect } from "react";
import {
  HeadSubTitle,
  HeadTitle,
  InputStyle,
  selectStyle,
} from "../../Components/StyledComponents/Common";
import {
  clearProductsDetailsByID,
  tryfetchProductsDetailByID,
} from "../../Slices/ProductSlice";
import { useDispatch, useSelector } from "react-redux";
import { IoAddCircleOutline } from "react-icons/io5";
import { CiImageOn } from "react-icons/ci";
import { RiArrowDropDownLine } from "react-icons/ri";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const ImageInputPreview = ({ file }) => {
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (file) {
      fileInputRef.current.src =
        typeof file === "string" ? file : URL.createObjectURL(file);
    }
  }, [file]);

  return (
    <div style={{ position: "relative", height: "100%", width: "100%" }}>
      <ImageFile ref={fileInputRef} alt="Selected Image" />
    </div>
  );
};

const ImageInputSection = ({
  placeholder,
  inputIndex,
  file,
  handleFileChange,
}) => {
  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="col-md-6 px-2 d-flex justify-content-center">
      <TextField
        fullWidth
        value=""
        onClick={handleClick}
        sx={{ display: "none" }}
      />
      <TextLabel onClick={handleClick} style={{ padding: 0 }}>
        {file ? (
          <ImageInputPreview file={file} />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CiImageOn size={20} />
            <span style={{ padding: "0 15px " }}>{placeholder}</span>
          </div>
        )}
      </TextLabel>
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={(e) => {
          handleFileChange(e, inputIndex);
        }}
      />
    </div>
  );
};

const AddProduct = () => {
  const dispatch = useDispatch();
  const ProductData = useSelector((state) => state?.product);
  const navigate = useNavigate();
  const params = useParams();
  const [IsSaving, setIsSaving] = useState(false);
  const [taxPref, setTaxPref] = useState([]);
  const [productType, setProductType] = useState([]);
  const [unit, setUnit] = useState([]);
  const [warranty, setWarranty] = useState([]);
  const [file, setFile] = useState(Array.from({ length: 4 }, () => []));
  const [features, setFeatures] = useState([""]);
  const [values, setValues] = useState({
    description: "",
    feature: "",
    features: "",
    hsn_code: "",
    image: "",
    image2: "",
    image3: "",
    image4: "",
    is_active: "",
    manufacture_id: "",
    manufacture_name: "",
    model_number: "",
    oem_id: "",
    product_id: "",
    warranty_type: "",
    product_name: "",
    product_type: "",
    product_type_name: "",
    tax_name: "",
    tax_preference: "",
    unit: "",
    unit_name: "",
    updated_at: "",
    warranty_package_name: "",
  });
  const [error, setError] = useState({
    product_name: false,
  });
  const handleInputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    if (e.target.name === "product_name") {
      if (e.target.value.length > 20) {
        setError({ ...error, product_name: true });
      } else {
        setError({ ...error, product_name: false });
      }
    }
  };

  const addProduct = async (e) => {
    setIsSaving(true);
    try {
      var token = localStorage.getItem("token");
      var bodyFormData = new FormData();
      bodyFormData.append("token", token);
      bodyFormData.append("product_name", values.product_name);
      bodyFormData.append("model_number", values.model_number);
      bodyFormData.append("manufacture_id", values.manufacture_id);
      bodyFormData.append("manufacture_name", values.manufacture_name);
      bodyFormData.append("description", values.description);
      bodyFormData.append("product_type", values.product_type);
      bodyFormData.append("hsn_code", values.hsn_code);
      bodyFormData.append("unit", values.unit);
      bodyFormData.append("warranty_type", values.warranty_type);
      bodyFormData.append("tax_preference", values.tax_preference);
      bodyFormData.append("features", features);
      // features?.map((feature, i) => {
      //   bodyFormData.append(`feature[${i}][name]`, feature);
      // });
      if (file[0] && file[0][0]) {
        console.log("fileee", file[0]?.[0]);
        console.log("fileee", file[1]?.[0]);
        console.log("fileee", file[2]?.[0]);
        console.log("fileee", file[3]?.[0]);
        if (file[0]?.[0]) bodyFormData.append("file", file[0]?.[0]);
        if (file[1]?.[0]) bodyFormData.append("file2", file[1]?.[0]);
        if (file[2]?.[0]) bodyFormData.append("file3", file[2]?.[0]);
        if (file[3]?.[0]) bodyFormData.append("file4", file[3]?.[0]);
      } else {
        // Handle the case where file[0] or file[0][0] is undefined or empty
        console.error("File is not properly set");
      }
      let resp = await apiPost(ADD_PRODUCT, bodyFormData);
      console.log(" formdata isprdt ", resp);
      if (
        resp.response.data.status === true
        // resp.response.data.message === "User Added Successfully"
      ) {
        toast.success(resp.response.data.message);
        setTimeout(() => {
          navigate("/Product-List");
          setValues({});
          setIsSaving(false);
        }, 2000);
      } else {
        toast.error(resp.response.data.message);
        setIsSaving(false);
      }
    } catch (error) {
      setIsSaving(false);
      toast.error(error.message);
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  };
  const updateProduct = async (e) => {
    setIsSaving(true);
    try {
      var token = localStorage.getItem("token");
      var bodyFormData = new FormData();
      bodyFormData.append("token", token);
      bodyFormData.append("product_id", params.id);
      bodyFormData.append("product_name", values.product_name);
      bodyFormData.append("model_number", values.model_number);
      bodyFormData.append("manufacture_id", values.manufacture_id);
      bodyFormData.append("manufacture_name", values.manufacture_name);
      bodyFormData.append("description", values.description);
      bodyFormData.append("product_type", values.product_type);
      bodyFormData.append(
        "hsn_code",
        values.hsn_code === null ? "" : values?.hsn_code
      );
      bodyFormData.append("unit", values.unit);
      bodyFormData.append("warranty_type", values.warranty_type);
      bodyFormData.append("tax_preference", values.tax_preference);
      bodyFormData.append("features", features);
      // features?.map((feature, i) => {
      //   bodyFormData.append(`feature[${i}][name]`, feature);
      // });
      if (file[0] && file[0][0]) {
        console.log("fileee", file);
        if (file[0]?.[0]) bodyFormData.append("file", file[0][0]);
        if (file[1]?.[0]) bodyFormData.append("file2", file[1][0]);
        if (file[2]?.[0]) bodyFormData.append("file3", file[2][0]);
        if (file[3]?.[0]) bodyFormData.append("file4", file[3][0]);
      } else {
        // Handle the case where file[0] or file[0][0] is undefined or empty
        console.error("File is not properly set");
      }
      // console.log(" formdata is ", bodyFormData);
      let resp = await apiPost(UPDATE_PRODUCT, bodyFormData);
      if (
        resp.response.data.status === true
        // resp.response.data.message === "User Added Successfully"
      ) {
        toast.success(resp.response.data.message);
        setTimeout(() => {
          navigate("/Product-List");
          setValues({});
          setIsSaving(false);
        }, 1000);
      } else {
        toast.error(resp.response.data.message);
        setIsSaving(false);
      }
    } catch (error) {
      setIsSaving(false);
      toast.error(error.message);
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  };
  useEffect(() => {
    if (params?.type === "Edit") {
      dispatch(tryfetchProductsDetailByID(params.id));
    }
  }, [params]);

  useEffect(() => {
    getUnit();
    getProductType();
    getWarranty();
    getTaxPref();
    if (ProductData?.productDetailByID?.length !== 0) {
      setValues(ProductData?.productDetailByID);

      // Extract image information from the response and set it in the 'file' state
      const productImages = [
        ProductData?.productDetailByID?.image,
        ProductData?.productDetailByID?.image2,
        ProductData?.productDetailByID?.image3,
        ProductData?.productDetailByID?.image4,
      ].filter((img) => img !== null && img !== undefined);

      setFile(productImages.map((image) => [image]));
      setFeatures(ProductData?.productDetailByID?.features);
    }
  }, [ProductData?.productDetailByID]);

  const handleFileChange = (e, inputIndex) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile((prevFiles) => {
        const newFileInputs = [...prevFiles];
        newFileInputs[inputIndex] = [selectedFile];
        return newFileInputs;
      });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (params.type === "Add") {
      addProduct();
    } else {
      updateProduct();
    }
  };
  const handleCancel = () => {
    navigate(-1);
  };
  const productTypes = productType?.map((item) => {
    // console.log("tyep", item);
    return {
      key: item.id,
      label: item.name,
      value: item.id,
    };
  });
  const unitOptions = unit?.map((item) => {
    // console.log("unit", item);
    return {
      key: item.id,
      label: item.name,
      value: item.id,
    };
  });
  const warrantyOptions = warranty?.map((item) => {
    // console.log("warranty_package_name", item);
    return {
      key: item.id,
      label: item.warranty_package_name,
      value: item.id,
    };
  });
  const taxPrefOptions = taxPref?.map((item) => {
    // console.log("unit", item);
    return {
      key: item.id,
      label: item.name,
      value: item.id,
    };
  });
  async function getUnit() {
    var token = localStorage.getItem("token");
    try {
      var bodyFormData = new FormData();
      bodyFormData.append("token", token);
      bodyFormData.append("type", "Unit");
      let resp = await apiPost(SETTINGS_TYPE, bodyFormData);
      // console.log("uniy", resp);
      if (resp.response.status === 200) {
        setUnit(resp.response.data.Details);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.message;
      } else {
        console.log("unexpected error: ", error);
        // return yield put(setUserListData());
      }
    }
  }
  async function getWarranty() {
    var token = localStorage.getItem("token");
    try {
      var bodyFormData = new FormData();
      bodyFormData.append("token", token);
      // bodyFormData.append("type", "Warranty");
      let resp = await apiPost(WARRANTY_LIST, bodyFormData);
      // console.log("Warranty", resp);
      if (resp.response.status === 200) {
        setWarranty(resp.response.data.warranty);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.message;
      } else {
        console.log("unexpected error: ", error);
        // return yield put(setUserListData());
      }
    }
  }
  async function getProductType() {
    var token = localStorage.getItem("token");
    try {
      var bodyFormData = new FormData();
      bodyFormData.append("token", token);
      bodyFormData.append("type", "product_type");
      let resp = await apiPost(SETTINGS_TYPE, bodyFormData);
      // console.log("PRODUC_TYPE", resp);
      if (resp?.response?.status === 200) {
        setProductType(resp?.response?.data?.Details);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.message;
      } else {
        console.log("unexpected error: ", error);
      }
    }
  }
  async function getTaxPref() {
    var token = localStorage.getItem("token");
    try {
      var bodyFormData = new FormData();
      bodyFormData.append("token", token);
      bodyFormData.append("type", "Tax Preference");
      let resp = await apiPost(SETTINGS_TYPE, bodyFormData);
      // console.log("TaxPreference", resp);
      if (resp?.response?.status === 200) {
        setTaxPref(resp?.response?.data?.Details);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.message;
      } else {
        console.log("unexpected error: ", error);
      }
    }
  }

  useEffect(() => {
    if (params?.type == "Add") {
      setValues({
        description: "",
        feature: "",
        features: "",
        hsn_code: "",
        image: "",
        image2: "",
        image3: "",
        image4: "",
        is_active: "",
        manufacture_id: "",
        manufacture_name: "",
        model_number: "",
        oem_id: "",
        product_id: "",
        product_name: "",
        product_type: "",
        product_type_name: "",
        tax_name: "",
        tax_preference: "",
        unit: "",
        unit_name: "",
        updated_at: "",
        warranty_package_name: "",
      });
      setFile([]);
      setFeatures([""]);
    }
  }, [params]);

  // useEffect(() => {
  //   console.log("file values", file);
  // }, [file]);

  useEffect(() => {
    return () => {
      setValues({});
      dispatch(clearProductsDetailsByID());
      setFile([]);
      setFeatures([""]);
    };
  }, []);

  // const handleKeyPress = (event) => {
  //   const keyCode = event.which || event.keyCode;
  //   const isNumber = keyCode >= 48 && keyCode <= 57;

  //   if (isNumber) {
  //     event.preventDefault();
  //   }
  // };

  const addFeature = () => {
    setFeatures((prevFeatures) => [...prevFeatures, ""]);
  };

  const handleFeatureChange = (index, value) => {
    const updatedFeatures = [...features];
    updatedFeatures[index] = value;
    setFeatures(updatedFeatures);
  };
  // useEffect(() => {
  //   console.log("features edit", features);
  // }, [features]);

  return (
    <div className="page">
      <ToastContainer />
      <div className="page-inner-content">
        <div className="row">
          <HeadTitle className="col-md-12">
            <span>
              {params.type == "Add" ? "Add New Product" : "Edit Product"}
            </span>
          </HeadTitle>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-4">
              <div className="row mb-5">
                <div className="col-md-12 px-2">
                  <TextField
                    label="Product Name"
                    variant="outlined"
                    fullWidth
                    // onKeyPress={handleKeyPress}
                    required
                    sx={InputStyle}
                    name="product_name"
                    value={values?.product_name}
                    onChange={handleInputChange}
                    error={error.product_name}
                    helperText={
                      error.product_name ? "Maximum Length exceeded!" : ""
                    }
                  />
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-md-12 px-2">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Product Type *
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      IconComponent={(props) => (
                        <ExpandMoreIcon size={25} {...props} />
                      )}
                      value={values?.product_type}
                      sx={selectStyle}
                      label=" Product Type"
                      required
                      name="product_type"
                      onChange={handleInputChange}
                    >
                      {productTypes?.map((type) => (
                        <MenuItem
                          key={type.value}
                          value={type.value}
                          selected={type.value === values?.product_type}
                        >
                          {type.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-md-12 px-2">
                  <TextField
                    label="Description"
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows="3"
                    sx={InputStyle}
                    name="description"
                    value={values?.description}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <HeadSubTitle>
                <span>Product Images</span>
              </HeadSubTitle>
              <div className="row mb-5 d-flex">
                <ImageInputSection
                  placeholder={
                    <div>
                      Drop your image here or select{" "}
                      <span style={{ color: "#4D5FFF" }}>Click to browse</span>
                    </div>
                  }
                  inputIndex={0}
                  // onChange={handleFileChange}
                  file={file[0]?.[0]}
                  handleFileChange={handleFileChange}
                />
                {/* </div>
             <div className="row mb-5"> */}
                <ImageInputSection
                  placeholder={
                    <div>
                      Drop your image here or select{" "}
                      <span style={{ color: "#4D5FFF" }}>Click to browse</span>
                    </div>
                  }
                  inputIndex={1}
                  // onChange={handleFileChange}
                  file={file[1]?.[0]}
                  handleFileChange={handleFileChange}
                />
              </div>
              <div className="row mb-5 d-flex">
                <ImageInputSection
                  placeholder={
                    <div>
                      Drop your image here or select{" "}
                      <span style={{ color: "#4D5FFF" }}>Click to browse</span>
                    </div>
                  }
                  inputIndex={2}
                  // onChange={handleFileChange}
                  file={file[2]?.[0]}
                  handleFileChange={handleFileChange}
                />
                {/* </div>
                 < div className="row mb-5"> */}
                <ImageInputSection
                  placeholder={
                    <div>
                      Drop your image here or select{" "}
                      <span style={{ color: "#4D5FFF" }}>Click to browse</span>
                    </div>
                  }
                  inputIndex={3}
                  // onChange={handleFileChange}
                  file={file[3]?.[0]}
                  handleFileChange={handleFileChange}
                />
              </div>
            </div>
            <Divider className="col-md-7" />
            <div className="col-md-7">
              <HeadSubTitle>
                <span>Product Details</span>
              </HeadSubTitle>
              <div className="row my-5">
                <div className="col-md-6 px-2">
                  <TextField
                    label="Model Number "
                    variant="outlined"
                    fullWidth
                    name="model_number"
                    required
                    sx={InputStyle}
                    value={values?.model_number}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-6 px-2">
                  <TextField
                    label="HSN Code"
                    variant="outlined"
                    fullWidth
                    sx={InputStyle}
                    name="hsn_code"
                    // required
                    value={values?.hsn_code}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="row my-5">
                <div className="col-md-6 px-2">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Unit *
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={values?.unit}
                      sx={selectStyle}
                      label=" Unit"
                      IconComponent={(props) => (
                        <ExpandMoreIcon size={25} {...props} />
                      )}
                      name="unit"
                      required
                      onChange={handleInputChange}
                    >
                      {unitOptions?.map((type) => (
                        <MenuItem key={type.value} value={type.value}>
                          {type.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-md-6 px-2">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Warranty Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={values?.warranty_type}
                      IconComponent={(props) => (
                        <ExpandMoreIcon size={25} {...props} />
                      )}
                      label=" Warranty Type"
                      name="warranty_type"
                      sx={selectStyle}
                      onChange={handleInputChange}
                    >
                      {warrantyOptions?.map((type) => (
                        <MenuItem key={type.value} value={type.value}>
                          {type.label}
                        </MenuItem>
                      ))}
                      {/* <MenuItem value={1}>Lifetime warranties</MenuItem>
                      <MenuItem value={2}>Factory warranties</MenuItem>
                      <MenuItem value={3}>Extended warranty</MenuItem> */}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="row my-5">
                {/* <div className="col-md-6 px-2">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Tax Preference
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={values?.tax_preference}
                      name="tax_preference"
                      label=" Tax Preference"
                      onChange={handleInputChange}
                    >
                      {taxPrefOptions?.map((type) => (
                        <MenuItem key={type.value} value={type.value}>
                          {type.label}
                        </MenuItem>
                      ))}
               
                    </Select>
                  </FormControl>
                </div> */}
                {/* <div className="col-md-6 px-2">
                  <TextField
                    label="Manufacturer Name"
                    variant="outlined"
                    fullWidth
                    required
                    name="manufacture_name"
                    sx={{
                      borderRadius: "22px", // Your desired border radius
                      // padding: "10px",
                      // Add other styles as needed
                    }}
                    value={values?.manufacture_name}
                    onChange={handleInputChange}
                  />
                </div> */}
                {/* </div>

              <div className="row my-5"> */}
                {/* <div className="col-md-6 px-2">
                  <TextField
                    label="Manufacture Id"
                    variant="outlined"
                    required
                    type="number"
                    fullWidth
                    name="manufacture_id"
                    sx={{
                      borderRadius: "22px", // Your desired border radius
                      // padding: "10px",
                      // Add other styles as needed
                    }}
                    value={values?.manufacture_id}
                    onChange={handleInputChange}
                  />
                </div> */}
              </div>
              <HeadSubTitle className="d-flex justify-content-between">
                <span>Features</span>
                {/* <div className="row my-3"> */}
                {/* <div className="col-md-12 d-flex justify-content-end"> */}
                <IconButton
                  onClick={addFeature}
                  color="primary"
                  aria-label="add"
                >
                  <IoAddCircleOutline />
                </IconButton>
                {/* </div> */}
                {/* </div> */}
              </HeadSubTitle>
              <div className="row my-5">
                {features.map((feature, index) => (
                  <div className="col-md-6 px-2 py-4" key={index}>
                    <TextField
                      label={`Feature ${index + 1}`}
                      variant="outlined"
                      fullWidth
                      sx={InputStyle}
                      value={feature}
                      onChange={(e) =>
                        handleFeatureChange(index, e.target.value)
                      }
                    />
                  </div>
                ))}
              </div>
              {/* <div className="row my-5">
              <div className="col-md-6 px-2">
                <TextField
                  label="Model Number"
                  variant="outlined"
                  fullWidth
                  name="ModelNo"
                  sx={{
                    borderRadius: "22px", // Your desired border radius
                    // padding: "10px",
                    // Add other styles as needed
                  }}
                  value={values?.productName}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-6 px-2">
                <TextField
                  label="HSN Code"
                  variant="outlined"
                  fullWidth
                  name="productName"
                  sx={{
                    borderRadius: "22px", // Your desired border radius
                    // padding: "10px",
                    // Add other styles as needed
                  }}
                  value={values?.productName}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="row my-5">
              <div className="col-md-6 px-2">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Unit</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={values?.age}
                    label=" Unit"
                    onChange={handleInputChange}
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-md-6 px-2">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Warranty Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={values?.age}
                      label=" Warranty Type"
                      onChange={handleInputChange}
                    >
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                  </FormControl>
              </div>
            </div> */}
            </div>
            <div className="row">
              <div className="col-md-12 buttons d-flex justify-content-end px-2">
                <SaveButton
                  type="button"
                  className="mx-5 save-button"
                  onClick={handleCancel}
                >
                  Cancel
                </SaveButton>
                <SubmitButton
                  type="submit"
                  className="submit-button"
                  disabled={IsSaving}
                >
                  {params.type == "Add" ? "Add Product" : "Update Product"}
                </SubmitButton>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const TextLabel = styled.div`
  position: relative;
  cursor: pointer;
  font-size: 12px;
  // user-select: none;
  text-align: center;
  padding: 15px;
  color: #aaaaaa;
  border: 2px dotted rgb(206, 212, 218);
  border-radius: 11px;
  width: 169px;
  min-height: 135px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2em;

  .placeholder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const ImageFile = styled.img`
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  object-fit: cover;
  border-radius: 11px;
`;
const Divider = styled.div`
  width: 1px;
  // height: 100%;
  margin: 0 2em;
  padding: 0;
  background-color: #ccc; /* Line color */
`;
export default AddProduct;
